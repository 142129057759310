import { __awaiter, __decorate } from "tslib";
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { CombinedFromOriginalDuplicatesUser } from '@/types/duplicates/duplicatesTypes';
import { useCustomerService } from '@/services';
const customerService = useCustomerService();
let DuplicateModule = class DuplicateModule extends VuexModule {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "groupsRes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "searchRequestMade", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "showBatchResultBlock", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "duplicateGroupSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "detailsPageAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 1
        });
        Object.defineProperty(this, "detailsCurrentPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 1
        });
        Object.defineProperty(this, "listOfSelectedDupsIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "dupsDetailsList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "detailsFilterObject", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                filters: [],
                pageSize: 0,
                pageIndex: 0
            }
        });
        Object.defineProperty(this, "combinedUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: Object.assign(Object.assign({}, new CombinedFromOriginalDuplicatesUser()), { dateOfBirth: {
                    key: 'dateOfBirth',
                    value: new Date().toISOString().slice(0, 10),
                    id: null,
                    selected: false
                } })
        });
        Object.defineProperty(this, "emptyFieldsVocab", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "stateKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "findDuplicatesErrorObj", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
    }
    get groupsResGetter() {
        return this.groupsRes;
    }
    get searchRequestFlagGetter() {
        return this.searchRequestMade;
    }
    get showBatchResultBlockGetter() {
        return this.showBatchResultBlock;
    }
    get duplicateGroupSelectedGetter() {
        return this.duplicateGroupSelected;
    }
    get getDetailsPageAmount() {
        return this.detailsPageAmount;
    }
    get detailsCurrentPageGetter() {
        return this.detailsCurrentPage;
    }
    get listOfSelectedDupsIDsGetter() {
        return this.listOfSelectedDupsIDs;
    }
    get dupsDetailsListGetter() {
        return this.dupsDetailsList;
    }
    get detailsFilterObjectGetter() {
        return this.detailsFilterObject;
    }
    get findDuplicatesErrorObjGetter() {
        return this.findDuplicatesErrorObj;
    }
    get combinedUserGetter() {
        return this.combinedUser;
    }
    get emptyFieldsVocabGetter() {
        return this.emptyFieldsVocab;
    }
    get stateKeyGetter() {
        return this.stateKey;
    }
    setGroupsRes(payload) {
        this.groupsRes = payload;
    }
    setDupsRequestDoneFlag(payload) {
        this.searchRequestMade = payload;
    }
    setShowGroupsResultBlockFLag(payload) {
        this.showBatchResultBlock = payload;
    }
    setDuplicateGroupSelected(payload) {
        this.duplicateGroupSelected = payload;
    }
    setDetailsPageAmount(payload) {
        this.detailsPageAmount = Math.ceil(payload.totalCount / payload.pageSize);
    }
    setDetailsCurrentPage(payload) {
        this.detailsCurrentPage = payload;
    }
    setListOfSelectedDupsIDs(payload) {
        this.listOfSelectedDupsIDs = payload;
    }
    setDupsDetailsList(payload) {
        this.dupsDetailsList = payload;
    }
    setDetailsFilterObject(payload) {
        this.detailsFilterObject = payload;
    }
    setStateKey(payload) {
        this.stateKey = payload;
    }
    setEmptyFieldsVocab(payload) {
        this.emptyFieldsVocab = payload;
    }
    seTfindDuplicatesErrorObj(payload) {
        this.findDuplicatesErrorObj = payload;
    }
    getDuplicatesGroupsRequest({ accountName, context, request }) {
        return __awaiter(this, void 0, void 0, function* () {
            const groupsResp = yield customerService.getCustomerDuplicateGroups(accountName, context, request);
            if (groupsResp.successful) {
                this.setListOfSelectedDupsIDs([]);
                this.setDupsRequestDoneFlag(true);
                this.setGroupsRes(groupsResp.data.groups);
            }
            else {
                this.seTfindDuplicatesErrorObj(groupsResp.error);
            }
        });
    }
    getDuplicatesDetailsRequest({ accountName, context, request }) {
        return __awaiter(this, void 0, void 0, function* () {
            const detailsResp = yield customerService.getCustomerDuplicateDetails(accountName, context, request);
            this.setDetailsPageAmount({ totalCount: detailsResp.data.paging.totalCount, pageSize: request.pageSize });
            this.setDetailsCurrentPage(request.pageIndex + 1);
            this.setDupsDetailsList(detailsResp.data.data);
            this.setDuplicateGroupSelected(true);
        });
    }
};
__decorate([
    Mutation
], DuplicateModule.prototype, "setGroupsRes", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setDupsRequestDoneFlag", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setShowGroupsResultBlockFLag", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setDuplicateGroupSelected", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setDetailsPageAmount", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setDetailsCurrentPage", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setListOfSelectedDupsIDs", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setDupsDetailsList", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setDetailsFilterObject", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setStateKey", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "setEmptyFieldsVocab", null);
__decorate([
    Mutation
], DuplicateModule.prototype, "seTfindDuplicatesErrorObj", null);
__decorate([
    Action
], DuplicateModule.prototype, "getDuplicatesGroupsRequest", null);
__decorate([
    Action
], DuplicateModule.prototype, "getDuplicatesDetailsRequest", null);
DuplicateModule = __decorate([
    Module({ name: 'duplicates', namespaced: true })
], DuplicateModule);
export default DuplicateModule;
