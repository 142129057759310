export function createTeeSheetService(client) {
    return {
        getTeeSheetConfig(accountName, teesheetAccountName, securityGroup = null) {
            return client.get(`${accountName}/teeSheet/${teesheetAccountName}/config`, { params: { securityGroup } });
        },
        getTeeSheetNames(accountName) {
            return client.get(`${accountName}/teeSheet/names`);
        }
    };
}
