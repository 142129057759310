import { __awaiter, __decorate } from "tslib";
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { HttpStatusCode, getCookie, isNilOrWhitespace, useAppConfig, usePermissions } from '@/g1';
import { useAuthService } from '@/services';
const { setUserPermissions } = usePermissions();
const { accessTokenExpirationBufferSeconds } = useAppConfig();
let AuthStore = class AuthStore extends VuexModule {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "authToken", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: getAuthTokenFromCookies()
        });
        Object.defineProperty(this, "currentUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: createEmptyCurrentUser()
        });
        Object.defineProperty(this, "arePermissionsLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isAuthenticated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
    }
    get isAuthorized() {
        return this.isAuthenticated && this.currentUser.id !== -1;
    }
    get currentUserDisplayName() {
        const fullName = [this.currentUser.firstName, this.currentUser.lastName].join(' ');
        return isNilOrWhitespace(fullName)
            ? this.currentUser.username
            : fullName;
    }
    get currentUserName() {
        return !isNilOrWhitespace(this.currentUser.username)
            ? this.currentUser.username
            : '';
    }
    setAuthToken(value) {
        this.authToken = value;
    }
    setIsAuthenticated(value) {
        this.isAuthenticated = value;
    }
    setCurrentUser(value) {
        var _a, _b;
        this.currentUser = value !== null && value !== void 0 ? value : createEmptyCurrentUser();
        setUserPermissions((_b = (_a = this.currentUser) === null || _a === void 0 ? void 0 : _a.permissions) !== null && _b !== void 0 ? _b : []);
    }
    isAccessTokenExpired() {
        if (this.authToken === null) {
            return Promise.resolve(true);
        }
        const expirationDate = new Date(this.authToken.accessTokenExpiration);
        const now = new Date();
        const differenceInSeconds = Math.floor((expirationDate.getTime() - now.getTime()) / 1000);
        return Promise.resolve(differenceInSeconds < accessTokenExpirationBufferSeconds);
    }
    isRefreshTokenExpired() {
        if (this.authToken === null) {
            return Promise.resolve(true);
        }
        const expirationDate = new Date(this.authToken.refreshTokenExpiration);
        const now = new Date();
        const differenceInSeconds = Math.floor((expirationDate.getTime() - now.getTime()) / 1000);
        return Promise.resolve(differenceInSeconds < 0);
    }
    authenticate() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isAuthenticated) {
                return;
            }
            const authService = useAuthService();
            try {
                const response = yield authService.login();
                if (!response.successful || ((_b = (_a = response.request) === null || _a === void 0 ? void 0 : _a.responseURL) === null || _b === void 0 ? void 0 : _b.includes('Login.aspx'))) {
                    return;
                }
                this.setAuthToken(getAuthTokenFromCookies());
                this.setIsAuthenticated(true);
            }
            catch (_c) {
                this.setAuthToken(null);
            }
        });
    }
    logout() {
        this.setAuthToken(null);
        this.setCurrentUser(null);
    }
    refreshAccessToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const authService = useAuthService();
            try {
                yield authService.refreshAccessToken();
                this.setAuthToken(getAuthTokenFromCookies());
            }
            catch (_a) {
                this.setAuthToken(null);
            }
        });
    }
    grantPermissions(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const authService = useAuthService();
            const response = yield authService.grantPermissions(request);
            if (response.successful) {
                this.setAuthToken(getAuthTokenFromCookies());
            }
            return response;
        });
    }
    authorize() {
        return __awaiter(this, void 0, void 0, function* () {
            const authService = useAuthService();
            const response = yield authService.getCurrentUser();
            if (response.status === HttpStatusCode.NotFound) {
                return;
            }
            this.setCurrentUser(response.data);
        });
    }
};
__decorate([
    Mutation
], AuthStore.prototype, "setAuthToken", null);
__decorate([
    Mutation
], AuthStore.prototype, "setIsAuthenticated", null);
__decorate([
    Mutation
], AuthStore.prototype, "setCurrentUser", null);
__decorate([
    Action({ rawError: true })
], AuthStore.prototype, "isAccessTokenExpired", null);
__decorate([
    Action({ rawError: true })
], AuthStore.prototype, "isRefreshTokenExpired", null);
__decorate([
    Action({ rawError: true })
], AuthStore.prototype, "authenticate", null);
__decorate([
    Action({ rawError: true })
], AuthStore.prototype, "logout", null);
__decorate([
    Action({ rawError: true })
], AuthStore.prototype, "refreshAccessToken", null);
__decorate([
    Action({ rawError: true })
], AuthStore.prototype, "grantPermissions", null);
__decorate([
    Action({ rawError: true })
], AuthStore.prototype, "authorize", null);
AuthStore = __decorate([
    Module({ name: 'auth', namespaced: true })
], AuthStore);
export default AuthStore;
function getAuthTokenFromCookies() {
    const authToken = {
        accessToken: getCookie('AUTH_ACCESS_TOKEN'),
        accessTokenExpiration: getCookie('AUTH_ACCESS_EXPIRATION'),
        refreshToken: getCookie('AUTH_REFRESH_TOKEN'),
        refreshTokenExpiration: getCookie('AUTH_REFRESH_EXPIRATION'),
        isPermissionGrantToken: getCookie('AUTH_IS_PERMISSIONS_GRANT_TOKEN')
    };
    if ([authToken.accessToken, authToken.accessTokenExpiration, authToken.refreshToken,
        authToken.isPermissionGrantToken, authToken.refreshToken]
        .some(v => v === null)) {
        return null;
    }
    return Object.assign(Object.assign({}, authToken), { isPermissionGrantToken: authToken.isPermissionGrantToken === '1' });
}
function createEmptyCurrentUser() {
    return {
        id: -1,
        email: null,
        firstName: null,
        lastName: null,
        permissions: [],
        type: null,
        username: ''
    };
}
