export function createLoyaltyProgramService(client) {
    return {
        getLoyaltyPrograms(accountName) {
            return client.get(`${accountName}/loyaltyPrograms`);
        },
        getLoyaltyProgram(accountName, id) {
            return client.get(`${accountName}/loyaltyPrograms/${id}`);
        },
        createLoyaltyProgram(accountName, request) {
            return client.post(`${accountName}/loyaltyPrograms`, request);
        },
        updateLoyaltyProgram(accountName, id, request) {
            return client.put(`${accountName}/loyaltyPrograms/${id}`, request);
        },
        activateLoyaltyProgram(accountName, id) {
            return client.post(`${accountName}/loyaltyPrograms/${id}/activate`);
        },
        deactivateLoyaltyProgram(accountName, id) {
            return client.post(`${accountName}/loyaltyPrograms/${id}/deactivate`);
        },
        assignEntityOwnersToLoyaltyProgram(accountName, id, request) {
            return client.post(`${accountName}/loyaltyPrograms/${id}/entityOwners`, request);
        },
        assignMembershipsToLoyaltyProgram(accountName, id, request) {
            return client.post(`${accountName}/loyaltyPrograms/${id}/memberships`, request);
        },
        deleteLoyaltyProgram(accountName, id) {
            return client.delete(`${accountName}/loyaltyPrograms/${id}`);
        },
        requestLoyaltyProgramExpiration(accountName, id) {
            return client.post(`${accountName}/loyaltyPrograms/${id}/requestExpiration`);
        },
        getPointsForActionRewards(accountName, id) {
            return client.get(`${accountName}/loyaltyPrograms/${id}/pointsForActionRewards`);
        },
        updatePointsForActionReward(accountName, loyaltyProgramID, actionBasedRewardID, request) {
            return client.put(`${accountName}/loyaltyPrograms/${loyaltyProgramID}/pointsForActionRewards/${actionBasedRewardID}`, request);
        },
        getPurchaseRewards(accountName, id) {
            return client.get(`${accountName}/loyaltyPrograms/${id}/purchaseRewards`);
        },
        updatePurchaseReward(accountName, loyaltyProgramID, actionBasedRewardID, request) {
            return client.put(`${accountName}/loyaltyPrograms/${loyaltyProgramID}/purchaseRewards/${actionBasedRewardID}`, request);
        },
        getProductHierarchyRewards(accountName, id) {
            return client.get(`${accountName}/loyaltyPrograms/${id}/productHierarchyRewards`);
        },
        addProductHierarchyReward(accountName, loyaltyProgramID, request) {
            return client.post(`${accountName}/loyaltyPrograms/${loyaltyProgramID}/productHierarchyRewards`, request);
        },
        updateProductHierarchyReward(accountName, loyaltyProgramID, loyaltyRewardItemID, request) {
            return client.put(`${accountName}/loyaltyPrograms/${loyaltyProgramID}/productHierarchyRewards/${loyaltyRewardItemID}`, request);
        },
        deleteProductHierarchyReward(accountName, loyaltyProgramID, loyaltyRewardItemID) {
            return client.delete(`${accountName}/loyaltyPrograms/${loyaltyProgramID}/productHierarchyRewards/${loyaltyRewardItemID}`);
        },
        getGreenFeeRewards(accountName, id) {
            return client.get(`${accountName}/loyaltyPrograms/${id}/greenFeeRewards`);
        },
        addGreenFeeReward(accountName, loyaltyProgramID, request) {
            return client.post(`${accountName}/loyaltyPrograms/${loyaltyProgramID}/greenFeeRewards`, request);
        },
        updateGreenFeeReward(accountName, loyaltyProgramID, adhocCategoryName, request) {
            return client.put(`${accountName}/loyaltyPrograms/${loyaltyProgramID}/greenFeeRewards/${adhocCategoryName}`, request);
        },
        deleteGreenFeeReward(accountName, loyaltyProgramID, adhocCategoryName) {
            return client.delete(`${accountName}/loyaltyPrograms/${loyaltyProgramID}/greenFeeRewards/${adhocCategoryName}`);
        }
    };
}
