import { __awaiter } from "tslib";
export function createBatchService(client) {
    return {
        searchItems(request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post('batches/search', request);
            });
        },
        deleteItem(id) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.delete(`batches/${id}`);
            });
        },
        searchItemResults(itemID, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`batches/${itemID}/results`, request);
            });
        },
        getItem(id) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.get(`batches/${id}`);
            });
        },
        updateItem(id, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.put(`batches/${id}`, request);
            });
        },
        retryItem(id) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.put(`batches/${id}/retry`);
            });
        }
    };
}
