import G1DialogsService, { createG1DialogsService } from './g1DialogsService';
export * from './g1DialogsService';
export { G1DialogsService };
const dialogInjectionKey = Symbol('g1-dialog');
const g1DialogsServiceInjectionKey = Symbol('g1-dialog-service');
export function getDialogInjectionKey() {
    return dialogInjectionKey;
}
export function getG1DialogsServiceInjectionKey() {
    return g1DialogsServiceInjectionKey;
}
const appG1DialogService = createG1DialogsService();
export { appG1DialogService };
