export var TransactionAuditStatus;
(function (TransactionAuditStatus) {
    TransactionAuditStatus[TransactionAuditStatus["Unknown"] = -1] = "Unknown";
    TransactionAuditStatus[TransactionAuditStatus["InProgress"] = 0] = "InProgress";
    TransactionAuditStatus[TransactionAuditStatus["Failed"] = 1] = "Failed";
    TransactionAuditStatus[TransactionAuditStatus["Completed"] = 2] = "Completed";
    TransactionAuditStatus[TransactionAuditStatus["Reprocess"] = 3] = "Reprocess";
    TransactionAuditStatus[TransactionAuditStatus["Scheduled"] = 4] = "Scheduled";
    TransactionAuditStatus[TransactionAuditStatus["All"] = 100] = "All";
})(TransactionAuditStatus || (TransactionAuditStatus = {}));
