import systemRoutes from './systemRoutes';
import locationRoutes from './locationRoutes';
import brandRoutes from './brandRoutes';
import frameRoutes from './frameRoutes';
import loginRoutes from './loginRoutes';
const routes = [
    {
        path: '/system',
        children: systemRoutes,
        component: () => import('@/layouts/system/SystemLayout.vue')
    },
    {
        path: '/location/:account',
        children: locationRoutes,
        component: () => import('@/layouts/location/LocationLayout.vue')
    },
    {
        path: '/brand/:account',
        children: brandRoutes,
        component: () => import('@/layouts/brand/BrandLayout.vue')
    },
    {
        path: '/loginNew',
        children: loginRoutes,
        component: () => import('@/layouts/login/LoginLayout.vue'),
        meta: {
            anonymous: true
        }
    },
    {
        name: 'home',
        path: '/',
        component: () => import('@/pages/HomePage.vue')
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('@/pages/LoginOldPage.vue'),
        meta: {
            anonymous: true
        }
    },
    {
        path: '/frameRoute',
        children: frameRoutes,
        component: () => import('@/layouts/frame/FrameLayout.vue')
    },
    {
        name: 'notFound',
        path: '*',
        component: () => import('@/pages/NotFoundPage.vue')
    }
];
export default routes;
