import { getSpaced, formatPhone } from '@/g1';
import { ApiResponse, Util } from '@/types/common';
import { CustomerNoteTypeNumber } from '@/types/customers/types';
import { OrderItemType, PaymentExpirationType, PaymentMethod } from './orders';
import { MembershipPaymentPlanPriceType } from './memberships';
export class Special {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
    }
}
export class NewCreditCard {
    constructor() {
        Object.defineProperty(this, "cardNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "expirationMonth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "expirationYear", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "nameOnCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "zipCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
    }
    get expirationDate() {
        if ((this.expirationYear == null) || (this.expirationMonth == null)) {
            return null;
        }
        return `${this.expirationYear.toString().padStart(4, '0')}-${this.expirationMonth.toString().padStart(2, '0')}-01`;
    }
    mapFrom(data) {
        this.cardNumber = data.cardNumber;
        this.expirationMonth = data.expirationMonth;
        this.expirationYear = data.expirationYear;
        this.nameOnCard = data.nameOnCard;
        this.type = data.type;
        this.zipCode = data.zipCode;
    }
    toJSON() {
        return {
            cardNumber: this.cardNumber,
            expirationDate: this.expirationDate,
            nameOnCard: this.nameOnCard,
            zipCode: this.zipCode,
            type: this.type
        };
    }
}
export class FederatedSearchCustomer {
    constructor() {
        Object.defineProperty(this, "locationId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fullName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "firstName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lastName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cellPhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "workPhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "homePhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "notes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "membershipCardNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "photo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.locationId = data.locationId;
        this.customerId = data.customerId;
        this.fullName = `${data.firstName} ${data.lastName}`;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.cellPhone = data.cellPhone;
        this.workPhone = data.workPhone;
        this.homePhone = data.homePhone;
        this.notes = data.notes;
        this.membershipCardNumber = data.membershipCardNumber;
        this.photo = data.photoUrl;
    }
    get firstMethodOfContact() {
        var _a, _b, _c, _d;
        if (((_a = this === null || this === void 0 ? void 0 : this.cellPhone) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            return formatPhone(this.cellPhone);
        }
        if (((_b = this === null || this === void 0 ? void 0 : this.homePhone) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            return formatPhone(this.homePhone);
        }
        if (((_c = this === null || this === void 0 ? void 0 : this.workPhone) === null || _c === void 0 ? void 0 : _c.length) > 0) {
            return formatPhone(this.workPhone);
        }
        if (((_d = this === null || this === void 0 ? void 0 : this.email) === null || _d === void 0 ? void 0 : _d.length) > 0) {
            return this === null || this === void 0 ? void 0 : this.email;
        }
        return '';
    }
    get hasWarningNotes() {
        return this === null || this === void 0 ? void 0 : this.notes.some(note => note.type === CustomerNoteTypeNumber.Caution || note.type === CustomerNoteTypeNumber.Warning);
    }
}
export var PhoneFormat;
(function (PhoneFormat) {
    PhoneFormat[PhoneFormat["None"] = 0] = "None";
    PhoneFormat[PhoneFormat["NANPA"] = 1] = "NANPA";
    PhoneFormat[PhoneFormat["International"] = 2] = "International";
})(PhoneFormat || (PhoneFormat = {}));
export class Phone {
    constructor(format, number) {
        Object.defineProperty(this, "number", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "format", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.format = format;
        this.number = number;
    }
    get Number() {
        return this.number;
    }
    get IsUSPhone() {
        return (this.format === PhoneFormat.NANPA);
    }
}
export class LocationSearch {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
    }
}
export class TransparentRedirectToken {
    constructor() {
        Object.defineProperty(this, "token", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "errorMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "clientUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.token = data.token;
        this.errorMessage = data.errorMessage;
        this.clientUrl = data.clientUrl;
    }
}
export class BrandSearch {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
    }
}
export var NoteType;
(function (NoteType) {
    NoteType[NoteType["Regular"] = 1] = "Regular";
    NoteType[NoteType["Caution"] = 2] = "Caution";
    NoteType[NoteType["Warning"] = 4] = "Warning";
})(NoteType || (NoteType = {}));
export class Address {
    constructor() {
        Object.defineProperty(this, "street1", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "street2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "city", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "postalCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "country", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "countryName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.street1 = data.street1;
        this.street2 = data.street2;
        this.city = data.city;
        this.state = data.state;
        this.postalCode = data.postalCode;
        this.country = data.country;
        this.countryName = data.countryName;
    }
}
export var DurationType;
(function (DurationType) {
    DurationType[DurationType["Day"] = 1] = "Day";
    DurationType[DurationType["Month"] = 2] = "Month";
    DurationType[DurationType["Year"] = 3] = "Year";
})(DurationType || (DurationType = {}));
export class ModifierGroup {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "modifiers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
export class Modifier {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chitName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productVariantID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "price", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
export class OrderBulkOperation {
    constructor() {
        Object.defineProperty(this, "metadata", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "order", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(response) {
        this.order = Util.mapObject(response.order, Order);
        this.metadata = {};
        for (const propData in response.metadata) {
            if (propData) {
                this.metadata[propData] = new ApiResponse(response.metadata[propData]);
            }
        }
    }
}
export class NewGiftCardData {
    constructor() {
        Object.defineProperty(this, "number", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "addToExistingCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
export class OrderCustomer {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "firstName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lastName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "photo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "phone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalances", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.photo = data.photo;
        this.phone = data.phone;
        this.externalAccountBalances = Util.mapArray(data.externalAccountBalances, ExternalAccountBalance);
    }
    get fullName() {
        var _a, _b;
        if ((this.firstName != null) && (this.lastName != null)) {
            return `${this.firstName} ${this.lastName}`;
        }
        return (_b = (_a = this.firstName) !== null && _a !== void 0 ? _a : this.lastName) !== null && _b !== void 0 ? _b : '';
    }
}
export class Order {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateCreated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateOpened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateCompleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "datePaid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "subtotal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "grandTotal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalTaxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalBeforeTaxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "autoGratuity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "autoGratuityTax", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "autoGratuityTaxRate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "autoGratuityBeforeOverride", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serviceCharge", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serviceChargeTax", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serviceChargeTaxRate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "locationID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "source", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cashRegisterID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cashRegister", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isSaved", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "items", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "payment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "taxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serviceFees", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "smartPlayFee", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "completedByUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdByUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serverName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "applicablePayments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "applicableSpecials", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "redeemableSeriesItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "applicableCustomerCreditBooks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rainchecks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "refundsAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "balanceAfterRefunds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "refunds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "relatedOrders", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "managerRefundTaxTotal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "managerRefundTotalBeforeTaxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "allowVoid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalNumOfCartFeesInOrder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.items = [];
        this.taxes = [];
        this.payment = new OrderPayment();
    }
    mapFrom(data) {
        var _a, _b, _c, _d;
        this.id = data.id;
        this.customerID = data.customerID;
        this.customer = Util.mapObject(data.customer, OrderCustomer);
        this.dateCreated = Util.parseDate(data.dateCreated);
        this.dateOpened = Util.parseDate(data.dateOpened);
        this.dateCompleted = Util.parseDate(data.dateCompleted);
        this.datePaid = Util.parseDate(data.datePaid);
        this.subtotal = data.subtotal;
        this.grandTotal = data.grandTotal;
        this.totalTaxes = data.totalTaxes;
        this.totalBeforeTaxes = data.totalBeforeTaxes;
        this.autoGratuity = data.autoGratuity;
        this.autoGratuityTax = data.autoGratuityTax;
        this.autoGratuityBeforeOverride = data.autoGratuityBeforeOverride;
        this.autoGratuityTaxRate = data.autoGratuityTaxRate;
        this.serviceCharge = data.serviceCharge;
        this.serviceChargeTax = data.serviceChargeTax;
        this.serviceChargeTaxRate = data.serviceChargeTaxRate;
        this.locationID = data.locationID;
        this.status = data.status;
        this.orderNumber = data.orderNumber;
        this.source = data.source;
        this.cashRegisterID = data.cashRegisterID;
        this.isSaved = data.isSaved;
        this.serverName = data.serverName;
        this.items = Util.mapArray(data.items, OrderItem);
        this.payment = Util.mapObject(data.payment, OrderPayment);
        this.taxes = Util.mapArray(data.taxes, OrderTaxItem);
        this.serviceFees = Util.mapArray(data.serviceFees, SmartPlayFee);
        this.smartPlayFee = data.smartPlayFee;
        this.completedByUser = Object.assign(Object.assign({}, data.completedByUser), { fullName: getSpaced((_a = data.completedByUser) === null || _a === void 0 ? void 0 : _a.firstName, (_b = data.completedByUser) === null || _b === void 0 ? void 0 : _b.lastName) });
        this.createdByUser = Object.assign(Object.assign({}, data.createdByUser), { fullName: getSpaced((_c = data.createdByUser) === null || _c === void 0 ? void 0 : _c.firstName, (_d = data.createdByUser) === null || _d === void 0 ? void 0 : _d.lastName) });
        this.applicablePayments = Util.mapArray(data.applicablePayments, OrderApplicablePayment);
        this.applicableSpecials = Util.mapArray(data.applicableSpecials, OrderApplicableSpecial);
        this.redeemableSeriesItems = Util.mapArray(data.redeemableSeriesItems, RedeemableSeriesItem);
        this.applicableCustomerCreditBooks = Util.mapArray(data.applicableCustomerCreditBooks, CustomerCreditBook);
        this.rainchecks = Util.mapObject(data.rainchecks, OrderRainchecks);
        this.cashRegister = data.cashRegister;
        this.refundsAmount = data.refundsAmount;
        this.refunds = Util.mapArray(data.refunds, OrderRefund);
        this.balanceAfterRefunds = data.balanceAfterRefunds;
        this.relatedOrders = Util.mapArray(data.relatedOrders, RelatedOrder);
        this.managerRefundTaxTotal = data.managerRefundTaxTotal;
        this.managerRefundTotalBeforeTaxes = data.managerRefundTotalBeforeTaxes;
        this.allowVoid = data.allowVoid;
        this.totalNumOfCartFeesInOrder = data.totalNumOfCartFeesInOrder;
    }
}
export class ReprocessQueue {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "entityTypeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "keyID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "locationID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "DateCreatedUTC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "DateProcessedUTC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "statusID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "insertedByAutomatedProcess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "errorDetail", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "typeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "transactionsAuditID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "visibleUTC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.entityTypeID = data.entityTypeID;
        this.keyID = data.keyID;
        this.locationID = data.locationID;
        this.DateCreatedUTC = data.DateCreatedUTC;
        this.DateProcessedUTC = data.DateProcessedUTC;
        this.statusID = data.statusID;
        this.insertedByAutomatedProcess = data.insertedByAutomatedProcess;
        this.errorDetail = data.errorDetail;
        this.typeID = data.typeID;
        this.transactionsAuditID = data.transactionsAuditID;
        this.visibleUTC = data.visibleUTC;
    }
}
export class CustomerCreditBook {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterCreditBooksId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "total", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "balance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterCreditBook", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.customerId = data.customerID;
        this.masterCreditBooksId = data.masterCreditBooksID;
        this.total = data.total;
        this.balance = data.balance;
        this.masterCreditBook = Util.mapObject(data.masterCreditBook, MasterCreditBook);
    }
}
export class MasterCreditBook {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
    }
}
export class ExternalAccountBalance {
    constructor() {
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceTypeEnum", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "balance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditLimit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "active", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableBalance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unitTypeId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        var _a, _b, _c, _d;
        this.type = PaymentMethod.ExternalAccountBalance;
        this.externalAccountBalanceType = data.type.id;
        this.externalAccountBalanceTypeEnum = data.type;
        this.externalAccountBalanceID = data.externalAccountBalanceID;
        this.name = data.name;
        this.description = data.description;
        this.active = data.active;
        this.creditLimit = (_b = (_a = data.creditLimit.amount) !== null && _a !== void 0 ? _a : data.creditLimit) !== null && _b !== void 0 ? _b : 0;
        this.balance = (_d = (_c = data.balance.amount) !== null && _c !== void 0 ? _c : data.balance) !== null && _d !== void 0 ? _d : 0;
        this.availableBalance = calculateAvailableWalletBalance(this.creditLimit, this.balance);
        this.unitTypeId = data.unitTypeId;
    }
}
function calculateAvailableWalletBalance(creditLimit, balance) {
    if (balance > 0 && creditLimit < 0) {
        return (0 - creditLimit) + balance;
    }
    if (balance === 0) {
        return balance - creditLimit;
    }
    return (0 - creditLimit) - (0 - balance);
}
export class ExternalAccountBalanceType {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
    }
}
export class OrderApplicableSpecial {
    constructor() {
        Object.defineProperty(this, "specialID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "specialName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "specialCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderItemIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isOrderScope", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.specialID = data.specialID;
        this.specialName = data.specialName;
        this.specialCode = data.specialCode;
        this.orderItemIDs = data.orderItemIDs;
        this.isOrderScope = data.isOrderScope;
    }
}
export class OrderApplicablePayment {
    constructor() {
        Object.defineProperty(this, "orderItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "balanceCurrency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "balanceInUse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "balanceQuantity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expirationDateTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "issuingLocationName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "membershipBenefitData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditCardData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "giftCertificateData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditAccountData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "seriesData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "raincheckData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerCreditBooksData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.orderItemID = data.orderItemID;
        this.paymentType = data.paymentType;
        this.balanceCurrency = data.balanceCurrency;
        this.balanceQuantity = data.balanceQuantity;
        this.expirationDateTime = Util.parseDate(data.expirationDateTime);
        this.issuingLocationName = data.issuingLocationName;
        this.membershipBenefitData = data.membershipBenefitData;
        this.creditCardData = Util.mapObject(data.creditCardData, CreditCardApplicablePayment);
        this.giftCertificateData = data.giftCertificateData;
        this.creditAccountData = data.creditAccountData;
        this.seriesData = data.seriesData;
        this.raincheckData = Util.mapObject(data.raincheckData, RaincheckApplicablePayment);
        this.externalAccountBalanceData = Util.mapObject(data.externalAccountBalanceData, ExternalAccountBalancesApplicablePayment);
    }
}
export class CustomerCreditBookApplicablePayment {
    constructor() {
        Object.defineProperty(this, "customerCreditBookId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterCreditBookName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.customerCreditBookId = data.customerCreditBookId;
        this.masterCreditBookName = data.masterCreditBookName;
    }
}
export class CreditCardApplicablePayment {
    constructor() {
        Object.defineProperty(this, "customerCreditCardID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "last4Digits", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDefault", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.customerCreditCardID = data.customerCreditCardID;
        this.type = data.type;
        this.last4Digits = data.last4Digits;
        this.isDefault = data.isDefault;
    }
}
export class RaincheckApplicablePayment {
    constructor() {
        Object.defineProperty(this, "raincheckCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "raincheckID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.raincheckCode = data.raincheckCode;
        this.raincheckID = data.raincheckID;
    }
}
export class ExternalAccountBalancesApplicablePayment {
    constructor() {
        Object.defineProperty(this, "externalAccountBalanceID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.externalAccountBalanceID = data.id;
        this.name = data.name;
        this.externalAccountBalanceType = data.type.id;
    }
}
export class ExternalAccountBalancesApplicablePaymentDisplay extends ExternalAccountBalancesApplicablePayment {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "externalAccountBalanceAvailableBalance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
export class GreenFeeCategory {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
    }
}
export class GreenFeeOrderItem {
    constructor() {
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "category", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.description = data.description;
        this.category = Util.mapObject(data.category, GreenFeeCategory);
    }
}
export class ExternalOrderItem {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "category", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vendorType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "thirdPartyCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateCreated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateExpires", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.orderItemID = data.orderItemID;
        this.category = data.category;
        this.vendorType = data.vendorType;
        this.thirdPartyCode = data.thirdPartyCode;
        this.dateCreated = Util.parseDate(data.dateCreated);
        this.dateExpires = Util.parseDate(data.dateExpires);
        this.isActive = data.isActive;
    }
}
export class OrderItem {
    constructor() {
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "parentOrderItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "quantity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "barcode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "price", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRefundedCompletely", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalRefundableAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "addonParentOrderItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productVariantData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "packageData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "greenFeeData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "membershipSignupData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "giftCertificateData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "membershipFeeData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "seriesData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "specialFreeItemData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditBooksData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "employeeTipData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "service", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "itemId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "order", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalOrderItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceReplenishData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.type = data.type;
        this.id = data.id;
        this.parentOrderItemID = data.parentOrderItemID;
        this.paymentItemID = data.paymentItemID;
        this.quantity = data.quantity;
        this.barcode = data.barcode;
        this.isRefundedCompletely = data.isRefundedCompletely;
        this.totalRefundableAmount = data.totalRefundableAmount;
        this.addonParentOrderItemID = data.addonParentOrderItemID;
        this.price = Util.mapObject(data.price, DynamicPrice);
        this.productVariantData = Util.mapObject(data.productVariantData, ProductVariantOrderItem);
        this.greenFeeData = Util.mapObject(data.greenFeeData, GreenFeeOrderItem);
        this.giftCertificateData = Util.mapObject(data.giftCertificateData, GiftCertificateOrderItem);
        this.packageData = Util.mapObject(data.packageData, PackageOrderItem);
        this.membershipSignupData = Util.mapObject(data.membershipSignupData, MembershipSignupOrderItem);
        this.membershipFeeData = Util.mapObject(data.membershipFeeData, MembershipFeeOrderItem);
        this.seriesData = Util.mapObject(data.seriesData, SeriesOrderItem);
        this.specialFreeItemData = Util.mapObject(data.specialFreeItemData, SpecialFreeOrderItem);
        this.creditBooksData = Util.mapObject(data.creditBooksData, CreditBooksOrderItem);
        this.employeeTipData = data.employeeTipData;
        this.order = Util.mapObject(data.order, Order);
        this.service = data.service;
        this.externalOrderItems = Util.mapArray(data.externalOrderItems, ExternalOrderItem);
        this.externalAccountBalanceReplenishData = Util.mapObject(data.externalAccountBalanceReplinishData, ExternalAccountBalanceReplenishOrderItem);
        if (this.productVariantData) {
            this.name = this.productVariantData.name;
            this.itemId = this.productVariantData.billableItemID;
        }
        if (this.service) {
            this.name = this.service.name;
            this.itemId = this.service.billableItemID;
        }
        if (this.packageData) {
            this.itemId = this.packageData.billableItemID;
        }
        if (this.seriesData) {
            this.itemId = this.seriesData.billableItemID;
        }
        if (this.creditBooksData) {
            this.itemId = this.creditBooksData.billableItemID;
        }
        this.description = data.description;
    }
    canBeRemovedFromOrder() {
        if ((this === null || this === void 0 ? void 0 : this.parentOrderItemID) != null) {
            return false;
        }
        const itemType = this === null || this === void 0 ? void 0 : this.type;
        switch (itemType) {
            case OrderItemType.MembershipFee:
            case OrderItemType.CreditAccountBalanceForward:
                return false;
            default:
                return true;
        }
    }
}
export class CreditCard {
    constructor() {
        Object.defineProperty(this, "expirationDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "last4Digits", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nameOnCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maskedNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ltuDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.expirationDate = Util.parseLocalDate(data.expirationDate);
        this.last4Digits = data.last4Digits;
        this.nameOnCard = data.nameOnCard;
        this.type = data.type;
        this.maskedNumber = data.maskedNumber;
        this.ltuDate = Util.parseLocalDate(data.ltuDate);
    }
}
export class MembershipSignupOrderItem {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "initiationFee", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "membership", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.membership = Util.mapObject(data.membership, MembershipOrderItemDTO);
        this.initiationFee = data.initiationFee;
        this.creditCard = Util.mapObject(data.creditCard, CreditCard);
    }
}
export class GiftCertificateOrderItem {
    constructor() {
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "to", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "from", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "message", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isGiftCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "giftProgramID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "originalAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "giftCardRefillAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "packageID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serviceID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "giftCertificateID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.amount = data.amount;
        this.to = data.to;
        this.from = data.from;
        this.message = data.message;
        this.isGiftCard = data.isGiftCard;
        this.giftProgramID = data.programID;
        this.code = data.code;
        this.originalAmount = data.originalAmount;
        this.availableAmount = data.availableAmount;
        this.giftCardRefillAmount = data.giftCardRefillAmount;
        this.packageID = data.packageID;
        this.serviceID = data.serviceID;
        this.giftCertificateID = data.giftCertificateID;
    }
}
export class EmailGiftCertificates {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
export class SeriesOrderItem {
    constructor() {
        Object.defineProperty(this, "customerSeriesID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "billableItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.customerSeriesID = data.customerSeriesID;
        this.name = data.name;
        this.billableItemID = data.billableItemID;
    }
}
export class CreditBooksOrderItem {
    constructor() {
        Object.defineProperty(this, "billableItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterCreditBooksID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.billableItemID = data.billableItemID;
        this.masterCreditBooksID = data.masterCreditBooksID;
        this.name = data.name;
    }
}
export class ExternalAccountBalanceReplenishOrderItem {
    constructor() {
        Object.defineProperty(this, "externalAccountBalanceId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceReplinishAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.externalAccountBalanceId = data.externalAccountBalanceID;
        this.externalAccountBalanceName = data.name;
        this.externalAccountBalanceReplinishAmount = data.externalAccountBalanceReplinishAmount;
    }
}
export class SpecialFreeOrderItem {
    constructor() {
        Object.defineProperty(this, "freeForOrderItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "specialID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderItemName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "specialName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerSeriesID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.freeForOrderItemID = data.freeForOrderItemID;
        this.specialID = data.specialID;
        this.orderItemName = data.orderItemName;
        this.specialName = data.specialName;
        this.customerSeriesID = data.customerSeriesID;
    }
}
export class MembershipFeeOrderItem {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "membership", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentFrequency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentExpiration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "beginsOn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expiresOn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.membership = Util.mapObject(data.membership, MembershipOrderItemDTO);
        this.paymentFrequency = data.paymentFrequency;
        this.paymentExpiration = data.paymentExpiration;
        this.paymentAmount = data.paymentAmount;
        this.beginsOn = Util.parseDate(data.beginsOn);
        this.expiresOn = Util.parseDate(data.expiresOn);
    }
}
export class MembershipOrderItemDTO {
    constructor() {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.name = data.name;
    }
}
export class PackageOrderItem {
    constructor() {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "billableItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "childItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.name = data.name;
        this.billableItemID = data.billableItemID;
        this.childItems = Util.mapArray(data.packageChildItems, PackageChildItem);
    }
}
export class PackageChildItem {
    constructor() {
        Object.defineProperty(this, "billableItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "packageItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "packageOrderItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "packageItemOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.billableItemID = data.billableItemID;
        this.packageItemID = data.packageItemID;
        this.packageOrderItem = Util.mapObject(data.packageOrderItem, OrderItem);
        this.packageItemOptions = Util.mapArray(data.packageItemOptions, PackageChildOption);
    }
}
export class PackageChildOption {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "packageItemOptionType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "packageItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "treatmentCategoryID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "billableItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
        this.packageItemOptionType = Util.parseEnum(PackageItemOptionType, data.packageItemOptionType);
        this.packageItemID = data.packageItemID;
        this.treatmentCategoryID = data.treatmentCategoryID;
        this.billableItemID = data.billableItemID;
    }
}
export class DynamicPrice {
    constructor() {
        Object.defineProperty(this, "finalTagPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "originalTagPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "finalPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "originalPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isFinalPriceOverridden", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "overrideReason", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "specials", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rainchecks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalInclusiveTax", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.finalTagPrice = data.finalTagPrice;
        this.originalTagPrice = data.originalTagPrice;
        this.finalPrice = data.finalPrice;
        this.originalPrice = data.originalPrice;
        this.isFinalPriceOverridden = data.isFinalPriceOverridden;
        this.overrideReason = data.overrideReason;
        this.totalInclusiveTax = data.totalInclusiveTax;
        this.specials = Util.mapArray(data.specials, DynamicPriceSpecial);
        this.rainchecks = Util.mapArray(data.rainchecks, DynamicPriceRaincheck);
    }
}
export class DynamicPriceSpecial {
    constructor() {
        Object.defineProperty(this, "specialID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "specialName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagAdjustmentAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isPartiallyApplied", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hideOnInvoicesAndReceipts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.specialID = data.specialID;
        this.specialName = data.specialName;
        this.tagAdjustmentAmount = data.tagAdjustmentAmount;
        this.isPartiallyApplied = data.isPartiallyApplied;
        this.hideOnInvoicesAndReceipts = data.hideOnInvoicesAndReceipts;
    }
}
export class DynamicPriceRaincheck {
    constructor() {
        Object.defineProperty(this, "raincheckID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "raincheckCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagAdjustmentAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "adjustmentAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.raincheckID = data.raincheckID;
        this.raincheckCode = data.raincheckCode;
        this.tagAdjustmentAmount = data.tagAdjustmentAmount;
        this.adjustmentAmount = data.adjustmentAmount;
    }
}
export class OrderItemMetadata {
    constructor() {
        Object.defineProperty(this, "note", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fireMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productDescription", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.note = data.note;
        this.fireMessage = data.fireMessage;
        this.productDescription = data.ProductDescription;
    }
}
export class ProductVariantOrderItem {
    constructor() {
        Object.defineProperty(this, "billableItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productVariantID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "metadata", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "minimumSellPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maximumDiscount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "modifiers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "volume", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unitOfMeasure", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "clothingSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "color", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.billableItemID = data.billableItemID;
        this.productID = data.productID;
        this.productVariantID = data.productVariantID;
        this.name = data.name;
        this.minimumSellPrice = data.minimumSellPrice;
        this.maximumDiscount = data.maximumDiscount;
        this.volume = data.volume;
        this.unitOfMeasure = data.unitOfMeasure;
        this.clothingSize = data.clothingSize;
        this.color = data.color;
        this.metadata = Util.mapObject(data.metadata, OrderItemMetadata);
        this.modifiers = data.modifiers;
        this.description = data.description;
    }
}
export class OrderPayment {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tipAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalTipAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "surchargeAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "changeAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "items", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.totalAmount = data.totalAmount;
        this.tipAmount = data.tipAmount;
        this.externalTipAmount = data.externalTipAmount;
        this.surchargeAmount = data.surchargeAmount;
        this.changeAmount = data.changeAmount;
        this.items = Util.mapArray(data.items, PaymentItem);
        this.totalAmount = data.totalAmount;
    }
}
export class CreditCardPayment {
    constructor() {
        Object.defineProperty(this, "creditCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isEmv", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "preauthTransactionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditCardTransactions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chargeCreditCardTransaction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lastCreditCardTransaction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.creditCard = Util.mapObject(data.creditCard, CreditCard);
        this.creditCardTransactions = Util.mapArray(data.creditCardTransactions, CreditCardPaymentTransaction);
        this.chargeCreditCardTransaction = Util.mapObject(data.chargeCreditCardTransaction, CreditCardPaymentTransaction);
        this.lastCreditCardTransaction = Util.mapObject(data.lastCreditCardTransaction, CreditCardPaymentTransaction);
        this.isEmv = data.isEmv;
        this.preauthTransactionID = data.preauthTransactionID;
    }
}
export class CreditAccountPayment {
    constructor() {
        Object.defineProperty(this, "creditAccountID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerDisplayName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.creditAccountID = data.creditAccountID;
        this.type = data.type;
        this.customerDisplayName = data.customerDisplayName;
    }
}
export class CreditCardPaymentTransaction {
    constructor() {
        Object.defineProperty(this, "confirmationID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "refundConfirmationID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "remoteTransactionID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isNewSDK", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDebitCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "approvalCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "emvTransactionReceiptID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "transactionReceipt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.confirmationID = data.confirmationID;
        this.refundConfirmationID = data.refundConfirmationID;
        this.remoteTransactionID = data.remoteTransactionID;
        this.isNewSDK = data.isNewSDK;
        this.isDebitCard = data.isDebitCard;
        this.approvalCode = data.approvalCode;
        this.creditCard = Util.mapObject(data.creditCard, CreditCard);
        this.emvTransactionReceiptID = data.emvTransactionReceiptID;
        this.transactionReceipt = data.transactionReceipt;
    }
}
export class EmvTransactionReceipt {
    constructor() {
        Object.defineProperty(this, "cardLogo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cardHoldersName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "processorReferenceNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "approvalNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "applicationID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cryptogram", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalTerminalID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "transactionDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.cardLogo = data.cardLogo;
        this.cardHoldersName = data.cardHoldersName;
        this.accountNumber = data.accountNumber;
        this.processorReferenceNumber = data.processorReferenceNumber;
        this.approvalNumber = data.approvalNumber;
        this.applicationID = data.applicationID;
        this.cryptogram = data.cryptogram;
        this.externalTerminalID = data.externalTerminalID;
        this.transactionDate = Util.parseDate(data.transactionDate);
    }
}
export class PaymentTransactionSignature {
    constructor() {
        Object.defineProperty(this, "signatureType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.signatureType = data.signatureType;
    }
}
export class PaymentTransaction {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "refundID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentTransactionSignatures", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.type = data.type;
        this.refundID = data.refundID;
        this.paymentTransactionSignatures = Util.mapArray(data.paymentTransactionSignatures, PaymentTransactionSignature);
    }
}
export class PaymentItem {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isPaid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "datePaid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tipAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "surchargeAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "surchargeRate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRefunded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "allowChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "method", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditCardData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "achData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditAccountPaymentData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerCreditBookData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterCreditBookData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalGolfNowData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerMembershipBenefitData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "giftCertificateData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "checkData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerSeriesData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rewardPointsData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customPaymentMethodAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customMethod", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "transactionReceipts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentTransactions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.isPaid = data.isPaid;
        this.datePaid = Util.parseDate(data.datePaid);
        this.amount = data.amount;
        this.tipAmount = data.tipAmount;
        this.method = data.method;
        this.surchargeAmount = data.surchargeAmount;
        this.surchargeRate = data.surchargeRate;
        this.isRefunded = data.isRefunded;
        this.allowChange = data.allowChange;
        this.customPaymentMethodAmount = data.customPaymentMethodAmount;
        this.creditCardData = Util.mapObject(data.creditCardData, CreditCardPayment);
        this.achData = data.achData;
        this.creditAccountPaymentData = Util.mapObject(data.creditAccountPaymentData, CreditAccountPayment);
        this.customerCreditBookData = Util.mapObject(data.customerCreditBookData, CustomerCreditBookPayment);
        this.masterCreditBookData = Util.mapObject(data.masterCreditBookData, MasterCreditBookPayment);
        this.externalGolfNowData = Util.mapObject(data.externalGolfNowData, ExternalGolfNowPayment);
        this.externalAccountBalanceData = Util.mapObject(data.externalAccountBalanceData, ExternalAccountBalancePayment);
        this.customerMembershipBenefitData = Util.mapObject(data.customerMembershipBenefitData, CustomerMembershipBenefitPayment);
        this.giftCertificateData = Util.mapObject(data.giftCertificateData, GiftCertificatePayment);
        this.checkData = Util.mapObject(data.checkData, CheckPayment);
        this.customerSeriesData = Util.mapObject(data.customerSeriesData, CustomerSeriesPayment);
        this.rewardPointsData = Util.mapObject(data.rewardPointsData, RewardPointsPayment);
        this.customMethod = data.customMethod;
        this.paymentTransactions = Util.mapArray(data.paymentTransactions, PaymentTransaction);
        this.transactionReceipts = Util.mapArray(data.transactionReceipts, EmvTransactionReceipt);
    }
    get allowTips() {
        switch (this.method) {
            case PaymentMethod.MembershipBenefit:
            case PaymentMethod.Series:
            case PaymentMethod.RewardPoints:
                return false;
            default:
                return true;
        }
    }
}
export class OrderRefund {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateCreated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentMethod", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "notes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderPaymentItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderPaymentItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "newPaymentItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "newPaymentItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "refundDisplayName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditCardTransaction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.amount = data.amount;
        this.dateCreated = data.dateCreated;
        this.paymentMethod = data.paymentMethod;
        this.notes = data.notes;
        this.orderPaymentItemID = data.orderPaymentItemID;
        this.orderPaymentItem = Util.mapObject(data.orderPaymentItem, PaymentItem);
        this.newPaymentItemID = data.newPaymentItemID;
        this.newPaymentItem = Util.mapObject(data.newPaymentItem, PaymentItem);
        this.refundDisplayName = data.refundDisplayName;
        this.creditCardTransaction = Util.mapObject(data.creditCardTransaction, CreditCardPaymentTransaction);
        this.type = data.type;
        this.orderItemID = data.orderItemID;
    }
}
export class RelatedOrder {
    constructor() {
        Object.defineProperty(this, "orderID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "orderNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.orderID = data.orderID;
        this.orderNumber = data.orderNumber;
    }
}
export class GiftCertificatePayment {
    constructor() {
        Object.defineProperty(this, "programID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isGiftCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "giftCertificatePaymentType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "remainingBalance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.programID = data.programID;
        this.programName = data.programName;
        this.code = data.code;
        this.isGiftCard = data.isGiftCard;
        this.giftCertificatePaymentType = data.giftCertificatePaymentType;
        this.remainingBalance = data.remainingBalance;
    }
}
export class CustomerCreditBookPayment {
    constructor() {
        Object.defineProperty(this, "customerCreditBooksID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "balance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterCreditBooksID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterCreditBooksName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.customerCreditBooksID = data.customerCreditBooksID;
        this.balance = data.balance;
        this.masterCreditBooksID = data.masterCreditBooksID;
        this.masterCreditBooksName = data.masterCreditBooksName;
    }
}
export class MasterCreditBookPayment {
    constructor() {
        Object.defineProperty(this, "balance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterCreditBooksID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterCreditBooksName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.balance = data.balance;
        this.masterCreditBooksID = data.masterCreditBooksID;
        this.masterCreditBooksName = data.masterCreditBooksName;
    }
}
export class ExternalGolfNowPayment {
    constructor() {
        Object.defineProperty(this, "externalGolfNowPaymentType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.externalGolfNowPaymentType = data.externalGolfNowPaymentType;
    }
}
export class ExternalAccountBalancePayment {
    constructor() {
        Object.defineProperty(this, "balance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "externalAccountBalanceTypeName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.balance = data.balance;
        this.name = data.name;
        this.externalAccountBalanceId = data.externalAccountBalanceId;
        this.externalAccountBalanceTypeName = data.type;
    }
}
export class CustomerMembershipBenefitPayment {
    constructor() {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.name = data.name;
    }
}
export class CheckPayment {
    constructor() {
        Object.defineProperty(this, "checkNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.checkNumber = data.checkNumber;
    }
}
export class CustomerSeriesPayment {
    constructor() {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "number", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "balance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateIssued", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expirationType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expireOnDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expirationDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expireDaysFromPurchase", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expireDaysFromFirstUse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.name = data.name;
        this.number = data.number;
        this.balance = data.balance;
        this.dateIssued = Util.parseLocalDate(data.dateIssued);
        this.expirationType = data.expirationType;
        this.expireOnDate = Util.parseLocalDate(data.expireOnDate);
        this.expirationDate = Util.parseLocalDate(data.expirationDate);
        this.expireDaysFromPurchase = data.expireDaysFromPurchase;
        this.expireDaysFromFirstUse = data.expireDaysFromFirstUse;
        this.customer = data.customer;
    }
}
export class RewardPointsPayment {
    constructor() {
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.amount = data.amount;
    }
}
export class OrderTaxItem {
    constructor() {
        Object.defineProperty(this, "taxID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amountBeforeOverride", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isTaxRateVariable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "overrideReason", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "managerRefundAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.taxID = data.taxID;
        this.name = data.name;
        this.amount = data.amount;
        this.amountBeforeOverride = data.amountBeforeOverride;
        this.rate = data.rate;
        this.isTaxRateVariable = data.isTaxRateVariable;
        this.overrideReason = data.overrideReason;
        this.managerRefundAmount = data.managerRefundAmount;
    }
}
export var PackageItemOptionType;
(function (PackageItemOptionType) {
    PackageItemOptionType[PackageItemOptionType["Service"] = 1] = "Service";
    PackageItemOptionType[PackageItemOptionType["ProductVariant"] = 2] = "ProductVariant";
    PackageItemOptionType[PackageItemOptionType["Series"] = 6] = "Series";
})(PackageItemOptionType || (PackageItemOptionType = {}));
export class GiftCertificate {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isGiftCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "usedAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "originalAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "programID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expirationDateUTC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.isGiftCard = data.isGiftCard;
        this.code = data.code;
        this.availableAmount = data.availableAmount;
        this.usedAmount = data.usedAmount;
        this.originalAmount = data.originalAmount;
        this.programID = data.programID;
        this.status = data.status;
        this.expirationDateUTC = Util.parseDate(data.expirationDateUTC);
    }
}
export class Membership {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableOnline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sharingLevel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "enableTags", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasBenefits", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isBrandMembership", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tier", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentPlans", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tags", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "benefits", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "priority", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "locations", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.status = data.status;
        this.availableOnline = Boolean(data.availableOnline);
        this.sharingLevel = data.sharingLevel;
        this.enableTags = data.enableTags;
        this.hasBenefits = data.hasBenefits;
        this.isBrandMembership = data.isBrandMembership;
        this.tier = data.tier;
        this.paymentPlans = Util.mapArray(data.paymentPlans, MembershipPaymentPlan);
        this.tags = Util.mapArray(data.tags, MembershipTag);
        this.benefits = Util.mapArray(data.benefits, MembershipBenefit);
        this.priority = data.priority;
        this.locations = data.locations;
    }
}
export class MembershipPaymentPlan {
    get membershipStart() {
        if (this.beginDate === null) {
            return 'beginsImmediately';
        }
        return 'beginsOn';
    }
    set membershipStart(value) {
        if (value === 'beginsImmediately') {
            this.beginDate = null;
        }
        if (value === 'beginsOn') {
            this.beginDate = new Date().toISOString().split('T')[0];
        }
    }
    get membershipExpiration() {
        if (this.expiresIn !== null) {
            return 'expiresIn';
        }
        if (this.expiresOn !== null) {
            return 'expiresOn';
        }
        return 'neverExpires';
    }
    set membershipExpiration(value) {
        if (value === 'neverExpires') {
            this.expiresIn = null;
            this.paymentExpirationType = null;
            this.expiresOn = null;
        }
        if (value === 'expiresIn') {
            this.expiresIn = 12;
            this.expiresOn = null;
            this.paymentExpirationType = PaymentExpirationType.Months;
        }
        if (value === 'expiresOn') {
            this.expiresIn = null;
            this.paymentExpirationType = null;
            if (this.beginDate != null) {
                this.expiresOn = this.beginDate;
            }
            else {
                this.expiresOn = new Date().toISOString().split('T')[0];
            }
        }
    }
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "price", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expiresIn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isAutoPay", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "commissionType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "commissionTypeString", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "commissionPercentage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "commissionAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sku", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentMethod", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sellOnline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "defaultInitiationFee", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "terms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onlineDescription", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "barcode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentFrequencyType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isBrandManaged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "billingDay", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "allowBillingDayOverride", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentExpirationType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "benefitsExpirationType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "benefitsExpirationPeriod", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expiresOn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "beginDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "availableOnline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "enableTermsAndConditions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "termsAndConditions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "enableCancellationPolicy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cancellationPolicy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "enableMembershipDescriptionText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "membershipDescriptionText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chargeOnRenewal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "freezeSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "priceChanges", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "taxOverrides", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "priority", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasFreezeSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasDayofMonthtoCharge", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasDefaultPaymentType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "toggleOnlineInformation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isNew", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.termsAndConditions = '';
        this.cancellationPolicy = '';
        this.membershipDescriptionText = '';
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
        this.isActive = data.isActive;
        this.price = data.price;
        this.tagPrice = data.tagPrice;
        this.expiresIn = data.expiresIn;
        this.isAutoPay = data.isAutoPay;
        this.commissionType = data.commissionType;
        if (this.commissionType !== null) {
            this.commissionTypeString = this.commissionType.toString();
        }
        else {
            this.commissionTypeString = '0';
        }
        this.commissionPercentage = data.commissionPercentage;
        this.commissionAmount = data.commissionAmount;
        this.sku = data.sku;
        this.description = data.description;
        this.paymentMethod = data.paymentMethod;
        this.defaultInitiationFee = data.defaultInitiationFee;
        this.terms = data.terms;
        this.onlineDescription = data.onlineDescription;
        this.barcode = data.barcode;
        this.paymentFrequencyType = data.paymentFrequencyType;
        this.isBrandManaged = data.isBrandManaged;
        this.billingDay = data.billingDay;
        this.allowBillingDayOverride = data.allowBillingDayOverride;
        this.paymentExpirationType = data.paymentExpirationType;
        this.benefitsExpirationType = data.benefitsExpirationType;
        this.benefitsExpirationPeriod = data.benefitsExpirationPeriod;
        this.expiresOn = data.expiresOn;
        this.beginDate = data.beginDate;
        this.availableOnline = data.availableOnline;
        this.toggleOnlineInformation = data.availableOnline;
        this.enableTermsAndConditions = data.enableTermsAndConditions;
        this.termsAndConditions = data.termsAndConditions;
        this.enableCancellationPolicy = data.enableCancellationPolicy;
        this.cancellationPolicy = data.cancellationPolicy;
        this.enableMembershipDescriptionText = data.enableMembershipDescriptionText;
        this.membershipDescriptionText = data.membershipDescriptionText;
        this.chargeOnRenewal = data.chargeOnRenewal;
        this.freezeSettings = data.freezeSettings;
        this.priceChanges = Util.mapArray(data.priceChanges, MembershipPaymentPlanPrice);
        this.taxOverrides = data.taxOverrides;
        this.priority = data.priority;
    }
}
export class TagGroup {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "entityOwnerID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "priority", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "teeSheetSlotColor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateCreated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateLastModified", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDeleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateDeleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.entityOwnerID = data.entityOwnerID;
        this.priority = data.priority;
        this.teeSheetSlotColor = data.teeSheetSlotColor;
        this.dateCreated = data.dateCreated;
        this.dateLastModified = data.dateLastModified;
        this.isDeleted = data.isDeleted;
        this.dateDeleted = data.dateDeleted;
    }
}
export class TagGroupTag {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagGroupID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateCreated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateLastModified", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDeleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateDeleted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagGroup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.tagGroupID = data.tagGroupID;
        this.tagID = data.tagID;
        this.dateCreated = data.dateCreated;
        this.dateLastModified = data.dateLastModified;
        this.isDeleted = data.isDeleted;
        this.dateDeleted = data.dateDeleted;
        this.tagGroup = Util.mapObject(data.tagGroup, TagGroup);
    }
}
export class MembershipTag {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "entityOwnerType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.entityOwnerType = data.entityOwnerType;
    }
}
export class BenefitPanel {
    constructor() {
        Object.defineProperty(this, "serviceCategories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "serviceSubCategories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "serviceItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "selectedServiceCategory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selectedServiceSubCategory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selectedServiceItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "productDepartments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "productCategories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "productSubCategories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "productItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "selectedProductDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selectedProductCategory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selectedProductSubCategory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selectedProductItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "packageItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "selectedPackgeItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "selectedServices", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "selectedProducts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
        Object.defineProperty(this, "selectedPackages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Array()
        });
    }
}
export class MembershipBenefit {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "freeItemQuantity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "freeItemFrequency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isUnlimited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "daysTillExpiration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expirationType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "services", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "products", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "packages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
        this.status = data.status;
        this.isUnlimited = data.isUnlimited;
        this.freeItemQuantity = data.freeItemQuantity;
        this.freeItemFrequency = data.freeItemFrequency;
        this.daysTillExpiration = data.daysTillExpiration;
        this.expirationType = data.expirationType;
        this.services = Util.mapArray(data.services, MembershipBenefitService);
        this.packages = data.packages;
        this.products = Util.mapArray(data.products, MembershipBenefitProduct);
    }
}
export class MembershipPaymentPlanPrice {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "startFrom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "price", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isGrandfathered", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lastProcessedByUserID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentPlanPriceType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.startFrom = data.startFrom;
        this.price = data.price;
        this.tagPrice = data.tagPrice;
        this.isGrandfathered = data.isGrandfathered;
        this.lastProcessedByUserID = data.lastProcessedByUserID;
        this.type = data.type;
        this.paymentPlanPriceType = Util.parseFlagEnum(MembershipPaymentPlanPriceType, data.type);
    }
}
export class SmartPlayFee {
    constructor() {
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tax", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "taxRate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chargeRate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rateType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "feeTypeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "feeConfigurationId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRefundable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.description = data.description;
        this.amount = data.amount;
        this.tax = data.tax;
        this.taxRate = data.taxRate;
        this.chargeRate = data.chargeRate;
        this.rateType = data.rateType;
        this.feeTypeID = data.feeTypeID;
        this.feeConfigurationId = data.feeConfigurationId;
        this.isRefundable = data.isRefundable;
    }
}
export class MembershipBenefitService {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serviceCategoryID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serviceSubcategoryID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "billableItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "masterBillableItemID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serviceCategoryName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "serviceSubcategoryName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "itemName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
        this.serviceCategoryID = data.serviceCategoryID;
        this.serviceSubcategoryID = data.serviceSubcategoryID;
        this.masterBillableItemID = data.masterBillableItemID;
    }
}
export class MembershipBenefitProduct {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tierID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "brandTierID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productDepartmentID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productDepartmentName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productCategoryID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productCategoryName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productSubCategoryName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "productSubCategoryID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "itemName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
        this.tierID = data.tierID;
        this.brandTierID = data.brandTierID;
    }
}
export var BillingDay;
(function (BillingDay) {
    BillingDay[BillingDay["DayOfPurchase"] = 0] = "DayOfPurchase";
    BillingDay[BillingDay["One"] = 1] = "One";
    BillingDay[BillingDay["Two"] = 2] = "Two";
    BillingDay[BillingDay["Three"] = 3] = "Three";
    BillingDay[BillingDay["Four"] = 4] = "Four";
    BillingDay[BillingDay["Five"] = 5] = "Five";
    BillingDay[BillingDay["Six"] = 6] = "Six";
    BillingDay[BillingDay["Seven"] = 7] = "Seven";
    BillingDay[BillingDay["Eight"] = 8] = "Eight";
    BillingDay[BillingDay["Nine"] = 9] = "Nine";
    BillingDay[BillingDay["Ten"] = 10] = "Ten";
    BillingDay[BillingDay["Eleven"] = 11] = "Eleven";
    BillingDay[BillingDay["Twelve"] = 12] = "Twelve";
    BillingDay[BillingDay["Thirthteen"] = 13] = "Thirthteen";
    BillingDay[BillingDay["Fourthteen"] = 14] = "Fourthteen";
    BillingDay[BillingDay["Fifthteen"] = 15] = "Fifthteen";
    BillingDay[BillingDay["Sixthteen"] = 16] = "Sixthteen";
    BillingDay[BillingDay["Seventhteen"] = 17] = "Seventhteen";
    BillingDay[BillingDay["EightTeen"] = 18] = "EightTeen";
    BillingDay[BillingDay["Nineteen"] = 19] = "Nineteen";
    BillingDay[BillingDay["Twenty"] = 20] = "Twenty";
    BillingDay[BillingDay["TwentyOne"] = 21] = "TwentyOne";
    BillingDay[BillingDay["TwentyTwo"] = 22] = "TwentyTwo";
    BillingDay[BillingDay["TwentyThree"] = 23] = "TwentyThree";
    BillingDay[BillingDay["TwentyFour"] = 24] = "TwentyFour";
    BillingDay[BillingDay["TwentyFive"] = 25] = "TwentyFive";
    BillingDay[BillingDay["TwentySix"] = 26] = "TwentySix";
    BillingDay[BillingDay["TwentySeven"] = 27] = "TwentySeven";
    BillingDay[BillingDay["TwentyEigth"] = 28] = "TwentyEigth";
    BillingDay[BillingDay["LastDayOfMonth"] = 31] = "LastDayOfMonth";
})(BillingDay || (BillingDay = {}));
export class MembershipSettings {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "termsOfUse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "defaultToProrated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "waiveProratedFee", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "enableAllMembershipCreditAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "missedPayments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "missedAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "requireCreditCardOnPurchase", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "enableCancellationReasons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "requireCancellationReason", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creditLimitType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "billingRetryType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "billingMaxRetryTimes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accrueBenefitsAfterFailedCharge", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cancellationReasons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "enableMembershipAutoNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "enableMembershipRenewal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cardFormatTypeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cardStartIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cardLength", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.termsOfUse = '';
    }
    mapFrom(data) {
        this.id = data.id;
        this.termsOfUse = data.termsOfUse;
        this.defaultToProrated = data.defaultToProrated;
        this.waiveProratedFee = data.waiveProratedFee;
        this.enableAllMembershipCreditAccount = data.enableAllMembershipCreditAccount;
        this.missedPayments = data.missedPayments;
        this.missedAmount = data.missedAmount;
        this.requireCreditCardOnPurchase = data.requireCreditCardOnPurchase;
        this.enableCancellationReasons = data.enableCancellationReasons;
        this.requireCancellationReason = data.requireCancellationReason;
        this.creditLimitType = data.creditLimitType;
        this.billingRetryType = data.billingRetryType;
        this.billingMaxRetryTimes = data.billingMaxRetryTimes;
        this.accrueBenefitsAfterFailedCharge = data.accrueBenefitsAfterFailedCharge;
        this.cancellationReasons = Util.mapArray(data.cancellationReasons, MembershipCancellationReason);
        this.enableMembershipAutoNumber = data.enableMembershipAutoNumber;
        this.enableMembershipRenewal = data.enableMembershipRenewal;
        this.cardFormatTypeID = data.cardFormatTypeID;
        this.cardStartIndex = data.cardStartIndex;
        this.cardLength = data.cardLength;
    }
}
export class MembershipCancellationReason {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
    }
}
export class CustomerEmailSettings {
    constructor() {
        Object.defineProperty(this, "transactional", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mailChimp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "promotional", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "receiveSMS", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "receiveEmails", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "receivePost", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "receivePhonecalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "termsConsent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.transactional = data.transactional;
        this.mailChimp = data.mailChimp;
        this.promotional = data.promotional;
        this.receiveSMS = data.receiveSMS;
        this.receiveEmails = data.receiveEmails;
        this.receivePost = data.receivePost;
        this.receivePhonecalls = data.receivePhonecalls;
        this.termsConsent = data.termsConsent;
    }
}
export class Tag {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagGroupTag", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.tagGroupTag = Util.mapObject(data.tagGroupTag, TagGroupTag);
    }
}
export class CustomerTag {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tag", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateCreatedUTC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.customerID = data.customerID;
        this.tagID = data.tagID;
        this.tag = Util.mapObject(data.tag, Tag);
        this.dateCreatedUTC = Util.parseDate(data.dateCreatedUTC);
    }
}
export class CustomerNote {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "noteMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "user", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateCreatedUTC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateLastModifiedUTC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "noteTypeID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.customerID = data.customerID;
        this.noteMessage = data.noteMessage;
        this.user = data.user;
        this.dateCreatedUTC = data.dateCreatedUTC;
        this.dateLastModifiedUTC = data.dateLastModifiedUTC;
        this.noteTypeID = data.noteTypeID;
    }
}
export class RedeemableSeriesItem {
    constructor() {
        Object.defineProperty(this, "orderItemIDs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerSeriesID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customerSeriesNo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "quantityRemaining", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unitPrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "locationName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "seriesName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.orderItemIDs = data.orderItemIDs;
        this.customerSeriesID = data.customerSeriesID;
        this.customerSeriesNo = data.customerSeriesNo;
        this.quantityRemaining = data.quantityRemaining;
        this.unitPrice = data.unitPrice;
        this.locationName = data.locationName;
        this.seriesName = data.seriesName;
    }
}
export class OrderRainchecks {
    constructor() {
        Object.defineProperty(this, "allowedRaincheckQuantity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "defaultRaincheckQuantity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "allowedRaincheckAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalRaincheckAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "canIssueRaincheck", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasDifferentGreenFees", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "hasDifferentCartFees", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "numberOfGreenFees", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "numberOfCartFees", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "issuedRainchecks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.allowedRaincheckQuantity = data.allowedRaincheckQuantity;
        this.defaultRaincheckQuantity = data.defaultRaincheckQuantity;
        this.allowedRaincheckAmount = data.allowedRaincheckAmount;
        this.totalRaincheckAmount = data.totalRaincheckAmount;
        this.canIssueRaincheck = data.canIssueRaincheck;
        this.hasDifferentGreenFees = data.hasDifferentGreenFees;
        this.hasDifferentCartFees = data.hasDifferentCartFees;
        this.numberOfGreenFees = data.numberOfGreenFees;
        this.numberOfCartFees = data.numberOfCartFees;
        this.issuedRainchecks = Util.mapArray(data.issuedRainchecks, OrderIssuedRaincheck);
    }
}
export class OrderRaincheckCustomer {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "firstName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lastName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
    }
}
export class OrderIssuedRaincheck {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "customer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "employeeName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "issuedOn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdByUserFullName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "expirationDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.id = data.id;
        this.code = data.code;
        this.amount = data.amount;
        this.customer = Util.mapObject(data.customer, OrderRaincheckCustomer);
        this.employeeName = data.employeeName;
        this.issuedOn = Util.parseDate(data.issuedOn);
        this.createdByUserFullName = data.createdByUserFullName;
        this.expirationDate = Util.parseDate(data.expirationDate);
        this.status = data.status;
    }
}
export var OrderIssuedRaincheckStatus;
(function (OrderIssuedRaincheckStatus) {
    OrderIssuedRaincheckStatus["Valid"] = "valid";
    OrderIssuedRaincheckStatus["Redeemed"] = "redeemed";
    OrderIssuedRaincheckStatus["Expired"] = "expired";
    OrderIssuedRaincheckStatus["Cancelled"] = "cancelled";
})(OrderIssuedRaincheckStatus || (OrderIssuedRaincheckStatus = {}));
export class DistributionRequest {
    constructor() {
        Object.defineProperty(this, "distributionItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.distributionItems = Util.mapArray(data.distributionItems, DistributionItem);
    }
}
export class DistributionItem {
    constructor() {
        Object.defineProperty(this, "customerID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.customerID = data.customerID;
        this.amount = data.amount;
    }
}
export class NoSaleOpenDrawerCashRegisterRequest {
    constructor() {
        Object.defineProperty(this, "locationID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.locationID = data.locationID;
    }
}
