import { __awaiter } from "tslib";
import { Order, OrderBulkOperation } from '@/types/domain';
import { DataResponse } from '@/types/common';
import { OrderExpand } from './getOrder';
export function createOrderService(client, { paymentTimeout }) {
    const defaultOrderExpandOptions = [OrderExpand.Customer, OrderExpand.ApplicableSpecials,
        OrderExpand.ApplicablePayments, OrderExpand.Rainchecks, OrderExpand.RedeemableSeriesItems,
        OrderExpand.CreatedByUser, OrderExpand.CompletedByUser, OrderExpand.ServiceFees];
    return {
        searchOrders(accountName, request) {
            return client.post(`${accountName}/orders/searchNew`, request);
        },
        searchOrdersOld(accountName, request) {
            return client.post(`${accountName}/orders/search`, request);
        },
        getOrder(accountName, orderID, expand = defaultOrderExpandOptions) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.get(`${accountName}/orders/${orderID}`, {
                    params: {
                        expand: expand.join(',')
                    }
                });
                return new DataResponse(Order, response);
            });
        },
        createOrder(accountName, request) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/orders`, request);
                return new DataResponse(Order, response);
            });
        },
        saveOpenOrder(accountName, orderID, cashRegisterID) {
            return client.post(`${accountName}/orders/${orderID}/save`, {
                cashRegisterID
            });
        },
        setCustomer(accountName, orderID, customerID) {
            return client.put(`${accountName}/orders/${orderID}/customer`, {
                customerID
            });
        },
        closeOrder(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/close`, request);
        },
        processOrder(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/process`, request);
        },
        voidOrder(accountName, orderID) {
            return client.post(`${accountName}/orders/${orderID}/void`, null, {
                timeout: paymentTimeout
            });
        },
        setReadyStatus(accountName, orderID, readyStatus) {
            return client.put(`${accountName}/orders/${orderID}/setReadyStatus/${readyStatus}`);
        },
        deleteOrder(accountName, orderID) {
            return client.delete(`${accountName}/orders/${orderID}`);
        },
        backdateOrder(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/BackDateOrder`, request);
        },
        overrideOrderTax(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/taxOverride`, request);
        },
        removeTaxOverride(accountName, orderID, taxItemID) {
            return client.delete(`${accountName}/orders/${orderID}/taxOverride/${taxItemID}`);
        },
        overrideOrderAutoGratuity(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/AutoGratuityOverride`, request);
        },
        removeAutoGratuityOverride(accountName, orderID) {
            return client.delete(`${accountName}/orders/${orderID}/AutoGratuityOverride`);
        },
        sendNotification(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/notifications`, request);
        },
        getOrderEventLogs(accountName, orderID) {
            return client.get(`${accountName}/orders/${orderID}/eventLogs`);
        },
        addItems(accountName, orderID, request, includeOrderInResponse = true) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/orders/${orderID}/items`, request, {
                    params: {
                        expand: includeOrderInResponse ? defaultOrderExpandOptions.join(',') : ''
                    }
                });
                return new DataResponse(OrderBulkOperation, response);
            });
        },
        removeItem(accountName, orderID, orderItemID, includeOrderInResponse = true) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.delete(`${accountName}/orders/${orderID}/items/${orderItemID}`, {
                    params: {
                        expand: includeOrderInResponse ? defaultOrderExpandOptions.join(',') : ''
                    }
                });
                return new DataResponse(Order, response);
            });
        },
        updateItemQuantity(accountName, orderID, orderItemID, quantity) {
            return client.put(`${accountName}/orders/${orderID}/items/${orderItemID}/quantity`, {
                quantity
            });
        },
        updateItemsQuantity(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/items/updateQuantity`, request);
        },
        updateItemModifiers(accountName, orderID, orderItemID, request) {
            return client.put(`${accountName}/orders/${orderID}/items/${orderItemID}/modifiers`, request);
        },
        updateItemNoteAndFireMessage(accountName, orderID, orderItemID, request) {
            return client.put(`${accountName}/orders/${orderID}/items/${orderItemID}/noteAndFireMessage`, request);
        },
        overrideItemPrice(accountName, orderID, orderItemID, request, options) {
            return client.post(`${accountName}/orders/${orderID}/items/${orderItemID}/priceOverride`, request, options);
        },
        removeItemPriceOverride(accountName, orderID, orderItemID) {
            return client.delete(`${accountName}/orders/${orderID}/items/${orderItemID}/priceOverride`);
        },
        applySpecialToItem(accountName, orderID, orderItemID, request) {
            return client.post(`${accountName}/orders/${orderID}/items/${orderItemID}/specials`, request);
        },
        substitutePackageItem(accountName, orderID, orderItemID, packageItemOptionBillableItemID, packageItemBillableItemID) {
            return client.put(`${accountName}/orders/${orderID}/items/${orderItemID}/substitute/
        ${packageItemOptionBillableItemID}/${packageItemBillableItemID}`);
        },
        updateServer(accountName, orderID, serverID) {
            return client.post(`${accountName}/orders/${orderID}/server`, { value: serverID });
        },
        addPayments(accountName, orderID, request, includeOrderInResponse = true) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/orders/${orderID}/payments`, request, {
                    timeout: paymentTimeout,
                    params: {
                        expand: includeOrderInResponse ? defaultOrderExpandOptions.join(',') : ''
                    }
                });
                return new DataResponse(OrderBulkOperation, response);
            });
        },
        addPaymentFromPinPad(accountName, orderID, request, includeOrderInResponse = true) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/orders/${orderID}/paymentsFromPinPad`, request, {
                    params: {
                        expand: includeOrderInResponse ? defaultOrderExpandOptions.join(',') : ''
                    }
                });
                return new DataResponse(OrderBulkOperation, response);
            });
        },
        addAuthorizationFromPinPad(accountName, orderID, request, includeOrderInResponse = true) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/orders/${orderID}/authorizationsFromPinPad`, request, {
                    params: {
                        expand: includeOrderInResponse ? defaultOrderExpandOptions.join(',') : ''
                    }
                });
                return new DataResponse(OrderBulkOperation, response);
            });
        },
        addPaymentRefundFromPinPad(accountName, orderID, paymentItemID, request, includeOrderInResponse = true) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/orders/${orderID}/payments/${paymentItemID}/orderPaymentRefundFromPinPad`, request, {
                    params: {
                        expand: includeOrderInResponse ? defaultOrderExpandOptions.join(',') : ''
                    }
                });
                return new DataResponse(Order, response);
            });
        },
        removePayment(accountName, orderID, paymentItemID, includeOrderInResponse = true) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.delete(`${accountName}/orders/${orderID}/payments/${paymentItemID}`, {
                    timeout: paymentTimeout,
                    params: {
                        expand: includeOrderInResponse ? defaultOrderExpandOptions.join(',') : ''
                    }
                });
                return new DataResponse(Order, response);
            });
        },
        getItemGreenFees(accountName, orderID, orderItemID) {
            return client.get(`${accountName}/orders/${orderID}/items/${orderItemID}/green-fees`);
        },
        updateItemGreenFee(accountName, orderID, orderItemID, request) {
            return client.patch(`${accountName}/orders/${orderID}/Items/${orderItemID}/green-fee`, request);
        },
        getOrderRefundInformation(accountName, orderID) {
            return client.get(`${accountName}/orders/${orderID}/refundInformation`);
        },
        refundOrder(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/refund`, request, {
                timeout: paymentTimeout
            });
        },
        executeManagerRefund(accountName, request, options) {
            if (options) {
                options.timeout = paymentTimeout;
            }
            else {
                options = {
                    timeout: paymentTimeout
                };
            }
            return client.post(`${accountName}/orders/executeManagerRefund`, request, options);
        },
        getTransparentRedirectToken(accountName, cashRegisterID) {
            return client.get(`${accountName}/orders/TRToken/${cashRegisterID}`, {
                timeout: paymentTimeout
            });
        },
        issueRaincheck(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/rainchecks/issue`, request);
        },
        applyRaincheck(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/rainchecks`, request);
        },
        removeRaincheck(accountName, orderID, orderItemID, raincheckID) {
            return client.delete(`${accountName}/orders/${orderID}/items/${orderItemID}/rainchecks/${raincheckID}`);
        },
        removeSpecial(accountName, orderID, orderItemID, specialID) {
            return client.delete(`${accountName}/orders/${orderID}/items/${orderItemID}/specials/${specialID}`);
        },
        overrideSpecial(accountName, orderID, orderItemID, specialID) {
            return client.post(`${accountName}/orders/${orderID}/items/${orderItemID}/specials/${specialID}/override`);
        },
        completeCreditCardPaymentItem(accountName, orderID, paymentItemID) {
            return client.post(`${accountName}/orders/${orderID}/payments/${paymentItemID}/completeCreditCardPaymentItem`, undefined, {
                timeout: paymentTimeout
            });
        },
        getOrderNotes(accountName, orderID) {
            return client.get(`${accountName}/orders/${orderID}/notes`);
        },
        getOrderNote(accountName, orderID, orderNoteID) {
            return client.get(`${accountName}/orders/${orderID}/notes/${orderNoteID}`);
        },
        createOrderNote(accountName, orderID, request) {
            return client.post(`${accountName}/orders/${orderID}/notes`, request);
        },
        updateOrderNote(accountName, orderID, orderNoteID, request) {
            return client.put(`${accountName}/orders/${orderID}/notes/${orderNoteID}`, request);
        },
        deleteOrderNote(accountName, orderID, orderNoteID) {
            return client.delete(`${accountName}/orders/${orderID}/notes/${orderNoteID}`);
        },
        getPaymentSignature(accountName, orderID, paymentItemID) {
            return client.get(`${accountName}/orders/${orderID}/Payments/${paymentItemID}/Signature`, { responseType: 'blob' });
        },
        getGNRefundReasons(accountName) {
            return client.get(`${accountName}/orders/Refund/GNRefundReasons`);
        }
    };
}
