export function createAdhocCategoryService(client) {
    return {
        getGreenFeeAdhocCategories(accountName) {
            return client.get(`${accountName}/adhocCategories/greenFee`);
        },
        getGreenFeeTaxAdhocCategories(accountName) {
            return client.get(`${accountName}/adhocCategories/greenFee/taxes`);
        }
    };
}
