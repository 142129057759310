import { defineComponent } from 'vue';
export function CustomIcon(iconSvg) {
    return {
        component: defineComponent({
            functional: true,
            render(h) {
                return h('i', {
                    domProps: {
                        innerHTML: iconSvg
                    },
                    class: 'v-icon'
                });
            }
        })
    };
}
