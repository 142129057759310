import { __awaiter } from "tslib";
import { ref } from 'vue';
import { generateUniqueString } from '../../utils';
const dialogCloseDelay = 300;
function createDialog(config, componentAttrs) {
    let resolveResult = null;
    const key = generateUniqueString(6);
    const value = new Promise(resolve => {
        resolveResult = resolve;
    });
    return {
        key,
        isOpened: true,
        isHidden: false,
        component: config.component,
        keepAlive: config.keepAlive === true,
        componentAttrs,
        vDialogAttrs: config.vDialogAttrs,
        result: {
            key,
            getValue: () => value
        },
        setResult(result) {
            resolveResult === null || resolveResult === void 0 ? void 0 : resolveResult(result);
        }
    };
}
export function createG1DialogsService() {
    const dialogs = ref([]);
    function open(configOrKey, componentAttrs) {
        if (typeof (configOrKey) === 'string') {
            const dialog = dialogs.value.find((d) => d.key === configOrKey);
            if (dialog === undefined) {
                throw new Error(`Dialog with key ${configOrKey}} is not found.
        Use keep alive property in G1DialogConfig to keep dialog after the closing`);
            }
            dialog.componentAttrs = componentAttrs !== null && componentAttrs !== void 0 ? componentAttrs : dialog.componentAttrs;
            dialog.isOpened = true;
            return dialog.result;
        }
        const dialog = createDialog(configOrKey, componentAttrs);
        dialogs.value.push(dialog);
        return dialog.result;
    }
    function close(key, forceNotKeepAlive = false) {
        const dialog = dialogs.value.find(d => d.key === key);
        if (dialog === undefined) {
            return;
        }
        dialog.isOpened = false;
        if (forceNotKeepAlive || !dialog.keepAlive) {
            setTimeout(() => {
                dialogs.value.splice(dialogs.value.indexOf(dialog), 1);
            }, dialogCloseDelay);
        }
    }
    function hide(key) {
        const dialog = dialogs.value.find(d => d.key === key);
        if (dialog === undefined) {
            return;
        }
        dialog.isHidden = true;
    }
    function show(key) {
        const dialog = dialogs.value.find(d => d.key === key);
        if (dialog === undefined) {
            return;
        }
        dialog.isHidden = false;
    }
    function openPopup(config, componentAttrs) {
        const popupAttrs = {
            width: 618,
            scrollable: true,
            persistent: true
        };
        return open(Object.assign(Object.assign({}, config), { vDialogAttrs: Object.assign(Object.assign({}, popupAttrs), config.vDialogAttrs) }), componentAttrs);
    }
    return {
        dialogs,
        openPopup,
        open,
        close,
        hide,
        show,
        forceCloseAll() {
            for (const dialog of dialogs.value) {
                close(dialog.key, true);
            }
        },
        openConfirmDialog(componentAttrs, vDialogAttrs) {
            return __awaiter(this, void 0, void 0, function* () {
                const config = {
                    component: () => import('../../components/G1ConfirmDialog.vue'),
                    vDialogAttrs: Object.assign({ width: '400px', persistent: true }, vDialogAttrs)
                };
                return open(Object.assign(Object.assign({}, config), { vDialogAttrs: config.vDialogAttrs }), Object.assign({}, componentAttrs)).getValue();
            });
        },
        openMessageDialog(componentAttrs, vDialogAttrs) {
            return __awaiter(this, void 0, void 0, function* () {
                const config = {
                    component: () => import('../../components/G1MessageDialog.vue'),
                    vDialogAttrs: Object.assign({ width: '400px', persistent: true }, vDialogAttrs)
                };
                return open(Object.assign(Object.assign({}, config), { vDialogAttrs: config.vDialogAttrs }), Object.assign({}, componentAttrs)).getValue();
            });
        }
    };
}
