export function createCashRegisterService(client) {
    return {
        searchCashRegisters(accountName, request) {
            return client.post(`${accountName}/cashRegisters/search`, request);
        },
        getCloseOutPeriodByDate(accountName, cashRegisterID, closeOutDateTime) {
            return client.get(`${accountName}/cashRegisters/${cashRegisterID}/closeOutPeriods`, { params: { closeOutDateTime } });
        },
        getCloseOutPeriodReport(accountName, closeOutPeriodID) {
            return client.get(`${accountName}/cashRegisters/closeOutPeriods/${closeOutPeriodID}/report`);
        },
        noSaleOpenDrawerCashRegister(accountName, cashRegisterID, request) {
            return client.post(`${accountName}/cashRegisters/${cashRegisterID}/noSaleOpenDrawer`, request);
        },
        getLastCloseOutPeriod(accountName, cashRegisterID) {
            return client.get(`${accountName}/cashRegisters/${cashRegisterID}/lastCloseOutPeriod`);
        },
        searchPayins(accountName, cashRegisterID, request) {
            return client.post(`${accountName}/cashRegisters/${cashRegisterID}/payins/search`, Object.assign(Object.assign({}, request), { cashRegisterID }));
        },
        getPayin(accountName, cashRegisterID, cashRegisterPayinID) {
            return client.get(`${accountName}/cashRegisters/${cashRegisterID}/payins/${cashRegisterPayinID}`);
        },
        createPayin(accountName, cashRegisterID, request) {
            return client.post(`${accountName}/cashRegisters/${cashRegisterID}/payins`, Object.assign(Object.assign({}, request), { cashRegisterID }));
        },
        updatePayin(accountName, cashRegisterID, cashRegisterPayinID, request) {
            return client.put(`${accountName}/cashRegisters/${cashRegisterID}/payins/${cashRegisterPayinID}`, Object.assign(Object.assign({}, request), { cashRegisterID }));
        },
        deletePayin(accountName, cashRegisterID, cashRegisterPayinID) {
            return client.delete(`${accountName}/cashRegisters/${cashRegisterID}/payins/${cashRegisterPayinID}`);
        },
        searchPayouts(accountName, cashRegisterID, request) {
            return client.post(`${accountName}/cashRegisters/${cashRegisterID}/payouts/search`, Object.assign(Object.assign({}, request), { cashRegisterID }));
        },
        getPayout(accountName, cashRegisterID, cashRegisterPayoutID) {
            return client.get(`${accountName}/cashRegisters/${cashRegisterID}/payouts/${cashRegisterPayoutID}`);
        },
        createPayout(accountName, cashRegisterID, request) {
            return client.post(`${accountName}/cashRegisters/${cashRegisterID}/payouts`, Object.assign(Object.assign({}, request), { cashRegisterID }));
        },
        updatePayout(accountName, cashRegisterID, cashRegisterPayoutID, request) {
            return client.put(`${accountName}/cashRegisters/${cashRegisterID}/payouts/${cashRegisterPayoutID}`, Object.assign(Object.assign({}, request), { cashRegisterID }));
        },
        deletePayout(accountName, cashRegisterID, cashRegisterPayoutID) {
            return client.delete(`${accountName}/cashRegisters/${cashRegisterID}/payouts/${cashRegisterPayoutID}`);
        },
        getCurrentCloseOutPeriod(accountName, cashRegisterID) {
            return client.get(`${accountName}/cashRegisters/${cashRegisterID}/currentCloseOutPeriod`);
        },
        getCloseOutPeriod(accountName, closeOutPeriodID) {
            return client.get(`${accountName}/cashRegisters/closeOutPeriods/${closeOutPeriodID}`);
        },
        closeCloseOutPeriod(accountName, closeOutPeriodID, request) {
            return client.post(`${accountName}/cashRegisters/closeOutPeriods/${closeOutPeriodID}/close`, request);
        },
        updateCloseOutPeriod(accountName, closeOutPeriodID, request) {
            return client.put(`${accountName}/cashRegisters/closeOutPeriods/${closeOutPeriodID}`, request);
        },
        searchCloseOutPeriods(accountName, request) {
            return client.post(`${accountName}/cashRegisters/closeOutPeriods/search`, request);
        },
        getCloseOutPeriodExpectedValues(accountName, cashRegisterID, request) {
            return client.post(`${accountName}/cashRegisters/${cashRegisterID}/closeOutExpectedValues`, request);
        }
    };
}
