export var CommunicationMethod;
(function (CommunicationMethod) {
    CommunicationMethod["HomePhone"] = "homePhone";
    CommunicationMethod["MobilePhone"] = "mobilePhone";
    CommunicationMethod["WorkPhone"] = "workPhone";
    CommunicationMethod["Email"] = "email";
})(CommunicationMethod || (CommunicationMethod = {}));
export var CustomerRaincheckStatus;
(function (CustomerRaincheckStatus) {
    CustomerRaincheckStatus["Valid"] = "valid";
    CustomerRaincheckStatus["Redeemed"] = "redeemed";
    CustomerRaincheckStatus["Expired"] = "expired";
    CustomerRaincheckStatus["Canceled"] = "canceled";
})(CustomerRaincheckStatus || (CustomerRaincheckStatus = {}));
export var CustomerMessagesCategory;
(function (CustomerMessagesCategory) {
    CustomerMessagesCategory[CustomerMessagesCategory["General"] = 0] = "General";
    CustomerMessagesCategory[CustomerMessagesCategory["TeesheetNotification"] = 1] = "TeesheetNotification";
    CustomerMessagesCategory[CustomerMessagesCategory["CreditAccountStatement"] = 2] = "CreditAccountStatement";
    CustomerMessagesCategory[CustomerMessagesCategory["MembershipBillingStatement"] = 3] = "MembershipBillingStatement";
    CustomerMessagesCategory[CustomerMessagesCategory["GiftCertificate"] = 4] = "GiftCertificate";
    CustomerMessagesCategory[CustomerMessagesCategory["SmartPlayOrderConfirmation"] = 5] = "SmartPlayOrderConfirmation";
    CustomerMessagesCategory[CustomerMessagesCategory["SmartPlayOutForDelivery"] = 6] = "SmartPlayOutForDelivery";
    CustomerMessagesCategory[CustomerMessagesCategory["OrderReceipt"] = 7] = "OrderReceipt";
})(CustomerMessagesCategory || (CustomerMessagesCategory = {}));
export var CustomerMessagesFormat;
(function (CustomerMessagesFormat) {
    CustomerMessagesFormat[CustomerMessagesFormat["Email"] = 0] = "Email";
    CustomerMessagesFormat[CustomerMessagesFormat["SMS"] = 1] = "SMS";
})(CustomerMessagesFormat || (CustomerMessagesFormat = {}));
export var CustomerMessagesStatus;
(function (CustomerMessagesStatus) {
    CustomerMessagesStatus[CustomerMessagesStatus["Queued"] = 0] = "Queued";
    CustomerMessagesStatus[CustomerMessagesStatus["FailedToQueue"] = 1] = "FailedToQueue";
    CustomerMessagesStatus[CustomerMessagesStatus["Successful"] = 2] = "Successful";
    CustomerMessagesStatus[CustomerMessagesStatus["Failed"] = 3] = "Failed";
})(CustomerMessagesStatus || (CustomerMessagesStatus = {}));
export var CustomerPaymentMethod;
(function (CustomerPaymentMethod) {
    CustomerPaymentMethod["GiftCertificate"] = "Gift Certificate";
    CustomerPaymentMethod["GiftCard"] = "Gift Card";
    CustomerPaymentMethod["Raincheck"] = "Raincheck";
})(CustomerPaymentMethod || (CustomerPaymentMethod = {}));
export var CustomerNoteType;
(function (CustomerNoteType) {
    CustomerNoteType["Regular"] = "regular";
    CustomerNoteType["Caution"] = "caution";
    CustomerNoteType["Warning"] = "warning";
})(CustomerNoteType || (CustomerNoteType = {}));
export var CustomerNoteTypeNumber;
(function (CustomerNoteTypeNumber) {
    CustomerNoteTypeNumber[CustomerNoteTypeNumber["Regular"] = 1] = "Regular";
    CustomerNoteTypeNumber[CustomerNoteTypeNumber["Caution"] = 2] = "Caution";
    CustomerNoteTypeNumber[CustomerNoteTypeNumber["Warning"] = 4] = "Warning";
})(CustomerNoteTypeNumber || (CustomerNoteTypeNumber = {}));
export class PreselectedFieldsDisabler {
    constructor() {
        Object.defineProperty(this, "statsPropsVocab", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                'creditAccount',
                'loyaltyPoints',
                'totalGiftCards',
                'totalGiftCertificates',
                'totalOrders',
                'totalRainchecks',
                'totalSeries'
            ]
        });
    }
    disableHandler(key) {
        return (this.statsPropsVocab.includes(key) || key === 'memberships' || key === 'dateCreated');
    }
}
export var CustomerSeriesStatus;
(function (CustomerSeriesStatus) {
    CustomerSeriesStatus["NotUsed"] = "notUsed";
    CustomerSeriesStatus["PartiallyUsed"] = "partiallyUsed";
    CustomerSeriesStatus["Used"] = "used";
    CustomerSeriesStatus["Voided"] = "voided";
    CustomerSeriesStatus["Expired"] = "expired";
})(CustomerSeriesStatus || (CustomerSeriesStatus = {}));
export var CustomerMembershipStatus;
(function (CustomerMembershipStatus) {
    CustomerMembershipStatus["Active"] = "active";
    CustomerMembershipStatus["Expired"] = "expired";
    CustomerMembershipStatus["Cancelled"] = "cancelled";
    CustomerMembershipStatus["Inactive"] = "inactive";
    CustomerMembershipStatus["Suspended"] = "suspended";
    CustomerMembershipStatus["Frozen"] = "frozen";
})(CustomerMembershipStatus || (CustomerMembershipStatus = {}));
export var AccountBalanceType;
(function (AccountBalanceType) {
    AccountBalanceType["BRS"] = "brs";
    AccountBalanceType["GolfNow"] = "golfNow";
})(AccountBalanceType || (AccountBalanceType = {}));
export var CustomerBladeTab;
(function (CustomerBladeTab) {
    CustomerBladeTab["Summary"] = "summary";
    CustomerBladeTab["Details"] = "details";
    CustomerBladeTab["Tags"] = "tags";
    CustomerBladeTab["Billing"] = "billing";
    CustomerBladeTab["Notes"] = "notes";
})(CustomerBladeTab || (CustomerBladeTab = {}));
export const customerBladeActionsKey = Symbol('customerBladeActions');
export const customerBladeKey = Symbol('customerBlade');
