import { g1Permissions } from '@/g1';
import { dateMask } from '@/common';
import { Permission } from '@/types';
import globalComponents from './globalComponents';
const plugins = vue => {
    vue.use(globalComponents);
    vue.directive('date-mask', dateMask);
    vue.use(g1Permissions, {
        permissionLookup: Permission
    });
};
export default plugins;
