export function createInventoryService(client) {
    return {
        searchInventory(accountName, request, filter) {
            var _a, _b, _c, _d, _e, _f;
            const r = Object.assign(Object.assign({}, request), { filterBy: filter.productVariantFilterBy, inventoryLevel: filter.inventoryLevel, tierID: (_b = (_a = filter.subcategoryID) !== null && _a !== void 0 ? _a : filter.categoryID) !== null && _b !== void 0 ? _b : filter.departmentID, dateCreatedFrom: (_c = filter.createdDate) === null || _c === void 0 ? void 0 : _c[0], dateCreatedTo: (_d = filter.createdDate) === null || _d === void 0 ? void 0 : _d[1], dateUpdatedFrom: (_e = filter.updatedDate) === null || _e === void 0 ? void 0 : _e[0], dateUpdatedTo: (_f = filter.updatedDate) === null || _f === void 0 ? void 0 : _f[1] });
            return client.post(`${accountName}/inventory/snapshots/search`, r);
        },
        createSnapshot(accountName) {
            return client.post(`${accountName}/inventory/snapshots`, {});
        },
        getLatestSnapshot(accountName) {
            return client.get(`${accountName}/inventory/snapshots/latest`);
        },
        deleteSnapshot(accountName, snapshotId) {
            return client.delete(`${accountName}/inventory/snapshots/${snapshotId}`);
        },
        submitSnapshot(accountName, snapshotId, request) {
            return client.post(`${accountName}/inventory/snapshots/${snapshotId}/commit`, request);
        },
        createInventorySnapshotItem(accountName, snapshotID, productVariantID, request) {
            return client.post(`${accountName}/inventory/snapshots/${snapshotID}/products/${productVariantID}`, request);
        },
        updateInventorySnapshotItem(accountName, snapshotID, productVariantID, request) {
            return client.put(`${accountName}/inventory/snapshots/${snapshotID}/products/${productVariantID}`, request);
        },
        deleteInventorySnapshotItem(accountName, snapshotID, productVariantID) {
            return client.delete(`${accountName}/inventory/snapshots/${snapshotID}/products/${productVariantID}`);
        },
        getVendors(accountName) {
            return client.post(`${accountName}/productVendors/search`, {});
        },
        getInventoryLevels() {
            return client.get('inventoryLevels');
        },
        uploadProductVariantInventory(accountName, request) {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            const formData = new FormData();
            formData.append('file', request.file);
            formData.append('originalFileName', request.originalFileName);
            formData.append('numRowsTotal', request.numRowsTotal.toString());
            formData.append('replaceProductInventoryStockLevel', request.replaceProductInventoryStockLevel.toString());
            formData.append('typeID', request.typeID.toString());
            formData.append('statusID', request.statusID.toString());
            return client.post(`${accountName}/inventory/import`, formData, config);
        }
    };
}
