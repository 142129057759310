export var MembershipStatus;
(function (MembershipStatus) {
    MembershipStatus["Active"] = "active";
    MembershipStatus["Inactive"] = "inactive";
})(MembershipStatus || (MembershipStatus = {}));
export var MembershipSharingLevel;
(function (MembershipSharingLevel) {
    MembershipSharingLevel["None"] = "none";
    MembershipSharingLevel["Location"] = "location";
    MembershipSharingLevel["Brand"] = "brand";
})(MembershipSharingLevel || (MembershipSharingLevel = {}));
export var BenefitsExpirationType;
(function (BenefitsExpirationType) {
    BenefitsExpirationType["Never"] = "never";
    BenefitsExpirationType["Immediately"] = "immediately";
    BenefitsExpirationType["AfterXDays"] = "afterXDays";
})(BenefitsExpirationType || (BenefitsExpirationType = {}));
export var MembershipPaymentPlanPriceType;
(function (MembershipPaymentPlanPriceType) {
    MembershipPaymentPlanPriceType[MembershipPaymentPlanPriceType["OriginalPrice"] = 1] = "OriginalPrice";
    MembershipPaymentPlanPriceType[MembershipPaymentPlanPriceType["CurrentPrice"] = 2] = "CurrentPrice";
    MembershipPaymentPlanPriceType[MembershipPaymentPlanPriceType["FuturePriceChange"] = 4] = "FuturePriceChange";
})(MembershipPaymentPlanPriceType || (MembershipPaymentPlanPriceType = {}));
export var MembershipBenefitStatus;
(function (MembershipBenefitStatus) {
    MembershipBenefitStatus["Draft"] = "draft";
    MembershipBenefitStatus["Active"] = "active";
    MembershipBenefitStatus["Discontinued"] = "discontinued";
})(MembershipBenefitStatus || (MembershipBenefitStatus = {}));
export var MembershipBenefitFreeItemFrequency;
(function (MembershipBenefitFreeItemFrequency) {
    MembershipBenefitFreeItemFrequency["OneTime"] = "oneTime";
    MembershipBenefitFreeItemFrequency["Weekly"] = "weekly";
    MembershipBenefitFreeItemFrequency["Monthly"] = "monthly";
    MembershipBenefitFreeItemFrequency["Daily"] = "daily";
})(MembershipBenefitFreeItemFrequency || (MembershipBenefitFreeItemFrequency = {}));
export var MembershipBenefitExpirationType;
(function (MembershipBenefitExpirationType) {
    MembershipBenefitExpirationType["DaysFromAccruedDate"] = "daysFromAccruedDate";
    MembershipBenefitExpirationType["OneDayBeforeNextAccrualDate"] = "oneDayBeforeNextAccrualDate";
})(MembershipBenefitExpirationType || (MembershipBenefitExpirationType = {}));
export var MembershipCardFormatType;
(function (MembershipCardFormatType) {
    MembershipCardFormatType["Generic"] = "generic";
    MembershipCardFormatType["GolfingUnionOfIreland"] = "golfingUnionOfIreland";
    MembershipCardFormatType["Clubminder"] = "clubminder";
    MembershipCardFormatType["Netgear"] = "netgear";
    MembershipCardFormatType["OpenSolutions"] = "openSolutions";
})(MembershipCardFormatType || (MembershipCardFormatType = {}));
export var MembershipCreditLimitType;
(function (MembershipCreditLimitType) {
    MembershipCreditLimitType["MissedPayments"] = "missedPayments";
    MembershipCreditLimitType["MissedAmount"] = "missedAmount";
})(MembershipCreditLimitType || (MembershipCreditLimitType = {}));
export var MembershipBillingRetryType;
(function (MembershipBillingRetryType) {
    MembershipBillingRetryType["AppendToNextCycle"] = "appendToNextCycle";
    MembershipBillingRetryType["Duration"] = "duration";
    MembershipBillingRetryType["None"] = "none";
})(MembershipBillingRetryType || (MembershipBillingRetryType = {}));
export var MembershipPaymentFrequency;
(function (MembershipPaymentFrequency) {
    MembershipPaymentFrequency["OneTime"] = "oneTime";
    MembershipPaymentFrequency["Monthly"] = "monthly";
    MembershipPaymentFrequency["Quarterly"] = "quarterly";
    MembershipPaymentFrequency["BiMonthly"] = "biMonthly";
    MembershipPaymentFrequency["BiWeekly"] = "biWeekly";
    MembershipPaymentFrequency["Weekly"] = "weekly";
    MembershipPaymentFrequency["Annually"] = "annually";
})(MembershipPaymentFrequency || (MembershipPaymentFrequency = {}));
export var MembershipPaymentExpiration;
(function (MembershipPaymentExpiration) {
    MembershipPaymentExpiration["Months"] = "months";
    MembershipPaymentExpiration["Weeks"] = "weeks";
})(MembershipPaymentExpiration || (MembershipPaymentExpiration = {}));
export var MembershipExpirationType;
(function (MembershipExpirationType) {
    MembershipExpirationType["NeverExpires"] = "neverExpires";
    MembershipExpirationType["ExpiresIn"] = "expiresIn";
    MembershipExpirationType["ExpiresOn"] = "expiresOn";
})(MembershipExpirationType || (MembershipExpirationType = {}));
