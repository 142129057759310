export var OrderExpand;
(function (OrderExpand) {
    OrderExpand["Customer"] = "customer";
    OrderExpand["ApplicableSpecials"] = "applicableSpecials";
    OrderExpand["ApplicablePayments"] = "applicablePayments";
    OrderExpand["RedeemableSeriesItems"] = "redeemableSeriesItems";
    OrderExpand["CreatedByUser"] = "createdByUser";
    OrderExpand["CompletedByUser"] = "completedByUser";
    OrderExpand["CashRegister"] = "cashRegister";
    OrderExpand["Refunds"] = "refunds";
    OrderExpand["Rainchecks"] = "rainchecks";
    OrderExpand["Tables"] = "tables";
    OrderExpand["ModificationHistory"] = "modificationHistory";
    OrderExpand["Taxes"] = "taxes";
    OrderExpand["ServiceFees"] = "serviceFees";
})(OrderExpand || (OrderExpand = {}));
