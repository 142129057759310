import { isNil } from 'lodash';
export function isNilOrWhitespace(value) {
    return typeof value === 'undefined'
        || value === null
        || value.replace(/\s/g, '').length < 1;
}
export function generateUniqueString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export function nullIfNilOrWhitespace(value) {
    return isNilOrWhitespace(value) ? null : value !== null && value !== void 0 ? value : null;
}
export function cleanWhitespace(value) {
    return value.replaceAll(/\s+/g, ' ').trim();
}
export function toPixels(value) {
    return typeof (value) === 'number' ? `${value.toString}px` : value;
}
export function removeHtmlTags(inputString) {
    if (inputString === null || inputString === undefined) {
        return inputString;
    }
    return inputString.replace(/<[^>]*>/g, '');
}
export function getInitials(fullName) {
    if (isNil(fullName)) {
        return '';
    }
    const regex = /[A-Za-z'-]+/g;
    const matches = fullName.match(regex);
    if (!matches) {
        return '';
    }
    let initials = '';
    for (const match of matches) {
        if (match.length > 0) {
            initials += match[0].toUpperCase();
        }
    }
    return initials;
}
export function getSpaced(...values) {
    const valid = values.filter(v => v !== undefined && v !== null);
    return valid.length === 0 ? null : values.join(' ');
}
export function waitForElementToAppear(elementId, callback, maxAttempts = 10, interval = 1000) {
    let attempts = 0;
    function checkElement() {
        const element = document.getElementById(elementId);
        attempts++;
        if (element !== null) {
            callback();
        }
        else if (attempts < maxAttempts) {
            setTimeout(checkElement, interval);
        }
        else {
        }
    }
    checkElement();
}
export function delay(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}
export function isNumeric(str) {
    return typeof str === 'string' && /^[0-9]+$/.test(str);
}
