export function createBackgroundTaskSettingsService(client) {
    return {
        deleteBackgroundTaskSettings(id) {
            return client.delete(`backgroundTaskSettings/${id}`);
        },
        getBackgroundTaskSettings(id) {
            return client.get(`backgroundTaskSettings/${id}`);
        },
        searchBackgroundTaskSettings(request) {
            return client.post('backgroundTaskSettings/search', request);
        },
        createBackgroundTaskSettings(request) {
            return client.post('backgroundTaskSettings', request);
        },
        updateBackgroundTaskSettings(id, request) {
            return client.put(`backgroundTaskSettings/${id}`, request);
        }
    };
}
