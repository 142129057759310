import { __awaiter } from "tslib";
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useCookies } from '@vueuse/integrations/useCookies';
import { useCurrentLocation, useDialogs } from '@/g1';
import { useLocationService, useSessionService } from '@/services';
import { CashRegisterSettingsExpand } from '@/services/locations/getCashRegisterSettings';
export const useLocationStore = defineStore('location', () => {
    var _a;
    const sessionService = useSessionService();
    const locationService = useLocationService();
    const currentLocation = useCurrentLocation();
    const { openPopup } = useDialogs();
    const cashRegisterCookiesKey = `CashRegisterID_${currentLocation.id}`;
    const cookies = useCookies([cashRegisterCookiesKey]);
    const cashRegisterID = ref((_a = cookies.get(cashRegisterCookiesKey)) !== null && _a !== void 0 ? _a : null);
    const cashRegisterSettings = ref(null);
    watch(cashRegisterID, () => __awaiter(void 0, void 0, void 0, function* () {
        if (cashRegisterID.value === null) {
            return;
        }
        const cashRegisterIDFromCookies = cookies.get(cashRegisterCookiesKey);
        if (cashRegisterIDFromCookies !== undefined && cashRegisterID.value === cashRegisterIDFromCookies) {
            return;
        }
        cookies.set(cashRegisterCookiesKey, cashRegisterID.value);
        yield sessionService.setCashRegister(cashRegisterID.value);
        if (cashRegisterSettings.value !== null) {
            yield tryLoadCashRegisterSettings(true);
        }
    }));
    let selectingCashRegister = null;
    function selectCashRegister() {
        return __awaiter(this, void 0, void 0, function* () {
            if (selectingCashRegister === null) {
                selectingCashRegister = internalSelectCashRegister();
            }
            yield selectingCashRegister;
        });
    }
    function internalSelectCashRegister() {
        return __awaiter(this, void 0, void 0, function* () {
            const selectedCashRegisterID = yield openPopup({
                component: () => import('@/pages/Location/Orders/CashRegisterSelectionDialog.vue')
            }, {
                selectedCashRegisterID: cashRegisterID.value,
                cashRegisters: currentLocation.settings.cashRegisters
            }).getValue();
            if (selectedCashRegisterID === false || selectedCashRegisterID === undefined) {
                selectingCashRegister = null;
                return;
            }
            if (cashRegisterID.value !== selectedCashRegisterID) {
                cashRegisterSettings.value = null;
            }
            cashRegisterID.value = selectedCashRegisterID;
            selectingCashRegister = null;
        });
    }
    function getCashRegister() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const cashRegister = cashRegisterID.value === null
                ? null
                : (_a = currentLocation.settings.cashRegisters.find(cr => cr.id === cashRegisterID.value)) !== null && _a !== void 0 ? _a : null;
            if (cashRegister !== null) {
                return cashRegister;
            }
            if (cashRegisterID.value !== null) {
                cashRegisterID.value = null;
            }
            if (selectingCashRegister !== null) {
                yield selectingCashRegister;
            }
            return cashRegister;
        });
    }
    function getCashRegisterSettings() {
        return __awaiter(this, void 0, void 0, function* () {
            yield tryLoadCashRegisterSettings();
            return cashRegisterSettings.value;
        });
    }
    function tryLoadCashRegisterSettings(force = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const currentCashRegister = yield getCashRegister();
            if (currentCashRegister === null) {
                cashRegisterSettings.value = null;
                return;
            }
            if (cashRegisterSettings.value !== null && !force) {
                return;
            }
            const response = yield locationService.getCashRegisterSettings(currentLocation.accountName, currentCashRegister.id, CashRegisterSettingsExpand.PaymentSettings, CashRegisterSettingsExpand.ReceiptSettings, CashRegisterSettingsExpand.PinPads);
            if (!response.successful) {
                return;
            }
            cashRegisterSettings.value = response.data;
        });
    }
    return {
        cashRegisterID,
        getCashRegister,
        getCashRegisterSettings,
        selectCashRegister,
        setCashRegister(id) {
            cashRegisterID.value = id;
            cashRegisterSettings.value = null;
        }
    };
});
