export var LocationStatus;
(function (LocationStatus) {
    LocationStatus["Implementation"] = "implementation";
    LocationStatus["Demo"] = "demo";
    LocationStatus["LiveNoCharge"] = "liveNoCharge";
    LocationStatus["Live"] = "live";
    LocationStatus["Terminated"] = "terminated";
    LocationStatus["ChargeNotLive"] = "chargeNotLive";
    LocationStatus["Copying"] = "copying";
    LocationStatus["Frozen"] = "frozen";
    LocationStatus["Onboarding"] = "onboarding";
    LocationStatus["ReportingOnly"] = "reportingOnly";
})(LocationStatus || (LocationStatus = {}));
export var TeeSheetSystem;
(function (TeeSheetSystem) {
    TeeSheetSystem["All"] = "all";
    TeeSheetSystem["EZLinks"] = "ezlinks";
    TeeSheetSystem["BRS"] = "brs";
})(TeeSheetSystem || (TeeSheetSystem = {}));
export var PhoneFormat;
(function (PhoneFormat) {
    PhoneFormat["NANPA"] = "nanpa";
    PhoneFormat["International"] = "international";
})(PhoneFormat || (PhoneFormat = {}));
export var GenderType;
(function (GenderType) {
    GenderType["Male"] = "male";
    GenderType["Female"] = "female";
})(GenderType || (GenderType = {}));
export var EmployeeStatus;
(function (EmployeeStatus) {
    EmployeeStatus["Active"] = "active";
    EmployeeStatus["Inactive"] = "inactive";
})(EmployeeStatus || (EmployeeStatus = {}));
export var EmployeeType;
(function (EmployeeType) {
    EmployeeType["Scheduled"] = "scheduled";
    EmployeeType["Freelancer"] = "freelancer";
})(EmployeeType || (EmployeeType = {}));
export var PhoneType;
(function (PhoneType) {
    PhoneType["Home"] = "home";
    PhoneType["Mobile"] = "mobile";
})(PhoneType || (PhoneType = {}));
