import { __awaiter } from "tslib";
import { inject } from 'vue';
import { getDialogInjectionKey } from '../features';
import { useDialogs } from './useDialogs';
export function useDialog(options) {
    const dialog = inject(getDialogInjectionKey());
    const { close: closeDialog, hide: hideDialog, show: showDialog } = useDialogs();
    function close(result) {
        if (dialog === undefined) {
            return;
        }
        dialog.setResult(result);
        closeDialog(dialog.key);
    }
    const onKeyDownEscHandlers = [];
    if ((options === null || options === void 0 ? void 0 : options.closeOnKeyDownEsc) === true) {
        onKeyDownEscHandlers.push(close);
    }
    const onKeyDownEnterHandlers = [];
    if (dialog !== undefined) {
        dialog.onKeyDownEsc = () => __awaiter(this, void 0, void 0, function* () {
            for (const handler of onKeyDownEscHandlers) {
                handler();
            }
        });
        dialog.onKeyDownEnter = () => {
            for (const handler of onKeyDownEnterHandlers) {
                handler();
            }
        };
    }
    return {
        close,
        hide() {
            if (dialog === undefined) {
                return;
            }
            hideDialog(dialog.key);
        },
        show() {
            if (dialog === undefined) {
                return;
            }
            showDialog(dialog.key);
        },
        onKeyDownEsc(handler) {
            onKeyDownEscHandlers.push(handler);
        },
        onKeyDownEnter(handler) {
            onKeyDownEnterHandlers.push(handler);
        }
    };
}
