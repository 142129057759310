export var LocationSettingsExpand;
(function (LocationSettingsExpand) {
    LocationSettingsExpand["Mobile"] = "mobile";
    LocationSettingsExpand["Features"] = "features";
    LocationSettingsExpand["Order"] = "order";
    LocationSettingsExpand["CashRegisters"] = "cashRegisters";
    LocationSettingsExpand["LoyaltyProgram"] = "loyaltyProgram";
    LocationSettingsExpand["PaymentSettings"] = "paymentSettings";
    LocationSettingsExpand["ReceiptSettings"] = "receiptSettings";
    LocationSettingsExpand["CourseTypes"] = "courseTypes";
    LocationSettingsExpand["PaymentSettingsOrder"] = "paymentSettings,order";
})(LocationSettingsExpand || (LocationSettingsExpand = {}));
export var SeriesRefundType;
(function (SeriesRefundType) {
    SeriesRefundType["No"] = "no";
    SeriesRefundType["UnusedOnly"] = "neverUsedOnly";
    SeriesRefundType["AnyRemaining"] = "anyRemaining";
})(SeriesRefundType || (SeriesRefundType = {}));
