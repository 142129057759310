import AutoNumeric from 'autonumeric';
import { cloneDeep, isEmpty, merge } from 'lodash';
import { ref } from 'vue';
const defaultAutoNumericOptions = {
    isCancellable: false,
    emptyInputBehavior: 'null',
    overrideMinMaxLimits: 'ignore',
    modifyValueOnWheel: false
};
const defaultCurrency = {
    currencyLocale: 'en-US',
    currency: 'USD'
};
export function createNumberFormatService(globalOptions) {
    const currentLocale = ref(globalOptions.fallbackLocale);
    function getLocaleOptions(format, locale) {
        var _a;
        return (_a = globalOptions.autoNumericOptions[locale]) === null || _a === void 0 ? void 0 : _a[format];
    }
    function getOptions(format, locale, options, fallback = true) {
        var _a, _b;
        locale !== null && locale !== void 0 ? locale : (locale = (_a = currentLocale.value) !== null && _a !== void 0 ? _a : globalOptions.fallbackLocale);
        if ((format === undefined || locale === undefined) && options === undefined) {
            return undefined;
        }
        if ((format === undefined || locale === undefined) && options !== undefined) {
            return options;
        }
        if (format === undefined) {
            return undefined;
        }
        const localeOptions = (_b = getLocaleOptions(format, locale)) !== null && _b !== void 0 ? _b : (fallback ? getLocaleOptions(format, globalOptions.fallbackLocale) : undefined);
        if (localeOptions === undefined && options !== undefined) {
            return options;
        }
        if (options === undefined) {
            return localeOptions;
        }
        return merge(cloneDeep(localeOptions), options);
    }
    function formatNumber(value, locale, arg) {
        let options;
        if (typeof arg === 'string') {
            options = globalOptions.numberFormats[arg];
        }
        else {
            options = arg;
        }
        return new Intl.NumberFormat(locale, options).format(Number(value));
    }
    function getCurrencyFormatOptions(currency) {
        const currencyOptions = globalOptions.currencyFormats[currency.currency];
        return merge({
            style: 'currency',
            currency: currency.currency,
            currencyDisplay: 'narrowSymbol'
        }, currencyOptions);
    }
    function formatCurrency(value, currency = defaultCurrency) {
        var _a;
        const options = getCurrencyFormatOptions(currency);
        const format = Intl.NumberFormat(currency.currencyLocale, options);
        let result = format.format(Number(value));
        const currencySymbol = (_a = format.formatToParts().find(p => p.type === 'currency')) === null || _a === void 0 ? void 0 : _a.value;
        if (currencySymbol !== undefined && (options === null || options === void 0 ? void 0 : options.currencySymbol) !== undefined) {
            result = result.replace(currencySymbol, options === null || options === void 0 ? void 0 : options.currencySymbol);
        }
        return result;
    }
    function getCurrencySymbol(currency) {
        const options = getCurrencyFormatOptions(currency);
        if ((options === null || options === void 0 ? void 0 : options.currencySymbol) !== undefined) {
            return options.currencySymbol;
        }
        const currencyPart = new Intl.NumberFormat(currency.currencyLocale, options)
            .formatToParts().find(p => p.type === 'currency');
        if (currencyPart === undefined) {
            return '';
        }
        return currencyPart.value;
    }
    return {
        setLocale(locale) {
            currentLocale.value = locale;
        },
        bindInput(input, initialValue, formatOrOptions, localeOrOptions, options) {
            const format = typeof formatOrOptions === 'string' ? formatOrOptions : undefined;
            const locale = typeof localeOrOptions === 'string' ? localeOrOptions : undefined;
            if (typeof formatOrOptions === 'object') {
                options = formatOrOptions;
            }
            else if (typeof localeOrOptions === 'object') {
                options = localeOrOptions;
            }
            const autoNumericOptions = merge(cloneDeep(defaultAutoNumericOptions), getOptions(format, locale, options));
            return new AutoNumeric(input, initialValue, autoNumericOptions);
        },
        formatNumber,
        formatCurrency,
        formatRateTypeAmount(amount, rateType, currency) {
            if (isEmpty(amount)) {
                return '';
            }
            if (rateType.toLowerCase() === 'percent') {
                return `${amount}%`;
            }
            if (rateType.toLowerCase() === 'flat') {
                return formatCurrency(amount, currency);
            }
            return '';
        },
        getLocaleOptions,
        getCurrencySymbol
    };
}
