import { convertDateOnlyToISOString, convertToTimeZone } from '../utils';
import { useCurrentAccount } from './context';
import { useI18n } from './useI18n';
export function useCurrentDate() {
    const { d } = useI18n();
    let { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    try {
        timeZone = useCurrentAccount().timeZone;
    }
    catch (_a) {
    }
    function convertDate(date) {
        return convertToTimeZone(new Date(date), timeZone);
    }
    function formatDate(date, dateTimeFormatKey) {
        return d(convertDate(date), dateTimeFormatKey);
    }
    return {
        timeZone,
        convertDate,
        formatDate,
        convertDateOnlyToISOString(dateOnly) {
            return convertDateOnlyToISOString(dateOnly, timeZone);
        }
    };
}
