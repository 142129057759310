export var RolloutFlag;
(function (RolloutFlag) {
    RolloutFlag["VuePaymentSettings"] = "rollout-vue-payment-settings";
    RolloutFlag["VueCashRegisterClose"] = "rollout-vue-cash-register-close";
    RolloutFlag["VueReports"] = "rollout-vue-reports";
    RolloutFlag["VueInventory"] = "rollout-vue-inventory";
    RolloutFlag["VueTeeSheet"] = "rollout-vue-teesheet";
    RolloutFlag["VueTaxes"] = "rollout-vue-taxes";
    RolloutFlag["VueProductLines"] = "rollout-smartplay-productlines";
    RolloutFlag["VueOnlinePayments"] = "rollout-online-payments";
    RolloutFlag["VueManageCustomers"] = "rollout-vue-manage-customers";
})(RolloutFlag || (RolloutFlag = {}));
