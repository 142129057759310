import { render, staticRenderFns } from "./GActionsButton.vue?vue&type=template&id=d395ca8e"
import script from "./GActionsButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./GActionsButton.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports