const isRc = window.location.hostname.includes('rc');
const isDevelopment = process.env.NODE_ENV === 'development';
const isProduction = process.env.NODE_ENV === 'production' && !isRc;
const isStaging = isRc;
export function useAppConfig() {
    var _a, _b, _c, _d;
    return {
        autoCompleteDelay: 500,
        paymentTimeout: 210000,
        version: process.env.VUE_APP_VERSION,
        isGtmEnabled: process.env.VUE_APP_GTM_ENABLED === '1',
        apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
        accessTokenExpirationBufferSeconds: Number(process.env.VUE_APP_ACCESS_TOKEN_EXPIRATION_BUFFER_SECONDS),
        googleAnalyticsCode: process.env.VUE_APP_GTM_GOOGLE_ANALYTICS_CODE,
        isStaging,
        isDevelopment,
        isProduction,
        launchDarklyClientId: (_a = process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_ID) !== null && _a !== void 0 ? _a : '',
        contactUsUrl: process.env.VUE_APP_CONTACT_US_URL,
        authBaseUrl: process.env.VUE_APP_AUTH_BASE_URL,
        publicPath: process.env.VUE_APP_PUBLIC_PATH,
        legacyApiBaseUrl: (_b = process.env.VUE_APP_LEGACY_API_BASE_URL) !== null && _b !== void 0 ? _b : '',
        appSyncEndpoint: (_c = process.env.VUE_APP_APPSYNC_ENDPOINT) !== null && _c !== void 0 ? _c : null,
        reportingSeverBaseUrl: (_d = process.env.VUE_APP_REPORTING_SERVER_BASE_URL) !== null && _d !== void 0 ? _d : ''
    };
}
