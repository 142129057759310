import { __awaiter } from "tslib";
import { useAppConfig, createHttpClient } from '@/g1';
import { useAuthStore } from '@/store';
import router from '@/router';
import { createAccountIntegrationsService } from './accountIntegrations';
import { createAdhocCategoryService } from './adhocCategories';
import { createAreaService } from './areas';
import { createAuthService } from './auth';
import { createBackgroundTaskLogService } from './backgroundTaskLogs';
import { createBackgroundTaskSettingsService } from './backgroundTaskSettings';
import { createBatchService } from './batches';
import { createBillingTypeService } from './billingTypes';
import { createBrandService } from './brands';
import { createCashRegisterService } from './cashRegisters';
import { createCatalogService } from './catalog';
import { createCreditBookService } from './creditBooks';
import { createCustomerNoteService } from './customerNotes';
import { createCustomerService } from './customers';
import { createEmployeeService } from './employees';
import { createGiftCertificateService } from './giftCertificates';
import { createGiftProgramService } from './giftPrograms';
import { createLocationService } from './locations';
import { createLogService } from './logs';
import { createLookupService } from './lookups';
import { createLoyaltyProgramService } from './loyaltyPrograms';
import { createMembershipService } from './memberships';
import { createMenuService } from './menus';
import { createNotificationService } from './notifications';
import { createOrderService } from './orders';
import { createPackageService } from './packages';
import { createPartnerService } from './partners';
import { createProductBrandService } from './productBrands';
import { createProductService } from './products';
import { createQuickBooksSettingsService } from './quickbooks';
import { createRaincheckService } from './rainchecks';
import { createReportService } from './reports';
import { createReprocessQueueService } from './reprocessQueue';
import { createSchedulingService } from './scheduling';
import { createSecurityService } from './security';
import { createServiceService } from './services';
import { createSessionService } from './session';
import { createSettingService } from './setting';
import { createTagService } from './tags';
import { createTaxService } from './taxes';
import { createTeeSheetService } from './teeSheet';
import { createTierService } from './tiers';
import { createTransactionAuditService } from './transactionAudits';
import { createUserService } from './users';
import { createStatusService } from './status';
import { createPaymentsSettingsService } from './paymentsSettings/paymentsSettingsService';
import { createReportsListService } from './reportsList/reportsListService';
import { createReportScheduleService } from './reports/reportScheduleService';
import { createInventoryService } from './inventory';
import { createDisplayService } from './displays/displayService';
import { createTimeService } from './time';
import { createAchTransactionService } from './achTransactions';
const { apiBaseUrl, authBaseUrl, paymentTimeout, legacyApiBaseUrl } = useAppConfig();
const client = createHttpClient({ baseURL: apiBaseUrl }, {
    accessTokenFactory: () => {
        var _a, _b;
        const authStore = useAuthStore();
        return (_b = (_a = authStore.authToken) === null || _a === void 0 ? void 0 : _a.accessToken) !== null && _b !== void 0 ? _b : null;
    },
    refreshAccessToken: () => {
        const authStore = useAuthStore();
        return authStore.refreshAccessToken();
    },
    onUnauthorized: logout
});
const authClient = createHttpClient({ baseURL: authBaseUrl });
client.interceptors.request.use((request) => __awaiter(void 0, void 0, void 0, function* () {
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
        logout();
    }
    if (yield authStore.isRefreshTokenExpired()) {
        logout();
    }
    if (authStore.authToken.isPermissionGrantToken) {
        return request;
    }
    if (yield authStore.isAccessTokenExpired()) {
        yield authStore.refreshAccessToken();
        if (!authStore.isAuthenticated) {
            logout();
        }
    }
    return request;
}));
const g1ApiClient = createHttpClient({ baseURL: '/app/api/' }, {
    onUnauthorized: logout
});
const legacyClient = createHttpClient({ baseURL: legacyApiBaseUrl }, {
    onUnauthorized: logout
});
export function useAreaService() {
    return createAreaService(client);
}
export function useReportScheduleService() {
    return createReportScheduleService(client);
}
export function useAuthService() {
    return createAuthService({ client, g1ApiClient, authClient });
}
export function useLocationService() {
    return createLocationService(client);
}
export function useLookupService() {
    return createLookupService(client);
}
export function useBrandService() {
    return createBrandService(client);
}
export function useOrderService() {
    return createOrderService(client, {
        paymentTimeout
    });
}
export function useBatchService() {
    return createBatchService(client);
}
export function useCreditBookService() {
    return createCreditBookService(client);
}
export function useCustomerService() {
    return createCustomerService(client);
}
export function useCustomerNoteService() {
    return createCustomerNoteService(client);
}
export function useMembershipService() {
    return createMembershipService(client);
}
export function useNotificationService() {
    return createNotificationService(client);
}
export function useEmployeeService() {
    return createEmployeeService(client);
}
export function usePartnerService() {
    return createPartnerService(client);
}
export function useMenuService() {
    return createMenuService(client);
}
export function useCatalogService() {
    return createCatalogService(client);
}
export function useRaincheckService() {
    return createRaincheckService(client);
}
export function useCashRegisterService() {
    return createCashRegisterService(client);
}
export function useGiftCertificateService() {
    return createGiftCertificateService(client);
}
export function useGiftProgramService() {
    return createGiftProgramService(client);
}
export function usePackageService() {
    return createPackageService(client);
}
export function useProductService() {
    return createProductService(client);
}
export function useProductBrandService() {
    return createProductBrandService(client);
}
export function useReportService() {
    return createReportService(client);
}
export function useSessionService() {
    return createSessionService({ g1ApiClient });
}
export function useTagService() {
    return createTagService(client);
}
export function useTeeSheetService() {
    return createTeeSheetService(client);
}
export function useScheduleService() {
    return createSchedulingService(client);
}
export function useBackgroundTaskLogService() {
    return createBackgroundTaskLogService(client);
}
export function useBackgroundTaskSettingsService() {
    return createBackgroundTaskSettingsService(client);
}
export function useBillingTypeService() {
    return createBillingTypeService(client);
}
export function useUserService() {
    return createUserService(client);
}
export function useTierService() {
    return createTierService(client);
}
export function useSecurityService() {
    return createSecurityService(client);
}
export function useSettingService() {
    return createSettingService(client);
}
export function useTaxService() {
    return createTaxService(client);
}
export function useTransactionAuditService() {
    return createTransactionAuditService(client);
}
export function useLogService() {
    return createLogService(client);
}
export function useServiceService() {
    return createServiceService(client);
}
export function useAccountIntegrationsService() {
    return createAccountIntegrationsService(client);
}
export function useQuickBooksSettingsService() {
    return createQuickBooksSettingsService(client);
}
export function useReprocessQueueService() {
    return createReprocessQueueService(client);
}
export function useLoyaltyProgramService() {
    return createLoyaltyProgramService(client);
}
export function useAdhocCategoryService() {
    return createAdhocCategoryService(client);
}
export function useStatusService() {
    return createStatusService(client);
}
export function usePaymentsSettingsService() {
    return createPaymentsSettingsService();
}
export function useReportsListService() {
    return createReportsListService(client);
}
export function useInventoryService() {
    return createInventoryService(client);
}
export function useDisplayService() {
    return createDisplayService(client);
}
export function useTimeService() {
    return createTimeService(client);
}
export function useAchTransactionService() {
    return createAchTransactionService(client);
}
export function logout() {
    const authStore = useAuthStore();
    authStore.logout();
    router.push({ name: 'login', params: { timeout: '4' } });
    throw new Error('Unauthorized');
}
