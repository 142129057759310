import { Flag, Permission } from '@/types';
import { BrandLocationManagementType } from '@/types/brands';
import { redirectToLegacy } from './utils';
const routes = [
    {
        name: 'brand.locations',
        path: 'locations',
        beforeEnter: redirectToLegacy('/BrandAdmin/Spas/SearchSpas.aspx'),
        meta: {
            hasEveryPermission: [Permission.BrandLocationGetAll]
        }
    },
    {
        name: 'brand.locations.impersonate',
        path: 'locations/:id',
        beforeEnter: redirectToLegacy(r => `/BrandAdmin/Spas/Impersonate.aspx?SpaID=${r.params.id}`),
        meta: {
            hasEveryPermission: [Permission.LocationGet]
        }
    },
    {
        name: 'brand.customers',
        path: 'customers',
        component: () => import('@/pages/Account/Customers/CustomersPage.vue'),
        meta: {
            hasEveryPermission: [Permission.CustomerManage],
            legacyRoute: { name: 'brand.customers.legacy' }
        },
        props: {
            legacyRoute: { name: 'brand.customers.legacy' },
            updateRoute: { name: 'brand.customers.update' },
            updateDetailsRoute: { name: 'brand.customers.update.details' }
        }
    },
    {
        name: 'brand.customers.legacy',
        path: 'customers/legacy',
        beforeEnter: redirectToLegacy('/BrandAdmin/Customers/SearchCustomers.aspx'),
        meta: {
            hasEveryPermission: [Permission.CustomerManage]
        }
    },
    {
        name: 'brand.customers.fields',
        path: 'customers/documents',
        beforeEnter: redirectToLegacy('/BrandAdmin/Customers/Crm/Default.aspx'),
        meta: {
            isAvailableInBrand: brand => !brand.settings.forceNotShareCustomFields && brand.settings.allowCommonCustomer,
            hasEveryPermission: [Permission.CustomerFieldManage]
        }
    },
    {
        name: 'brand.customers.documents',
        path: 'customers/documents',
        beforeEnter: redirectToLegacy('/BrandAdmin/Customers/Documents/SearchDocuments.aspx'),
        meta: {
            hasEveryPermission: [Permission.CustomerDocumentGet]
        }
    },
    {
        name: 'brand.customers.duplicates',
        path: 'customers/duplicates',
        component: () => import('@/pages/Location/Customers/findDuplicates.vue'),
        meta: {
            hasEveryPermission: [Permission.BrandCustomerGetAll]
        }
    },
    {
        name: 'brand.customers.duplicates.merge',
        path: 'customers/duplicates/mergeDuplicates/:uid',
        component: () => import('@/pages/Location/Customers/mergeDuplicates.vue'),
        meta: {
            hasEveryPermission: [Permission.BrandCustomerGetAll]
        }
    },
    {
        name: 'brand.customers.update',
        path: 'customers/:id',
        beforeEnter: redirectToLegacy(r => `/BrandAdmin/Customers/EditCustomer.aspx?Operation=View&CustomerID=${r.params.id}`)
    },
    {
        name: 'brand.customers.update.details',
        path: 'customers/:id/details',
        beforeEnter: redirectToLegacy(r => `/BrandAdmin/Customers/EditCustomer.aspx?Operation=Update&CustomerID=${r.params.id}&Tab=Details.aspx`)
    },
    {
        name: 'brand.members',
        path: 'members/',
        redirect: { name: 'brand.members.memberships' }
    },
    {
        name: 'brand.members.memberships',
        path: 'members/memberships',
        component: () => import('@/pages/Account/Members/Memberships/MembershipsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchMemberships]
        }
    },
    {
        name: 'brand.members.memberships.create',
        path: 'members/memberships/create',
        component: () => import('@/pages/Account/Members/Memberships/MembershipCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.CreateMembership]
        }
    },
    {
        name: 'brand.members.memberships.update',
        path: 'members/memberships/:id',
        component: () => import('@/pages/Account/Members/Memberships/MembershipCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.UpdateMembership]
        }
    },
    {
        name: 'brand.members.creditBooks',
        path: 'members/creditBooks',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBooksPage.vue'),
        meta: {
            isAvailableInBrand: brand => brand.settings.features.enableNewCreditBooks,
            hasEveryPermission: [Permission.SearchCreditBooks]
        }
    },
    {
        name: 'brand.members.creditBooks.create',
        path: 'members/creditBooks/create',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBookCreateUpdatePage.vue'),
        meta: {
            isAvailableInBrand: brand => brand.settings.features.enableNewCreditBooks,
            hasEveryPermission: [Permission.CreateCreditBooks]
        }
    },
    {
        name: 'brand.members.creditBooks.distribute',
        path: 'members/creditBooks/distribute',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBooksDistributePage.vue'),
        meta: {
            isAvailableInBrand: brand => brand.settings.features.enableNewCreditBooks,
            hasEveryPermission: [Permission.DistributeCreditBooks]
        }
    },
    {
        name: 'brand.members.creditBooks.update',
        path: 'members/creditBooks/:CBID',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBookCreateUpdatePage.vue'),
        meta: {
            isAvailableInBrand: brand => brand.settings.features.enableNewCreditBooks,
            hasEveryPermission: [Permission.UpdateCreditBooks]
        }
    },
    {
        name: 'brand.members.creditBooks.history',
        path: 'members/creditBooks/:CBID/history',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBookHistoryPage.vue'),
        meta: {
            isAvailableInBrand: brand => brand.settings.features.enableNewCreditBooks,
            hasEveryPermission: [Permission.SearchCreditBooks]
        }
    },
    {
        name: 'brand.members.creditBooks.expiration',
        path: 'members/creditBooks/:CBID/expiration',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBookExpirationPage.vue'),
        meta: {
            isAvailableInBrand: brand => brand.settings.features.enableNewCreditBooks,
            hasEveryPermission: [Permission.ExpireCreditBooks]
        }
    },
    {
        name: 'brand.orders',
        path: 'orders/',
        beforeEnter: redirectToLegacy('/BrandAdmin/Orders/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.SearchOrders]
        }
    },
    {
        name: 'brand.orders.giftCertificates',
        path: 'orders/giftCertificates',
        beforeEnter: redirectToLegacy('/BrandAdmin/Orders/GiftCertificates.aspx'),
        meta: {
            hasEveryPermission: [Permission.SearchGiftCertificates]
        }
    },
    {
        name: 'brand.marketing',
        path: 'marketing/',
        beforeEnter: redirectToLegacy('/BrandAdmin/Marketing/Default.aspx')
    },
    {
        name: 'brand.marketing.emailTemplates',
        path: 'brand/marketing/emailTemplates',
        beforeEnter: redirectToLegacy('/BrandAdmin/Marketing/EmailSettings/EmailTemplates.aspx'),
        meta: {
            hasEveryPermission: [Permission.EmailTemplateGet]
        }
    },
    {
        name: 'brand.marketing.loyaltyPrograms',
        path: 'marketing/loyaltyPrograms',
        component: () => import('@/pages/Brand/Settings/LoyaltyPrograms/LoyaltyProgramsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LoyaltyProgramGet]
        }
    },
    {
        name: 'brand.marketing.loyaltyPrograms.create',
        path: 'marketing/loyaltyPrograms/create',
        component: () => import('@/pages/Brand/Settings/LoyaltyPrograms/LoyaltyProgramCreatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.LoyaltyProgramCreate]
        }
    },
    {
        name: 'brand.marketing.loyaltyPrograms.detail',
        path: 'marketing/loyaltyPrograms/:id',
        component: () => import('@/pages/Account/Settings/LoyaltyPrograms/LoyaltyProgramDetailPage.vue'),
        props: {
            loyaltyProgramsRouteName: 'brand.marketing.loyaltyPrograms'
        },
        meta: {
            hasEveryPermission: [Permission.LoyaltyProgramGet]
        }
    },
    {
        name: 'brand.products',
        path: 'products/',
        beforeEnter: redirectToLegacy('/BrandAdmin/Products/ManageProducts.aspx'),
        meta: {
            isAvailableInBrand: isProductBrandManageable
        }
    },
    {
        name: 'brand.products.create',
        path: 'products/create',
        beforeEnter: redirectToLegacy('/BrandAdmin/Products/EditProduct.aspx?Operation=Add'),
        meta: {
            isAvailableInBrand: isProductBrandManageable
        }
    },
    {
        name: 'brand.products.upload',
        path: 'products/upload',
        beforeEnter: redirectToLegacy('/BrandAdmin/Products/ListProductsUploads.aspx'),
        meta: {
            isAvailableInBrand: isProductBrandManageable,
            hasEveryPermission: [Permission.ProductUpload]
        }
    },
    {
        name: 'brand.products.uploadParentChild',
        path: 'products/uploadParentChild',
        beforeEnter: redirectToLegacy('/BrandAdmin/Products/ListParentChildProductsUploads.aspx'),
        meta: {
            isAvailableInBrand: isProductBrandManageable,
            hasEveryPermission: [Permission.ProductUpload]
        }
    },
    {
        name: 'brand.products.vendorsProductBrands',
        path: 'products/vendorsProductBrands',
        beforeEnter: redirectToLegacy('/BrandAdmin/Products/Vendors/Default.aspx'),
        meta: {
            isAvailableInBrand: isProductBrandManageable,
            hasEveryPermission: [Permission.VendorGet]
        }
    },
    {
        name: 'brand.reports.legacy',
        path: 'reports/legacy',
        beforeEnter: redirectToLegacy('/BrandAdmin/Reports/ListReports.aspx'),
        meta: {
            hasEveryPermission: [Permission.BrandReportGet],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueReports
        }
    },
    {
        name: 'brand.reports',
        path: 'reports',
        component: () => import('@/pages/Location/Reports/ReportsList/ReportsList.vue'),
        meta: {
            hasEveryPermission: [Permission.BrandReportGet],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueReports
        }
    },
    {
        name: 'brand.reports.schedule',
        path: 'reports/schedule',
        beforeEnter: redirectToLegacy('/BrandAdmin/Reports/Schedule.aspx'),
        meta: {
            hasEveryPermission: [Permission.BrandReportGet]
        }
    },
    {
        name: 'brand.settings',
        path: 'settings/',
        beforeEnter: redirectToLegacy('/BrandAdmin/BrandSettings/Settings/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.BrandSettingsManage]
        }
    },
    {
        name: 'brand.settings.creditAccount',
        path: 'settings/creditAccount',
        component: () => import('@/pages/Brand/Settings/CreditAccount/manageCreditAccount.vue'),
        meta: {
            hasEveryPermission: [Permission.GetBillingTypes]
        }
    }
];
function isProductBrandManageable(brand) {
    return brand.settings.brandManageProducts === BrandLocationManagementType.Both
        || brand.settings.brandManageProducts === BrandLocationManagementType.Brand;
}
export default routes;
