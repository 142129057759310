import { __awaiter } from "tslib";
import { inject, ref } from 'vue';
import { flagsPluginKey } from '../plugins/flags';
export function useFlagsContext() {
    const plugin = inject(flagsPluginKey);
    if (!plugin) {
        throw new Error('Flags plugin not found');
    }
    const ready = ref(false);
    return {
        setContext(context) {
            return __awaiter(this, void 0, void 0, function* () {
                ready.value = false;
                yield plugin.setContext(context);
                ready.value = true;
            });
        },
        ready
    };
}
