export class SearchFilterFieldsClass {
    constructor() {
        Object.defineProperty(this, "firstName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "lastName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "phone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
    }
}
export class SearchGroupFiltersClass {
    constructor() {
        Object.defineProperty(this, "structure", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                {
                    name: 'First Name',
                    selected: false,
                    label: 'firstName'
                },
                {
                    name: 'Last Name',
                    selected: false,
                    label: 'lastName'
                },
                {
                    name: 'Email',
                    selected: false,
                    label: 'email'
                },
                {
                    name: 'Phone',
                    selected: false,
                    label: 'phone'
                }
            ]
        });
    }
}
export class CombinedFromOriginalDuplicatesUser {
    constructor() {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "dateCreated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "firstName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "lastName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "dateOfBirth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "anniversaryDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "homePhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "mobilePhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "workPhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "location", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "preferredContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "address", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "taxExempt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "membershipCardNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "creditCards", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "memberships", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "creditAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "creditAccountBillingType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "creditBooks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "loyaltyPoints", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "totalSeries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "totalOrders", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "totalRainchecks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "totalGiftCertificates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "totalGiftCards", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "regularNotes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "warningNotes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
        Object.defineProperty(this, "cautionNotes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                value: undefined,
                key: undefined,
                id: undefined,
                selected: undefined
            }
        });
    }
}
export class DuplicateStructure {
    constructor() {
        Object.defineProperty(this, "selectionDuplicateStucture", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                { key: 'location' },
                { key: 'dateCreated' },
                { key: 'firstName' },
                { key: 'lastName' },
                { key: 'dateOfBirth' },
                { key: 'anniversaryDate' },
                { key: 'address' },
                { key: 'email' },
                { key: 'homePhone' },
                { key: 'mobilePhone' },
                { key: 'workPhone' },
                { key: 'preferredContact' },
                { key: 'taxExempt' },
                { key: 'membershipCardNumber' },
                { key: 'creditAccount' },
                { key: 'creditAccountBillingType' },
                { key: 'creditBooks' },
                { key: 'loyaltyPoints' },
                { key: 'totalOrders' },
                { key: 'totalRainchecks' },
                { key: 'totalGiftCertificates' },
                { key: 'totalGiftCards' },
                { key: 'totalSeries' },
                { key: 'memberships' },
                { key: 'creditCards' },
                { key: 'regularNotes' },
                { key: 'warningNotes' },
                { key: 'cautionNotes' },
                { key: 'id' }
            ]
        });
        Object.defineProperty(this, "idRequiredList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                'location',
                'dateCreated',
                'dateOfBirth',
                'anniversaryDate',
                'address',
                'preferredContact',
                'taxExempt',
                'membershipCardNumber',
                'creditCards',
                'creditAccountBillingType',
                'regularNotes',
                'warningNotes',
                'cautionNotes'
            ]
        });
    }
}
export class GroupRequestOptionsClass {
    constructor() {
        Object.defineProperty(this, "filter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "groupBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
    }
}
export class DetailsRequestOptionsClass {
    constructor() {
        Object.defineProperty(this, "filters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "pageSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 5
        });
        Object.defineProperty(this, "pageIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
    }
}
export class mergeCombinerStructure {
    constructor() {
        Object.defineProperty(this, "schema", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                {
                    label: undefined,
                    isEditable: false,
                    key: 'location'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'dateCreated'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'firstName'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'lastName'
                },
                {
                    label: undefined,
                    isEditable: false,
                    key: 'dateOfBirth'
                },
                {
                    label: undefined,
                    isEditable: false,
                    key: 'anniversaryDate'
                },
                {
                    label: undefined,
                    hasTextArea: true,
                    key: 'address'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'email'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'homePhone'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'mobilePhone'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'workPhone'
                },
                {
                    label: undefined,
                    isEditable: false,
                    key: 'preferredContact'
                },
                {
                    label: undefined,
                    isEditable: false,
                    key: 'taxExempt'
                },
                {
                    label: undefined,
                    isEditable: false,
                    key: 'membershipCardNumber'
                },
                {
                    label: undefined,
                    isEditable: false,
                    key: 'creditAccount'
                },
                {
                    label: undefined,
                    isEditable: false,
                    key: 'creditAccountBillingType'
                },
                {
                    label: undefined,
                    isEditable: false,
                    hasTextArea: true,
                    key: 'creditBooks'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'loyaltyPoints'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'totalOrders'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'totalRainchecks'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'totalGiftCertificates'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'totalGiftCards'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'totalSeries'
                },
                {
                    label: undefined,
                    isEditable: false,
                    membershipsList: true,
                    key: 'memberships'
                },
                {
                    label: undefined,
                    isEditable: false,
                    key: 'creditCards',
                    hasTextArea: true
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'regularNotes'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'warningNotes'
                },
                {
                    label: undefined,
                    isEditable: true,
                    key: 'cautionNotes'
                }
            ]
        });
    }
}
