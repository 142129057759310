import { __awaiter } from "tslib";
export function createMembershipService(client) {
    return {
        searchMemberships(accountName, request) {
            return client.post(`${accountName}/memberships/search`, request);
        },
        searchOnlineMemberships(accountName, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/memberships/searchOnline`, request);
            });
        },
        getMembership(accountName, membershipID) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.get(`${accountName}/memberships/${membershipID}`);
            });
        },
        createMembership(accountName, membership) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/Memberships`, membership);
            });
        },
        updateMembership(accountName, membership) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.put(`${accountName}/Memberships/${membership.id}`, membership);
            });
        },
        deleteMembership(accountName, membershipID) {
            return client.delete(`${accountName}/memberships/${membershipID}`);
        },
        getMembershipSettings(accountName) {
            return client.get(`${accountName}/membershipSettings`);
        },
        updatePriorities(accountName, memberships) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.put(`${accountName}/Memberships/Priorities`, memberships);
            });
        },
        saveMembershipSettings(accountName, membershipSettings) {
            return __awaiter(this, void 0, void 0, function* () {
                return membershipSettings.id > 0
                    ? client.put(`${accountName}/MembershipSettings`, membershipSettings)
                    : client.post(`${accountName}/MembershipSettings`, membershipSettings);
            });
        }
    };
}
