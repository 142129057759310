import { ref } from 'vue';
function flattenPermissions(permissions) {
    return permissions.reduce((acc, p) => [...acc, ...(Array.isArray(p) ? p : [p])], []);
}
export function createG1PermissionsService() {
    const userPermissions = ref([]);
    function hasEveryPermission(...permissions) {
        const flatPermissions = flattenPermissions(permissions);
        return flatPermissions.length === 0 || flatPermissions
            .every(p => userPermissions.value.includes(p));
    }
    function hasSomePermission(...permissions) {
        const flatPermissions = flattenPermissions(permissions);
        return flatPermissions.length === 0 || flatPermissions
            .some(p => userPermissions.value.includes(p));
    }
    return {
        hasEveryPermission,
        hasSomePermission,
        hasAccessToRoute(route) {
            var _a;
            const meta = (_a = route.meta) !== null && _a !== void 0 ? _a : {};
            if (meta.hasEveryPermission !== undefined) {
                return hasEveryPermission(meta.hasEveryPermission);
            }
            if (meta.hasSomePermission !== undefined) {
                return hasSomePermission(meta.hasSomePermission);
            }
            return true;
        },
        setUserPermissions(permissions) {
            userPermissions.value = permissions;
        }
    };
}
export const g1PermissionsService = createG1PermissionsService();
