import examplePhoneNumbers from 'libphonenumber-js/examples.mobile.json';
import { getExampleNumber, isPossiblePhoneNumber } from 'libphonenumber-js';
import { computed, unref } from 'vue';
import { helpers } from '@vuelidate/validators';
export function usePhoneInput(options) {
    const countryCode = computed(() => unref(options.countryCode));
    const mask = computed(() => {
        const example = getExampleNumber(countryCode.value, examplePhoneNumbers);
        if (example === undefined) {
            throw new Error('Invalid country code for phone field.');
        }
        const nationalFormat = example.formatNational();
        return nationalFormat.replace(/\d/g, '#');
    });
    return {
        mask,
        isPossiblePhoneNumber(value) {
            return !helpers.req(value) || isPossiblePhoneNumber(value, countryCode.value);
        }
    };
}
