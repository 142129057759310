import { __awaiter } from "tslib";
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useTransactionAuditService } from '@/services';
export const useTransactionStatusStore = defineStore('transactionStatus', () => {
    const transactionService = useTransactionAuditService();
    const transactionStatuses = ref(null);
    const transactionStatusesLoaded = ref(false);
    watch(transactionStatusesLoaded, () => __awaiter(void 0, void 0, void 0, function* () {
        if (transactionStatusesLoaded.value === true) {
            return;
        }
        if (transactionStatusesLoaded.value !== null) {
            yield tryLoadTransactionStatuses(true);
        }
    }));
    function getTransactionStatuses() {
        return __awaiter(this, void 0, void 0, function* () {
            yield tryLoadTransactionStatuses();
            return transactionStatuses.value;
        });
    }
    function tryLoadTransactionStatuses(force = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (transactionStatusesLoaded.value === true && !force) {
                transactionStatusesLoaded.value = true;
                return;
            }
            const response = yield transactionService.getTransactionStatuses();
            if (!response.successful) {
                return;
            }
            transactionStatuses.value = response.data.data;
            transactionStatusesLoaded.value = true;
        });
    }
    return {
        transactionStatusesLoaded,
        getTransactionStatuses
    };
});
