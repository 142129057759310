import { getCurrentInstance } from 'vue';
export function usePrinter() {
    var _a;
    const app = (_a = getCurrentInstance()) === null || _a === void 0 ? void 0 : _a.proxy;
    if (app === undefined) {
        throw new Error('usePrinter can be used only in setup function');
    }
    return {
        print: app.$printer.print.bind(app.$printer)
    };
}
