export function createUserService(client) {
    return {
        getUser(id) {
            return client.get(`users/${id}`);
        },
        searchUsers(request) {
            return client.post('user/search', request);
        },
        searchLocationUsers(accountName, request) {
            return client.post(`${accountName}/users/search`, request);
        }
    };
}
