export function createLogService(client) {
    return {
        searchCreditCardTransactions(request) {
            return client.post('log/creditCardTransaction/search', request);
        },
        getCreditCardTransactionDetail(id) {
            return client.get(`log/creditCardTransaction/${id}`);
        },
        searchEventLogs(request) {
            return client.post('eventLog/search', request);
        }
    };
}
