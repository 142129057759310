const directive = {
    inserted(el, binding) {
        var _a;
        if (JSON.parse((_a = window === null || window === void 0 ? void 0 : window.localStorage.g1_qa_enabled) !== null && _a !== void 0 ? _a : false)) {
            const { id } = binding.value;
            if (binding.value.isList) {
                const listItems = document.querySelectorAll(`[data-qa-class='${id}']`);
                el.setAttribute('data-qa-id', `${id}-${listItems.length}`);
                el.setAttribute('data-qa-class', id);
            }
            else {
                el.setAttribute('data-qa-id', id);
            }
        }
    }
};
export default directive;
