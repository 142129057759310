export function createNotificationService(client) {
    return {
        getActiveNotifications(accountName, refreshFeaturedProductCache = false) {
            let params = { clientType: 'desktop' };
            if (accountName !== undefined) {
                params = Object.assign(Object.assign({}, params), { accountName });
            }
            if (refreshFeaturedProductCache) {
                params = Object.assign(Object.assign({}, params), { refreshFeaturedProductCache });
            }
            return client.get('notifications/active', { params });
        },
        dismissActiveNotification(notificationID) {
            return client.post(`notifications/${notificationID}/dismiss`);
        },
        getNotification(id) {
            return client.get(`notifications/${id}`);
        },
        searchNotifications(request) {
            return client.post('notifications/search', request);
        },
        createNotification(request) {
            return client.post('notifications', request);
        },
        updateNotification(id, request) {
            return client.put(`notifications/${id}`, request);
        }
    };
}
