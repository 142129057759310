import { __awaiter } from "tslib";
import { HttpStatusCode } from '@/g1';
export function createReportService(client) {
    return {
        getQuickbooksIifSalesExportRpt(accountName, request) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/quickbooks/salesexportrpt`, request, { responseType: 'blob' });
                if (response.status === HttpStatusCode.Ok) {
                    const fileNameMatch = (_a = response === null || response === void 0 ? void 0 : response.headers['content-disposition']) === null || _a === void 0 ? void 0 : _a.match(/filename\s*=\s*"(.+)";/i);
                    const fileName = (_b = fileNameMatch === null || fileNameMatch === void 0 ? void 0 : fileNameMatch[1]) !== null && _b !== void 0 ? _b : ' - Sales - .iif';
                    const blob = new Blob([response.data], { type: 'application/octet-stream' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                    return true;
                }
                return false;
            });
        },
        getQuickbooksIifCashReceiptsExportRpt(accountName, request) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client
                    .post(`${accountName}/quickbooks/cashreceiptsexportrpt`, request, { responseType: 'blob' });
                if (response.status === HttpStatusCode.Ok) {
                    const fileNameMatch = (_a = response === null || response === void 0 ? void 0 : response.headers['content-disposition']) === null || _a === void 0 ? void 0 : _a.match(/filename\s*=\s*"(.+)";/i);
                    const fileName = (_b = fileNameMatch === null || fileNameMatch === void 0 ? void 0 : fileNameMatch[1]) !== null && _b !== void 0 ? _b : ' - Cash - .iif';
                    const blob = new Blob([response.data], { type: 'application/octet-stream' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                    return true;
                }
                return false;
            });
        },
        getAvailableForTransfer(accountName, request) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/quickbooks/transfers/available`, request);
                if (response.status === HttpStatusCode.Ok) {
                    const items = response.data;
                    return items;
                }
                return [];
            });
        },
        transferJournalEntries(accountName, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/quickbooks/transfers`, request);
            });
        },
        getTransferPreview(accountName, request) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/quickbooks/transfers/preview`, request);
                if (response.status === HttpStatusCode.Ok) {
                    const items = response.data;
                    return items;
                }
                return [];
            });
        },
        searchTransfers(accountName, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/quickbooks/transfers/search`, request);
            });
        },
        deleteTransfer(accountName, transferId) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.delete(`${accountName}/quickbooks/transfers/${transferId}`);
            });
        },
        getTransfer(accountName, transferId) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.get(`${accountName}/quickbooks/transfers/${transferId}`);
            });
        }
    };
}
