import { __awaiter } from "tslib";
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useNotificationService } from '@/services';
export const useNotificationStore = defineStore('notifications', () => {
    const notificationService = useNotificationService();
    const serverNotifications = ref([]);
    const localNotifications = ref([]);
    const notifications = computed(() => [...localNotifications.value, ...serverNotifications.value]);
    function loadNotifications(accountName = undefined, refreshFeaturedProductCache = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield notificationService.getActiveNotifications(accountName, refreshFeaturedProductCache);
            if (response.successful) {
                serverNotifications.value = response.data.map(n => (Object.assign(Object.assign({}, n), { severity: getNotificationSeverity(n.color) })));
            }
        });
    }
    function sendLocal(notification) {
        localNotifications.value.push(notification);
    }
    function dismiss(notification) {
        return __awaiter(this, void 0, void 0, function* () {
            if ('id' in notification) {
                serverNotifications.value.splice(serverNotifications.value.indexOf(notification), 1);
                yield notificationService.dismissActiveNotification(notification.id);
                return;
            }
            localNotifications.value.splice(localNotifications.value.indexOf(notification), 1);
        });
    }
    function getNotificationSeverity(color) {
        switch (color) {
            case 'red':
                return 'error';
            case 'yellow':
                return 'warning';
            default:
                return 'success';
        }
    }
    return {
        notifications,
        localNotifications,
        serverNotifications,
        loadNotifications,
        sendLocal,
        dismiss
    };
});
