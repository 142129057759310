export function createGiftCertificateService(client) {
    return {
        searchGiftCertificates(accountName, request) {
            return client.post(`${accountName}/giftCertificates/search`, request);
        },
        getGiftCertificateTemplates(accountName) {
            return client.get(`${accountName}/giftCertificateTemplates`);
        }
    };
}
