import { __awaiter } from "tslib";
import { ReprocessQueue } from '@/types/domain';
import { DataResponse } from '@/types/common';
export function createReprocessQueueService(client) {
    return {
        searchReprocessQueue(request) {
            return client.post('ReprocessQueue/Search', request);
        },
        getReprocessQueue(itemID) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.get(`reprocessQueue/${itemID}`);
                return new DataResponse(ReprocessQueue, response);
            });
        },
        processReprocessQueue(itemID, request) {
            return client.post(`reprocessQueue/${itemID}/process`, request);
        },
        getReprocessQueueEntityTypes() {
            return client.get('reprocessQueue/EntityTypes');
        },
        getReprocessQueueStatuses() {
            return client.get('reprocessQueue/Statuses');
        }
    };
}
