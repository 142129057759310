import Vue from 'vue';
import Vuetify from 'vuetify';
import '@fortawesome/fontawesome-free/css/all.css';
import CalendarIcon from '@/assets/icons/calendar.svg?raw';
import { CustomIcon } from '@/common';
Vue.use(Vuetify);
const icons = {
    check: 'mdi-check-circle',
    delete: 'mdi-delete-outline',
    deleteCircle: 'mdi-delete-circle',
    calendar: CustomIcon(CalendarIcon),
    email: 'mdi-at',
    error: 'mdi-alert-circle-outline',
    mobilePhone: 'mdi-cellphone',
    modifiers: 'mdi-puzzle',
    search: 'mdi-magnify',
    view: 'mdi-eye',
    update: 'mdi-pencil-outline',
    minus: 'mdi-minus',
    trash: 'mdi-trash-can',
    creditAccount: 'mdi-account-circle-outline',
    giftCertificate: 'mdi-wallet-giftcard',
    rewardPoints: 'mdi-tag-heart',
    membership: 'mdi-wallet-membership',
    series: 'mdi-calendar-sync-outline',
    raincheck: 'mdi-wallet-giftcard',
    creditBooks: 'mdi-account-cash-outline',
    externalAccountBalance: 'mdi-account-cash-outline',
    creditCard: 'mdi-credit-card-outline',
    upload: 'mdi-upload',
    refresh: 'mdi-refresh',
    newOrder: 'mdi-cart-plus',
    notes: 'mdi-message-text-outline',
    newPage: 'mdi-note-plus-outline',
    add: 'mdi-plus-circle',
    warning: 'mdi-alert-circle',
    success: 'mdi-check-circle',
    notification: 'mdi-bell',
    info: 'mdi-information',
    cancel: 'mdi-cancel',
    print: 'mdi-printer',
    expand: 'mdi-chevron-down',
    continue: 'mdi-arrow-right-circle',
    moreVert: 'mdi-dots-vertical',
    void: 'mdi-note-remove',
    backdate: 'mdi-arrow-left-circle',
    share: 'mdi-share-variant',
    cart: 'mdi-cart',
    locked: 'mdi-lock',
    navigate: 'mdi-arrow-right-bold-circle',
    undo: 'mdi-undo'
};
export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                background: '#F9F9F9',
                tableOddRow: '#f9f9f9',
                surface: '#FFFFFF',
                primary: '#004778',
                secondary: '#96C28F',
                accent: '#2C861A',
                success: '#007D3E',
                error: '#DC2626',
                warning: '#FF8F00',
                info: '#007BB2',
                'primary-text': '#212529',
                'secondary-text': '#6C6D75',
                'tertiary-text': '#3D9AEB',
                border: '#E1E1E1'
            },
            dark: {
                background: '#121212',
                tableOddRow: '#272727',
                surface: '#424242',
                primary: '#007AC9',
                secondary: '#96C28F',
                accent: '#2C861A',
                success: '#007D3E',
                error: '#DC2626',
                warning: '#ED8500',
                info: '#007BB2',
                'primary-text': '#FFFFFF',
                'secondary-text': '#B0B3BA',
                'tertiary-text': '#64B5F6',
                border: '#2F2E2E'
            }
        }
    },
    icons: {
        iconfont: 'mdi',
        values: icons
    }
});
