import { __awaiter } from "tslib";
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useLookupService, useTimeService } from '@/services';
import i18n, { loadLocaleMessages } from '@/plugins/localization';
import { useAuthStore, useNotificationStore } from '..';
export const useAppStore = defineStore('app', () => {
    var _a;
    const isLoading = ref(false);
    const loadingPromises = [];
    function doWhileLoading(promise) {
        return __awaiter(this, void 0, void 0, function* () {
            loadingPromises.push(promise);
            if (!isLoading.value) {
                isLoading.value = true;
            }
            try {
                return yield promise;
            }
            finally {
                const index = loadingPromises.indexOf(promise);
                if (index > -1) {
                    loadingPromises.splice(index, 1);
                }
                if (loadingPromises.length === 0) {
                    isLoading.value = false;
                }
            }
        });
    }
    const authStore = useAuthStore();
    const isDarkModeStorageKey = 'g1_darkMode';
    function isDarkModeUserStorageKey(userID) {
        return `g1_${userID}_darkMode`;
    }
    const isDarkMode = ref(localStorage.getItem(isDarkModeStorageKey) === 'true');
    watch(() => authStore.isAuthorized, () => {
        if (!authStore.isAuthorized) {
            return;
        }
        isDarkMode.value = localStorage.getItem(isDarkModeUserStorageKey(authStore.currentUser.id)) === 'true';
    }, { immediate: true });
    watch(isDarkMode, () => {
        localStorage.setItem(isDarkModeStorageKey, isDarkMode.value.toString());
        if (authStore.isAuthorized) {
            localStorage.setItem(isDarkModeUserStorageKey(authStore.currentUser.id), isDarkMode.value.toString());
        }
        document.documentElement.classList.toggle('theme--dark', isDarkMode.value);
    }, { immediate: true });
    const localeStorageKey = 'locale';
    const locale = ref((_a = localStorage.getItem(localeStorageKey)) !== null && _a !== void 0 ? _a : i18n.locale);
    watch(locale, () => __awaiter(void 0, void 0, void 0, function* () {
        localStorage.setItem(localeStorageKey, locale.value);
        yield loadLocaleMessages(locale.value);
    }), { immediate: true });
    const navigationItems = ref([]);
    const currentNavigationItems = ref([]);
    const navigationItemNameOverrides = ref([]);
    function clearNavigationItemText(name) {
        const index = navigationItemNameOverrides.value.findIndex(o => o.name === name);
        if (index > -1) {
            navigationItemNameOverrides.value.splice(index, 1);
        }
    }
    function setNavigationItemText({ name, text }) {
        const existingItem = navigationItemNameOverrides.value.find(i => i.name === name);
        if (existingItem) {
            existingItem.text = text;
        }
        else {
            navigationItemNameOverrides.value.push({ name, text });
        }
    }
    const lookupService = useLookupService();
    const currencies = ref([]);
    function loadCurrencies() {
        return __awaiter(this, void 0, void 0, function* () {
            if (currencies.value.length > 0) {
                return;
            }
            const response = yield lookupService.getCurrencies();
            if (!response.successful) {
                return;
            }
            currencies.value = response.data.map(c => ({
                currency: c.code,
                currencyLocale: c.culture,
                name: c.name,
                id: c.id
            }));
        });
    }
    function getCurrency(code) {
        var _a;
        return (_a = currencies.value.find(c => c.currency === code)) !== null && _a !== void 0 ? _a : null;
    }
    const timeService = useTimeService();
    const notificationStore = useNotificationStore();
    const serverTime = ref(null);
    const hasServerTimeMismatch = ref(false);
    watch(serverTime, () => {
        if (serverTime.value === null) {
            return;
        }
        const now = new Date();
        const diff = Math.abs(now.getTime() - new Date(serverTime.value).getTime());
        const diffInMinutes = diff / (1000 * 60);
        hasServerTimeMismatch.value = diffInMinutes > 1;
    }, { immediate: true });
    watch(hasServerTimeMismatch, () => {
        if (!hasServerTimeMismatch.value) {
            return;
        }
        notificationStore.sendLocal({
            severity: 'warning',
            message: i18n.t('hasServerTimeMismatch.notification').toString(),
            permanent: true
        });
    });
    function loadServerTime() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield timeService.getServerTime();
            if (response.successful) {
                serverTime.value = response.data.serverTime;
            }
        });
    }
    return {
        isLoading,
        doWhileLoading,
        isDarkMode,
        locale,
        navigationItems,
        currentNavigationItems,
        navigationItemNameOverrides,
        clearNavigationItemText,
        setNavigationItemText,
        currencies,
        loadCurrencies,
        getCurrency,
        serverTime,
        hasServerTimeMismatch,
        loadServerTime
    };
});
