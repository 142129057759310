export function createSettingService(client) {
    return {
        getSmartPlayFeeConfiguration(accountName) {
            return client.get(`${accountName}/smartPlay/feeConfiguration`);
        },
        getSmartPlayMobileSettings(accountName) {
            return client.get(`${accountName}/smartPlay/mobileSettings`);
        },
        getSmartPlayMobileNotes(accountName) {
            return client.get(`${accountName}/smartPlay/mobileNotes`);
        },
        getSmartPlayFeaturedProductsSettingsByAccountName(accountName) {
            return client.get(`${accountName}/smartPlay/featuredProductsSettings`);
        },
        saveSmartPlayMobileSettings(accountName, settings) {
            return client.post(`${accountName}/smartPlay/mobileSettings`, settings);
        },
        saveSmartPlayMobileNotes(accountName, settings) {
            return client.post(`${accountName}/smartPlay/mobileNotes`, settings);
        },
        saveFeaturedProductsSettings(accountName, settings) {
            return client.post(`${accountName}/smartPlay/featuredProductsSettings`, settings);
        }
    };
}
