import { __awaiter } from "tslib";
import axios from 'axios';
import { HttpStatusCode, isValidationErrorResponse } from '../../types';
export function createHttpClient(axiosOptions = {}, clientOptions = {}) {
    function updateAuthorizationHeader(headers) {
        var _a, _b;
        const accessToken = (_b = (_a = clientOptions.accessTokenFactory) === null || _a === void 0 ? void 0 : _a.call(clientOptions)) !== null && _b !== void 0 ? _b : null;
        const authHeaderName = 'Authorization';
        if (accessToken === null) {
            headers.delete(authHeaderName);
            return;
        }
        headers.set(authHeaderName, `Bearer ${accessToken}`);
    }
    const httpClient = axios.create(Object.assign({ validateStatus: () => true, transformRequest: (data, headers) => {
            updateAuthorizationHeader(headers);
            if (headers['Content-Type'] !== 'application/json') {
                return data;
            }
            return typeof (data) === 'object'
                ? JSON.stringify(data)
                : data;
        }, headers: {
            'Content-Type': 'application/json'
        } }, axiosOptions));
    httpClient.clientOptions = clientOptions;
    useInterceptors(httpClient);
    return httpClient;
}
function useInterceptors(httpClient) {
    const { clientOptions } = httpClient;
    function unauthorizedResponseInterceptor(response) {
        return __awaiter(this, void 0, void 0, function* () {
            if (response.status !== HttpStatusCode.Unauthorized) {
                return response;
            }
            if (response.request === undefined) {
                return response;
            }
            if (!response.config.$$refreshed && clientOptions.refreshAccessToken !== undefined) {
                response.config.$$refreshed = true;
                yield clientOptions.refreshAccessToken();
                const retriedResponse = yield httpClient.request(Object.assign(Object.assign({}, response.config), { headers: response.config.headers.toJSON() }));
                response.config.$$refreshed = false;
                return retriedResponse;
            }
            const unauthorizedResponse = response;
            let handlerReturn;
            if (response.config.onUnauthorized !== undefined) {
                handlerReturn = response.config.onUnauthorized(unauthorizedResponse, () => (clientOptions.onUnauthorized === undefined
                    ? response
                    : clientOptions.onUnauthorized(unauthorizedResponse)));
            }
            else {
                handlerReturn = clientOptions.onUnauthorized === undefined
                    ? response
                    : clientOptions.onUnauthorized(unauthorizedResponse);
            }
            return handlerReturn === undefined
                ? response
                : handlerReturn;
        });
    }
    httpClient.interceptors.response.use(transformResponse);
    httpClient.interceptors.response.use(r => unauthorizedResponseInterceptor(r));
}
function transformResponse(response) {
    var _a;
    function hasProperty(prop, obj) {
        return typeof obj === 'object' && obj !== null && prop in obj;
    }
    const apiResponse = response;
    if (hasProperty('data', response.data) && !hasProperty('paging', response.data)) {
        Object.assign(apiResponse, response.data);
    }
    if (hasProperty('error', response.data)) {
        apiResponse.error = response.data.error;
        if (hasProperty('errors', response.data)) {
            apiResponse.errors = response.data.errors;
        }
        delete apiResponse.data;
    }
    if (hasProperty('code', apiResponse.error) && typeof (apiResponse.error.code) === 'string') {
        switch (apiResponse.error.code) {
            case 'unauthorized':
            case 'invalidToken':
                apiResponse.status = HttpStatusCode.Unauthorized;
                apiResponse.statusText = apiResponse.error.code;
                break;
            case 'badRequest':
            case 'validationError':
                apiResponse.status = HttpStatusCode.BadRequest;
                apiResponse.statusText = apiResponse.error.code;
                break;
            case 'notFound':
                apiResponse.status = HttpStatusCode.NotFound;
                apiResponse.statusText = apiResponse.error.code;
                break;
            default:
                break;
        }
    }
    apiResponse.successful = (_a = apiResponse.successful) !== null && _a !== void 0 ? _a : (apiResponse.status >= 200 && apiResponse.status < 300);
    if (isValidationErrorResponse(apiResponse)) {
        const validationErrors = new Map();
        for (const [fieldName, { errors }] of Object.entries(apiResponse.error.validationErrors)) {
            validationErrors.set(fieldName, errors);
        }
        apiResponse.error.validationErrors = validationErrors;
    }
    return apiResponse;
}
