export function createSchedulingService(client) {
    return {
        searchSchedules(request) {
            return client.post('/schedules/search', request);
        },
        createSchedule(request) {
            return client.post('/schedules', request);
        },
        getSchedule(id) {
            return client.get(`/schedules/${id}`);
        },
        updateSchedule(request) {
            return client.put('/schedules', request);
        },
        deleteSchedule(id) {
            return client.delete(`/schedules/${id}`);
        }
    };
}
