import { inject, provide, ref } from 'vue';
import { Inject } from 'vue-property-decorator';
export const currentLocationKey = Symbol('CurrentLocation');
export function useCurrentLocation() {
    const location = inject(currentLocationKey, ref(null));
    if (location === undefined || location.value === null) {
        throw new Error('Current Location is not provided');
    }
    return location.value;
}
export function provideCurrentLocation(currentLocation) {
    provide(currentLocationKey, currentLocation);
}
export function InjectCurrentLocation() {
    return Inject(currentLocationKey);
}
export const currentBrandKey = Symbol('CurrentBrand');
export function useCurrentBrand() {
    const brand = inject(currentBrandKey, ref(null));
    if (brand === undefined || brand.value === null) {
        throw new Error('Current Brand is not provided');
    }
    return brand.value;
}
export function provideCurrentBrand(currentBrand) {
    provide(currentBrandKey, currentBrand);
}
export function InjectCurrentBrand() {
    return Inject(currentBrandKey);
}
export function useCurrentAccount() {
    let account = inject(currentLocationKey, ref(null));
    if (account.value === null) {
        account = inject(currentBrandKey, ref(null));
    }
    if (account.value === null) {
        throw new Error('Current Account is not provided');
    }
    return account.value;
}
