export class PrinterManager {
    constructor() {
        Object.defineProperty(this, "printFrame", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "timeoutTimer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
    }
    getPrintFrame() {
        if (this.printFrame == null) {
            this.printFrame = document.createElement('iframe');
            this.printFrame.style.display = 'block';
            this.printFrame.style.position = 'absolute';
            this.printFrame.style.width = '0';
            this.printFrame.style.height = '0';
            this.printFrame.style.top = '-100px';
            this.printFrame.style.left = '-100px';
            this.printFrame.className = 'g1-print-frame';
            document.body.appendChild(this.printFrame);
        }
        return this.printFrame;
    }
    hookEscapeKeyForPrint(ev) {
        if (ev.key === 'Escape') {
            ev.preventDefault();
        }
    }
    print(url, options) {
        window.addEventListener('keydown', this.hookEscapeKeyForPrint);
        const promise = new Promise((resolve, reject) => {
            if ((options === null || options === void 0 ? void 0 : options.timeout) != null) {
                this.timeoutTimer = window.setTimeout(() => {
                    reject();
                }, options.timeout);
            }
            const printFrame = this.getPrintFrame();
            printFrame.setAttribute('src', url);
            printFrame.onload = () => {
                var _a;
                if (printFrame.contentWindow === null) {
                    return;
                }
                printFrame.contentWindow.onafterprint = () => {
                    if (this.timeoutTimer != null) {
                        window.clearTimeout(this.timeoutTimer);
                        this.timeoutTimer = null;
                    }
                    window.removeEventListener('keydown', this.hookEscapeKeyForPrint);
                    resolve();
                };
                const mediaQueryList = printFrame.contentWindow.matchMedia('print');
                mediaQueryList.addListener(mql => {
                    if (!mql.matches) {
                        if (this.timeoutTimer != null) {
                            window.clearTimeout(this.timeoutTimer);
                            this.timeoutTimer = null;
                        }
                        window.removeEventListener('keydown', this.hookEscapeKeyForPrint);
                        resolve();
                    }
                });
                if ((_a = options === null || options === void 0 ? void 0 : options.triggerPrint) !== null && _a !== void 0 ? _a : true) {
                    printFrame.contentWindow.print();
                }
            };
        });
        return promise;
    }
}
export class PrintOptions {
    constructor() {
        Object.defineProperty(this, "triggerPrint", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "timeout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 300000
        });
    }
}
export default function PrinterPlugin(Vue) {
    Vue.prototype.$printer = new PrinterManager();
}
