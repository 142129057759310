const routes = [
    {
        name: 'loginNew',
        path: '',
        component: () => import('@/pages/Login/LoginPage.vue'),
        meta: {
            anonymous: true
        }
    },
    {
        name: 'login.passwordReset',
        path: 'forgotPassword',
        component: () => import('@/pages/Login/PasswordResetPage.vue'),
        meta: {
            anonymous: true
        }
    },
    {
        name: 'login.newPassword',
        path: 'newPassword',
        component: () => import('@/pages/Login/NewPasswordPage.vue'),
        meta: {
            anonymous: true
        }
    }
];
export default routes;
