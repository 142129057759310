export function createLookupService(client) {
    return {
        getBusinessTypes() {
            return client.get('lookups/businessTypes');
        },
        getClientTypes() {
            return client.get('lookups/clientTypes');
        },
        getCountries() {
            return client.get('lookups/countries');
        },
        getCurrencies() {
            return client.get('lookups/currencies');
        },
        getEventLogEntryTypes() {
            return client.get('lookups/eventLogEntryTypes');
        },
        getEventLogEntryTypesByItem(eventLogItemType) {
            return client.get(`lookups/eventLogItemTypes/${eventLogItemType}/eventLogEntryTypes`);
        },
        getEventLogItemTypes() {
            return client.get('lookups/eventLogItemTypes');
        },
        getEzLinksManagementCompanies() {
            return client.get('lookups/ezlinks/managementCompanies');
        },
        getEzLinksSecurityGroups(managementCompanyId) {
            return client.get(`lookups/ezlinks/${managementCompanyId}/securityGroups`);
        },
        getEzLinksSecurityGroupsByFacility(facilityId) {
            return client.get(`lookups/ezlinks/${facilityId}/securityGroupsByFacility`);
        },
        getPaymentProcessorTypes() {
            return client.get('lookups/paymentProcessorTypes');
        },
        getPermissions() {
            return client.get('lookups/permissions');
        },
        getPermissionGroups() {
            return client.get('lookups/permissionGroups');
        },
        getStates(countryCode) {
            return client.get(`lookups/countries/${countryCode}/states`);
        },
        getTimeZones() {
            return client.get('lookups/timeZones');
        },
        getUserRoles() {
            return client.get('lookups/userRoles');
        },
        getUserRolesByType(userTypeID) {
            return client.get(`lookups/userRoles/${userTypeID}`);
        }
    };
}
