import { AccountType } from '@/g1';
import { Flag, Permission } from '@/types';
import { TeeSheetAccountType } from '@/types/teeSheet';
import i18n from '@/plugins/localization';
import { isNilOrWhitespace } from '@/common';
import { redirectToLegacy } from './utils';
const routes = [
    {
        name: 'location.teeSheet',
        path: 'teesheet',
        beforeEnter: redirectToLegacy('/GolfNow/Default.aspx'),
        meta: {
            isAvailableInLocation: location => location.teeSheetAccounts.length > 0
                && (location.userAccessSetName === 'Tee Sheet Only' || location.isEuropeanCountry)
        }
    },
    {
        name: 'location.teeSheet.teeSheet.legacy',
        path: 'teesheet/teeSheet/legacy',
        beforeEnter: redirectToLegacy('/GolfNow/Default.aspx?page=teesheet'),
        meta: {
            isAvailableInLocation: location => location.teeSheetAccounts.length > 0,
            hasEveryPermission: [Permission.GetOrder],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueTeeSheet
        }
    },
    {
        name: 'location.teeSheet.teeSheet',
        path: 'teesheet/teeSheet',
        component: () => import('@/pages/Location/TeeSheet/TeeSheetPage.vue'),
        meta: {
            isAvailableInLocation: location => location.teeSheetAccounts.length > 0,
            hasEveryPermission: [Permission.GetOrder],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueTeeSheet
        }
    },
    {
        name: 'location.teeSheet.search',
        path: 'teesheet/search',
        beforeEnter: redirectToLegacy('/GolfNow/Default.aspx?page=search'),
        meta: {
            isAvailableInLocation: location => location.teeSheetAccounts.length > 0
                && !location.teeSheetAccounts.some(t => t.type === TeeSheetAccountType.Responsive),
            hasEveryPermission: [Permission.TeeTimeGetAll]
        }
    },
    {
        name: 'location.membershipsLegacy',
        path: '/Memberships',
        beforeEnter() {
            window.location.href = '/App/Memberships/default.aspx';
        },
        meta: {
            hasEveryPermission: [Permission.SearchMemberships]
        }
    },
    {
        name: 'location.customers',
        path: 'customers',
        component: () => import('@/pages/Account/Customers/CustomersPage.vue'),
        meta: {
            hasEveryPermission: [Permission.CustomerManage],
            legacyRoute: { name: 'location.customers.legacy' }
        },
        props: {
            legacyRoute: { name: 'location.customers.legacy' },
            updateRoute: { name: 'location.customers.update' },
            updateDetailsRoute: { name: 'location.customers.update.details' }
        }
    },
    {
        name: 'location.customers.legacy',
        path: 'customers/legacy',
        beforeEnter: redirectToLegacy('/SpaAdmin/Customers/SearchCustomers.aspx'),
        meta: {
            hasEveryPermission: [Permission.CustomerManage]
        }
    },
    {
        name: 'location.customers.create',
        path: 'customers/create',
        beforeEnter: redirectToLegacy('/SpaAdmin/Customers/NewCustomer.aspx'),
        meta: {
            hasEveryPermission: [Permission.CustomerManage]
        }
    },
    {
        name: 'location.customers.segmentationFilter',
        path: 'customers/segmentationFilter',
        beforeEnter: redirectToLegacy('/SpaAdmin/Customers/Segmentation.aspx'),
        meta: {
            isAvailableInLocation: location => location.settings.features.enableCustomerSegmentation,
            hasEveryPermission: [Permission.SegmentationManage]
        }
    },
    {
        name: 'location.customers.duplicates',
        path: 'customers/duplicates',
        component: () => import('@/pages/Location/Customers/findDuplicates.vue'),
        meta: {
            hasEveryPermission: [Permission.ManageCustomerDuplicates]
        }
    },
    {
        name: 'location.customers.duplicates.merge',
        path: 'customers/duplicates/mergeDuplicates/:uid',
        component: () => import('@/pages/Location/Customers/mergeDuplicates.vue'),
        meta: {
            hasEveryPermission: [Permission.ManageCustomerDuplicates]
        }
    },
    {
        name: 'location.customers.fields',
        path: 'customers/fields',
        beforeEnter: redirectToLegacy('/SpaAdmin/Customers/Crm/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.CustomerFieldManage],
            isAvailableInLocation: location => location.settings.features.enableMerchantCustomerFieldModification
                && (location.brand === null || location.brand.settings.shareCustomFields)
        }
    },
    {
        name: 'location.customers.documents',
        path: 'customers/documents',
        beforeEnter: redirectToLegacy('/SpaAdmin/Customers/Documents/SearchDocuments.aspx'),
        meta: {
            hasEveryPermission: [Permission.CustomerDocumentGet],
            isAvailableInLocation: location => location.settings.features.useDocumentManagement
        }
    },
    {
        name: 'location.customers.events',
        path: 'customers/events',
        beforeEnter: redirectToLegacy('/SpaAdmin/SpaSettings/Events/Default.aspx'),
        meta: {
            isAvailableInLocation: location => location.settings.features.enableCreditBooks,
            hasEveryPermission: [Permission.LocationEventGet]
        }
    },
    {
        name: 'location.customers.update',
        path: 'customers/:id',
        beforeEnter: redirectToLegacy(r => `/SpaAdmin/Customers/EditCustomer.aspx?CustomerID=${r.params.id}`)
    },
    {
        name: 'location.customers.update.details',
        path: 'customers/:id/details',
        beforeEnter: redirectToLegacy(r => `/SpaAdmin/Customers/EditCustomer.aspx?CustomerID=${r.params.id}&Tab=Details.aspx`)
    },
    {
        name: 'location.members',
        path: 'members'
    },
    {
        name: 'location.members.membershipsLegacy.navigate',
        path: '/Memberships',
        beforeEnter: redirectToLegacy('/Memberships/Default.aspx'),
        meta: {
            isAvailableInLocation: location => location.isEuropeanCountry,
            hasEveryPermission: [Permission.SearchMemberships]
        }
    },
    {
        name: 'location.golfPlus.legacy.navigate',
        path: '/GolfPlus',
        beforeEnter: redirectToLegacy('/GolfPlus/Default.aspx'),
        meta: {
            isAvailableWhenFlagIsEnabled: Flag.Configure.GolfPlus
        }
    },
    {
        name: 'location.members.memberships',
        path: 'members/memberships',
        component: () => import('@/pages/Account/Members/Memberships/MembershipsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.AccessMemberships]
        }
    },
    {
        name: 'location.members.memberships.create',
        path: 'members/memberships/create',
        component: () => import('@/pages/Account/Members/Memberships/MembershipCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.AccessMembershipDetail]
        }
    },
    {
        name: 'location.members.memberships.settings',
        path: 'members/membershipSettings',
        component: () => import('@/pages/Location/Members/Memberships/MembershipSettingsCreatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.AccessMembershipSettings]
        }
    },
    {
        name: 'location.members.memberships.update',
        path: 'members/memberships/:id',
        component: () => import('@/pages/Account/Members/Memberships/MembershipCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.UpdateMembership]
        }
    },
    {
        name: 'location.members.creditBooks',
        path: 'members/creditBooks',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBooksPage.vue'),
        meta: {
            hasEveryPermission: [Permission.AccessCreditBooks]
        }
    },
    {
        name: 'location.members.creditBooks.create',
        path: 'members/creditBooks/create',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBookCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.CreateCreditBooks]
        }
    },
    {
        name: 'location.members.creditBooks.distribute',
        path: 'members/creditBooks/distribute',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBooksDistributePage.vue'),
        meta: {
            hasEveryPermission: [Permission.DistributeCreditBooks]
        }
    },
    {
        name: 'location.members.creditBooks.update',
        path: 'members/creditBooks/:CBID',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBookCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.UpdateCreditBooks]
        }
    },
    {
        name: 'location.members.creditBooks.history',
        path: 'members/creditBooks/:CBID/history',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBookHistoryPage.vue'),
        meta: {
            hasEveryPermission: [Permission.GetCreditBooks]
        }
    },
    {
        name: 'location.members.creditBooks.expiration',
        path: 'members/creditBooks/:CBID/expiration',
        component: () => import('@/pages/Account/Members/CreditBooks/CreditBookExpirationPage.vue'),
        meta: {
            hasEveryPermission: [Permission.ExpireCreditBooks]
        }
    },
    {
        name: 'location.orders',
        path: 'orders',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/Default.aspx'),
        meta: {
            isAvailableInLocation: location => location.userAccessSetName !== 'Tee Sheet Only'
                && location.userAccessSetName !== 'UK - Teesheet Only'
        }
    },
    {
        name: 'location.orders.createLegacy',
        path: 'orders/legacy',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/NewOrder.aspx'),
        meta: {
            isAvailableInLocation: location => !location.settings.features.enableNewPOS,
            hasEveryPermission: [Permission.CreateOrder, Permission.GetOrder]
        }
    },
    {
        name: 'location.orders.createLegacy.order',
        path: 'orders/legacy/:id',
        beforeEnter: redirectToLegacy(r => `/SpaAdmin/Orders/PointOfSale.aspx?OrderID=${r.params.id}`),
        meta: {
            hasEveryPermission: [Permission.CreateOrder, Permission.GetOrder]
        }
    },
    {
        name: 'location.orders.manage',
        path: 'orders/manage',
        component: () => import('@/pages/Location/Orders/OrdersPage.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchOrders],
            legacyRoute: (_, account) => {
                if (account === undefined) {
                    return undefined;
                }
                if (account.type === AccountType.Location && !account.isEuropeanCountry) {
                    return undefined;
                }
                return { name: 'location.orders.manage.legacy' };
            }
        }
    },
    {
        name: 'location.orders.manage.legacy',
        path: 'orders/manage/legacy',
        beforeEnter: redirectToLegacy(r => {
            const params = new URLSearchParams();
            if (!Array.isArray(r.query.cashRegisterID) && !isNilOrWhitespace(r.query.cashRegisterID)) {
                params.append('cashregisterid', r.query.cashRegisterID);
            }
            return `/SpaAdmin/Orders/Orders/SearchOrders.aspx${params}`;
        })
    },
    {
        name: 'location.orders.manage.managerRefund',
        path: 'orders/manage/managerRefund',
        component: () => import('@/pages/Location/Orders/ManagerRefundPage/ManagerRefundPage.vue'),
        meta: {
            hasEveryPermission: [Permission.CreateOrder, Permission.RefundOrder, Permission.ManagerRefund],
            canPermissionsBeGranted: true,
            mustProvideCredentials: true,
            requireCashRegister: true,
            grantPermissionsDialogAttrs: {
                title: i18n.t('managerRefund.grantPermissions.title'),
                message: i18n.t('managerRefund.grantPermissions.message'),
                requestedPermissions: [Permission.CreateOrder, Permission.RefundOrder, Permission.ManagerRefund],
                requestedResource: 'location.orders.managerRefund',
                requestedResourceType: 'location'
            }
        }
    },
    {
        name: 'location.orders.pointOfSale',
        path: 'orders/pointOfSale',
        component: () => import('@/pages/Location/Orders/pointOfSale.vue'),
        meta: {
            isAvailableInLocation: location => location.settings.features.enableNewPOS,
            hasEveryPermission: [Permission.CreateOrder, Permission.GetOrder],
            isNavigationDrawerCollapsed: true,
            shiftLegacyRoute: true,
            requireCashRegister: true
        }
    },
    {
        name: 'location.orders.pointOfSale.order',
        path: 'orders/pointOfSale/:orderId',
        component: () => import('@/pages/Location/Orders/pointOfSale.vue'),
        meta: {
            isAvailableInLocation: location => location.settings.features.enableNewPOS,
            hasEveryPermission: [Permission.GetOrder],
            isNavigationDrawerCollapsed: true,
            shiftLegacyRoute: true,
            requireCashRegister: true
        }
    },
    {
        name: 'location.orders.menus',
        path: 'orders/menus',
        component: () => import('@/pages/Location/Menus/MenusPage.vue'),
        meta: {
            isAvailableInLocation: location => location.settings.features.enableNewPOS,
            hasEveryPermission: [Permission.SearchMenus]
        }
    },
    {
        name: 'location.orders.menus.create',
        path: 'orders/menus/create',
        component: () => import('@/pages/Location/Menus/MenuCreateUpdatePage.vue'),
        meta: {
            isAvailableInLocation: location => location.settings.features.enableNewPOS,
            hasEveryPermission: [Permission.GetMenu, Permission.CreateMenu],
            isNavigationDrawerCollapsed: true
        }
    },
    {
        path: 'menus/create',
        redirect: { name: 'location.orders.menus.create' }
    },
    {
        name: 'location.orders.menus.update',
        path: 'orders/menus/:menuId',
        component: () => import('@/pages/Location/Menus/MenuCreateUpdatePage.vue'),
        meta: {
            isNavigationDrawerCollapsed: true,
            hasEveryPermission: [Permission.GetMenu, Permission.UpdateMenu]
        }
    },
    {
        path: 'menus/:menuId',
        redirect: { name: 'location.orders.menus.update' }
    },
    {
        name: 'location.orders.manage.detail',
        path: 'orders/manage/:id',
        component: () => import('@/pages/Location/Orders/OrderDetailPage.vue'),
        meta: {
            hasEveryPermission: [Permission.GetOrder],
            legacyRoute: (route, account) => {
                if (account === undefined) {
                    return undefined;
                }
                if (account.type === AccountType.Location && !account.isEuropeanCountry) {
                    return undefined;
                }
                return { name: 'location.orders.manage.detail.legacy', params: { id: route.params.id } };
            }
        }
    },
    {
        name: 'location.orders.manage.detail.legacy',
        path: 'orders/manage/:id/legacy',
        beforeEnter: redirectToLegacy(r => `/SpaAdmin/Orders/ViewOrder.aspx?OrderID=${r.params.id}`)
    },
    {
        name: 'location.orders.teeSheet.search',
        path: 'orders/teesheet/search',
        beforeEnter: redirectToLegacy('/GolfNow/Default.aspx?page=searchfromorder'),
        meta: {
            isAvailableInLocation: location => location.teeSheetAccounts.length > 0
                && !location.teeSheetAccounts.some(t => t.type === TeeSheetAccountType.Responsive),
            hasEveryPermission: [Permission.TeeTimeGetAll]
        }
    },
    {
        name: 'location.orders.giftCertificates',
        path: 'orders/giftCertificates',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/GiftCertificates.aspx'),
        meta: {
            hasEveryPermission: [Permission.GiftCertificateManage]
        }
    },
    {
        name: 'location.orders.series',
        path: 'orders/series',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/Series.aspx'),
        meta: {
            isAvailableInLocation: location => location.settings.features.useSeries,
            hasEveryPermission: [Permission.SeriesManage]
        }
    },
    {
        name: 'location.orders.cashRegister',
        path: 'orders/cashRegister',
        redirect: { name: 'location.orders.cashRegister.endOfDay' },
        component: () => import('@/pages/Location/Orders/CashRegister/CashRegisterPage.vue'),
        meta: {
            hasEveryPermission: [Permission.CashRegisterManage]
        },
        children: [
            {
                name: 'location.orders.cashRegister.endOfDay',
                path: 'endOfDay',
                component: () => import('@/pages/Location/Orders/CashRegister/CashRegisterEndOfDayPage.vue'),
                meta: {
                    legacyRoute: { name: 'location.orders.cashRegister.legacy.endOfDay' },
                    isAvailableWhenFlagIsEnabled: Flag.Rollout.VueCashRegisterClose,
                    requireCashRegister: true,
                    hasEveryPermission: [Permission.CashRegisterManage]
                }
            },
            {
                name: 'location.orders.cashRegister.payouts',
                path: 'payouts',
                component: () => import('@/pages/Location/Orders/CashRegister/CashRegisterPayoutsPage.vue'),
                meta: {
                    legacyRoute: { name: 'location.orders.cashRegister.legacy.payouts' },
                    isAvailableWhenFlagIsEnabled: Flag.Rollout.VueCashRegisterClose,
                    requireCashRegister: true,
                    hasEveryPermission: [Permission.CashRegisterManage]
                }
            },
            {
                name: 'location.orders.cashRegister.payins',
                path: 'payins',
                component: () => import('@/pages/Location/Orders/CashRegister/CashRegisterPayinsPage.vue'),
                meta: {
                    legacyRoute: { name: 'location.orders.cashRegister.legacy.payins' },
                    isAvailableWhenFlagIsEnabled: Flag.Rollout.VueCashRegisterClose,
                    requireCashRegister: true,
                    hasEveryPermission: [Permission.CashRegisterManage]
                }
            },
            {
                name: 'location.orders.cashRegister.closeouts',
                path: 'closeouts',
                component: () => import('@/pages/Location/Orders/CashRegister/CashRegisterCloseOutPeriodsPage.vue'),
                meta: {
                    legacyRoute: { name: 'location.orders.cashRegister.legacy.closeouts' },
                    isAvailableWhenFlagIsEnabled: Flag.Rollout.VueCashRegisterClose,
                    requireCashRegister: true,
                    hasEveryPermission: [Permission.CashRegisterManage]
                }
            }
        ]
    },
    {
        name: 'location.orders.cashRegister.legacy',
        path: 'orders/cashRegister/legacy',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/CashRegister/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.CashRegisterManage],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueCashRegisterClose
        }
    },
    {
        name: 'location.orders.cashRegister.legacy.endOfDay',
        path: 'orders/cashRegister/legacy/endOfDay',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/CashRegister/EndOfDay.aspx'),
        meta: {
            hasEveryPermission: [Permission.CashRegisterManage],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueCashRegisterClose
        }
    },
    {
        name: 'location.orders.cashRegister.legacy.payouts',
        path: 'orders/cashRegister/legacy/payouts',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/CashRegister/Payouts.aspx'),
        meta: {
            hasEveryPermission: [Permission.CashRegisterManage],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueCashRegisterClose
        }
    },
    {
        name: 'location.orders.cashRegister.legacy.payins',
        path: 'orders/cashRegister/legacy/payins',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/CashRegister/Payins.aspx'),
        meta: {
            hasEveryPermission: [Permission.CashRegisterManage],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueCashRegisterClose
        }
    },
    {
        name: 'location.orders.cashRegister.legacy.closeouts',
        path: 'orders/cashRegister/legacy/closeouts',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/CashRegister/Closeouts.aspx'),
        meta: {
            hasEveryPermission: [Permission.CashRegisterManage],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueCashRegisterClose
        }
    },
    {
        name: 'location.orders.cartAgreements',
        path: 'orders/cartAgreements',
        beforeEnter: redirectToLegacy('/SpaAdmin/Orders/CartAgreement/SearchCartAgreements.aspx'),
        meta: {
            hasEveryPermission: [Permission.CartAgreementGetAll]
        }
    },
    {
        name: 'location.orders.search.online',
        path: 'orders/search/online',
        component: () => import('@/pages/Orders/Search/OnlineOrdersPage.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchOrders]
        }
    },
    {
        name: 'location.marketing',
        path: 'marketing',
        beforeEnter: redirectToLegacy('/SpaAdmin/Marketing/Default.aspx')
    },
    {
        name: 'location.marketing.rainchecks',
        path: 'marketing/rainchecks',
        component: () => import('@/pages/Location/Marketing/Rainchecks/RainchecksPage.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchRaincheck, Permission.UpdateRaincheck]
        }
    },
    {
        name: 'location.marketing.messages',
        path: 'marketing/messages',
        component: () => import('@/pages/Location/Marketing/Messages/searchMessages.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchCustomerMessage, Permission.UpdateCustomerMessage]
        }
    },
    {
        name: 'location.marketing.messages.detail',
        path: 'marketing/messages/:id',
        component: () => import('@/pages/Location/Marketing/Messages/viewMessages.vue'),
        meta: {
            hasEveryPermission: [Permission.GetCustomerMessage, Permission.UpdateCustomerMessage]
        }
    },
    {
        name: 'location.marketing.driveSales',
        path: 'marketing/driveSales',
        beforeEnter: redirectToLegacy('/SpaAdmin/Marketing/IntegratedMarketing/IntegratedMarketing.aspx'),
        meta: {
            hasEveryPermission: [Permission.IntegratedMarketingManage],
            isAvailableInLocation: location => location.settings.features.useIntegratedMarketing
        }
    },
    {
        name: 'location.marketing.customerEmails',
        path: 'marketing/customerEmails',
        beforeEnter: redirectToLegacy('/SpaAdmin/Marketing/EmailSettings/EmailTemplates.aspx'),
        meta: {
            hasEveryPermission: [Permission.CustomerEmailGet],
            isAvailableInLocation: location => {
                var _a, _b;
                return location.brandID === null
                    || ((_b = (_a = location.brand) === null || _a === void 0 ? void 0 : _a.settings.features) === null || _b === void 0 ? void 0 : _b.overrideEmailTemplates) === false;
            }
        }
    },
    {
        name: 'location.marketing.specials',
        path: 'marketing/specials',
        beforeEnter: redirectToLegacy('/SpaAdmin/Marketing/Specials/SearchSpecials.aspx'),
        meta: {
            hasEveryPermission: [Permission.SpecialManage],
            isAvailableInLocation: location => location.settings.features.useSpecials
        }
    },
    {
        name: 'location.marketing.loyaltyProgram',
        path: 'marketing/loyaltyProgram',
        component: () => import('@/pages/Account/Settings/LoyaltyPrograms/LoyaltyProgramDetailPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LoyaltyProgramGet]
        }
    },
    {
        name: 'location.marketing.giftCertificateTemplates',
        path: 'marketing/giftCertificateTemplates',
        beforeEnter: redirectToLegacy('/SpaAdmin/Marketing/GiftCertificateTemplates/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.GiftCertificateTemplateManage]
        }
    },
    {
        name: 'location.marketing.customizeColors',
        path: 'marketing/customizeColors',
        beforeEnter: redirectToLegacy('/SpaAdmin/Marketing/Resources/CustomizeColors.aspx'),
        meta: {
            hasEveryPermission: [Permission.CustomizeColors]
        }
    },
    {
        name: 'location.schedules',
        path: 'schedules',
        beforeEnter: redirectToLegacy('/SpaAdmin/Schedules/Default.aspx')
    },
    {
        name: 'location.schedules.employeesSchedules',
        path: 'schedules/employees',
        beforeEnter: redirectToLegacy('/SpaAdmin/Schedules/Employees/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.EmployeeScheduleGet]
        }
    },
    {
        name: 'location.schedules.schedule',
        path: 'schedules/employees/listWeek',
        beforeEnter: redirectToLegacy('/SpaAdmin/Schedules/Employees/ListWeekSchedules.aspx?User=Employee'),
        meta: {
            hasEveryPermission: [Permission.EmployeeScheduleGet, Permission.EmployeeScheduleUpdate]
        }
    },
    {
        name: 'location.schedules.clockMe',
        path: 'schedules/employees/clockMe',
        beforeEnter: redirectToLegacy('/SpaAdmin/Schedules/TimeClock/ClockSpaAdmin.aspx'),
        meta: {
            hasEveryPermission: [Permission.EmployeeScheduleGet, Permission.EmployeeScheduleUpdate]
        }
    },
    {
        name: 'location.schedules.timeClock',
        path: 'schedules/employees/timeClock',
        beforeEnter: redirectToLegacy('/SpaAdmin/Schedules/TimeClock/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.EmployeeScheduleGet],
            isAvailableInLocation: location => location.settings.features.useClocksPayrollAndCompensation
        }
    },
    {
        name: 'location.products',
        path: 'products',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.ProductGet]
        }
    },
    {
        name: 'location.products.inventorySubmitted',
        path: 'products',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/ManageProducts.aspx?InventorySubmitted=1'),
        meta: {
            hasEveryPermission: [Permission.ManageInventory]
        }
    },
    {
        name: 'location.products.create',
        path: 'products/create',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/EditProduct.aspx?Operation=Add'),
        meta: {
            hasEveryPermission: [Permission.ProductGet, Permission.ProductCreate]
        }
    },
    {
        name: 'location.products.purchaseOrders',
        path: 'products/purchaseOrders',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/Reorder/ManagePurchaseOrders.aspx'),
        meta: {
            hasEveryPermission: [Permission.InventoryOrderGet]
        }
    },
    {
        name: 'location.products.inventory.legacy',
        path: 'products/uploadInventory',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/Inventory/UploadInventory.aspx'),
        meta: {
            hasEveryPermission: [Permission.ManageInventory],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueInventory
        }
    },
    {
        name: 'location.products.inventory',
        path: 'products/inventory',
        component: () => import('@/pages/Location/Products/Inventory/InventoryPage.vue'),
        meta: {
            hasEveryPermission: [Permission.ManageInventory],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueInventory
        }
    },
    {
        name: 'location.products.inventory.search.legacy',
        path: 'products/inventory/search/legacy',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/Inventory/TakeInventory.aspx'),
        meta: {
            hasEveryPermission: [Permission.ManageInventory],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueInventory
        }
    },
    {
        name: 'location.products.inventory.search',
        path: 'products/inventory/search',
        component: () => import('@/pages/Location/Products/Inventory/InventorySearchPage.vue'),
        meta: {
            hasEveryPermission: [Permission.ManageInventory],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueInventory
        }
    },
    {
        name: 'location.products.inventory.upload',
        path: 'products/inventory/upload',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/Inventory/ListInventoryUploads.aspx'),
        meta: {
            hasEveryPermission: [Permission.ManageInventory],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueInventory
        }
    },
    {
        name: 'location.products.reorder',
        path: 'products/reorder',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/Reorder/OrderProducts.aspx'),
        meta: {
            hasEveryPermission: [Permission.ProductVariantUpload]
        }
    },
    {
        name: 'location.products.vendorsProductBrands',
        path: 'products/vendorsProductBrands',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/Vendors/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.VendorGet, Permission.SearchProductBrand]
        }
    },
    {
        name: 'location.products.pos',
        path: 'products/pos',
        beforeEnter: redirectToLegacy('/SpaAdmin/Products/POS/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.LegacyMenuManage],
            isAvailableInLocation: location => location.settings.features.enableRealtimePointOfSaleSync
        }
    },
    {
        name: 'location.reports.legacy',
        path: 'reports/legacy',
        beforeEnter: redirectToLegacy('/SpaAdmin/Reports/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.LocationReportGet],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueReports
        }
    },
    {
        name: 'location.reports',
        path: 'reports',
        component: () => import('@/pages/Location/Reports/ReportsList/ReportsList.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationReportGet],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueReports
        }
    },
    {
        name: 'location.reports.quickbooks.salesExportRpt',
        path: 'reports/quickbooks/salesexportrpt',
        component: () => import('@/pages/Location/Reports/Quickbooks/SalesExportRpt.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationReportGet]
        }
    },
    {
        name: 'location.reports.quickbooks.cashReceiptsExportRpt',
        path: 'reports/quickbooks/cashreceiptsexportrpt',
        component: () => import('@/pages/Location/Reports/Quickbooks/CashReceiptsExportRpt.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationReportGet]
        }
    },
    {
        name: 'location.reports.quickbooks.onlineDirectExport',
        path: 'reports/quickbooks/onlineDirectExport',
        component: () => import('@/pages/Location/Reports/Quickbooks/OnlineDirectExport.vue'),
        meta: {}
    },
    {
        name: 'location.reports.quickbooks.exportPreview',
        path: 'reports/quickbooks/exportPreview',
        component: () => import('@/pages/Location/Reports/Quickbooks/QuickbooksExportPreview.vue'),
        meta: {}
    },
    {
        name: 'location.reports.quickbooks.historyDetail',
        path: 'reports/quickbooks/historyDetail',
        component: () => import('@/pages/Location/Reports/Quickbooks/QuickbooksHistoryDetail.vue'),
        meta: {}
    },
    {
        name: 'location.reports.schedule',
        path: 'reports/schedule',
        component: () => import('@/pages/Location/Reports/Schedule/ReportSchedule.vue'),
        meta: {
            hasEveryPermission: [
                Permission.DeleteSubscription,
                Permission.GetSchedules,
                Permission.GetSubscriptionProperties,
                Permission.GetSubscriptions
            ]
        }
    },
    {
        name: 'location.reports.viewreport',
        path: 'reports/viewreport',
        component: () => import('@/pages/Location/Reports/ViewReport/ViewReport.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationReportGet],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueReports
        }
    },
    {
        name: 'location.reports.scheduler',
        path: 'reports/scheduler',
        component: () => import('@/pages/Location/Reports/Scheduler/ReportScheduler.vue'),
        meta: {
            hasEveryPermission: [Permission.CreateSubscription, Permission.GetRenderingExtensions],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueReports
        }
    },
    {
        name: 'location.gnc',
        path: 'GNC',
        beforeEnter: redirectToLegacy('RedirectToExternalApplication.ashx?app=GNC')
    },
    {
        name: 'location.settings',
        path: 'settings',
        beforeEnter: redirectToLegacy('/SpaAdmin/SpaSettings/Settings/Default.aspx')
    },
    {
        name: 'location.settings.systemSettings',
        path: 'settings/systemSettings',
        beforeEnter: redirectToLegacy('/SpaAdmin/SpaSettings/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.LocationSettingsManage]
        }
    },
    {
        name: 'location.settings.tags.tagGroups.legacy',
        path: 'settings/tags/tagGroups',
        beforeEnter: redirectToLegacy('/SpaAdmin/SpaSettings/Tags/TagGroups/SearchTagGroups.aspx'),
        meta: {
            hasEveryPermission: [Permission.LocationSettingsManage]
        }
    },
    {
        name: 'location.settings.creditAccount',
        path: 'settings/creditAccount',
        component: () => import('@/pages/Location/Settings/CreditAccount/manageCreditAccount.vue'),
        meta: {
            hasEveryPermission: [Permission.GetBillingTypes]
        }
    },
    {
        name: 'location.settings.emailSettings',
        path: 'settings/emailSettings',
        component: () => import('@/pages/Location/Settings/EmailSettings/manageEmailSettings.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationSettingsManage]
        }
    },
    {
        name: 'location.settings.smartPlay',
        path: 'settings/smartPlay',
        component: () => import('@/pages/Location/Settings/SmartPlay/SmartPlaySettingsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.ManageSmartplaySettings],
            isAvailableInLocation: location => location.smartCourseEnabled
        }
    },
    {
        name: 'location.settings.accountIntegrations',
        path: 'settings/accountIntegrations',
        component: () => import('@/pages/Location/Settings/AccountIntegrations/AccountIntegrationsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationSettingsManage],
            isAvailableInLocation: location => location.settings.features.enableOnlineAccounting
        }
    },
    {
        name: 'location.settings.deviceManagement',
        path: 'settings/deviceManagement',
        component: () => import('@/pages/Location/Settings/DeviceManagement/DeviceManagementPage.vue'),
        meta: {
            hasSomePermission: [Permission.UpdateDevice, Permission.UpdateDisplay]
        }
    },
    {
        name: 'location.settings.quickbooks.create',
        path: 'settings/quickbooks/settings/create',
        component: () => import('@/pages/Location/Settings/Quickbooks/QuickbooksSettingsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationSettingsManage],
            isAvailableInLocation: location => location.settings.features.enableOnlineAccounting
        }
    },
    {
        name: 'location.settings.quickbooks.update',
        path: 'settings/quickbooks/settings/:id',
        component: () => import('@/pages/Location/Settings/Quickbooks/QuickbooksSettingsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationSettingsManage],
            isAvailableInLocation: location => location.settings.features.enableOnlineAccounting
        }
    },
    {
        name: 'location.settings.paymentSettings',
        path: 'settings/payments',
        component: () => import('@/pages/Location/Settings/Payments/PaymentsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationSettingsManage],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VuePaymentSettings
        }
    },
    {
        name: 'location.settings.taxes',
        path: 'settings/taxes',
        component: () => import('@/pages/Location/Settings/Taxes/TaxesPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationSettingsManage],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueTaxes
        }
    }
];
export default routes;
