import { computed, unref, useAttrs } from 'vue';
import { generateUniqueString } from '../utils';
export function useG1Input(props, type) {
    const attrs = useAttrs();
    const hasSeparateLabel = computed(() => props.label !== undefined && props.omitDefaults !== true);
    const computedLabel = computed(() => {
        var _a, _b;
        if (hasSeparateLabel.value) {
            return (_a = props.label) === null || _a === void 0 ? void 0 : _a.toString();
        }
        return props.required && props.label !== undefined
            ? `${props.label}*`
            : (_b = props.label) === null || _b === void 0 ? void 0 : _b.toString();
    });
    return {
        vInputAttrs: computed(() => (Object.assign({ outlined: !props.omitDefaults, dense: !props.omitDefaults, 'persistent-placeholder': !props.omitDefaults, 'hide-details': !props.omitDefaults ? 'auto' : undefined, label: hasSeparateLabel.value ? undefined : computedLabel.value, 'menu-props': { 'offset-y': true } }, attrs))),
        inputId: computed(() => (typeof attrs.id === 'string' ? attrs.id : `input-${generateUniqueString(4)}`)),
        hasSeparateLabel,
        computedLabel,
        computedErrorMessages: computed(() => (Array.isArray(props.errorMessages)
            ? props.errorMessages
            : props.errors.map(e => unref(e.$message)))),
        classes: computed(() => {
            const className = `g1-${type}`;
            return {
                [className]: true,
                [`${className}--separate-label`]: hasSeparateLabel.value
            };
        })
    };
}
