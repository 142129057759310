import { inject, getCurrentInstance } from 'vue';
import { appG1DialogService, getG1DialogsServiceInjectionKey } from '../features';
export function useDialogs() {
    const isCalledFromVueComponent = getCurrentInstance() !== null;
    const g1DialogsService = isCalledFromVueComponent
        ? inject(getG1DialogsServiceInjectionKey(), appG1DialogService)
        : appG1DialogService;
    if (g1DialogsService === undefined) {
        throw new Error('g1DialogService was not provided');
    }
    return g1DialogsService;
}
