import { inject } from 'vue';
import { flagsPluginKey } from '../plugins/flags';
export function useFlags() {
    const plugin = inject(flagsPluginKey);
    if (plugin === undefined) {
        throw new Error('Flags plugin not found');
    }
    return {
        getFlag: plugin.getFlag,
        isFlagEnabled: (flagKey) => plugin.getFlag(flagKey, false)
    };
}
