export function createAuthService({ client, g1ApiClient, authClient }) {
    return {
        login() {
            return g1ApiClient.post('session/login');
        },
        refreshAccessToken() {
            return g1ApiClient.post('session/refreshAccessToken');
        },
        getCurrentUser() {
            return client.get('security/currentUser');
        },
        grantPermissions(request) {
            return g1ApiClient.post('session/grantPermissions', request, {
                onUnauthorized: r => r
            });
        },
        authenticate(request) {
            return authClient.post('auth/authenticate', request);
        },
        requestPasswordReset(request) {
            return authClient.post('auth/requestPasswordReset', request);
        },
        resetPassword(request) {
            return authClient.post('auth/resetPassword', request);
        },
        updatePassword(request) {
            return authClient.post('auth/updatePassword', request);
        },
        verifyPasswordResetToken(request) {
            return authClient.post('auth/verifyPasswordResetToken', request);
        }
    };
}
