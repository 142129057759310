export function createLocationService(client) {
    return {
        getFeatures(locationId) {
            return client.get(`locations/${locationId}/features`);
        },
        setFeatures(locationId, features) {
            return client.put(`locations/${locationId}/features`, features);
        },
        searchLocations(request) {
            return client.post('locations/search', request);
        },
        searchLocationsLookup(request) {
            return client.post('locations/lookup', request);
        },
        getLocationByAccountName(accountName) {
            return client.get(`locations/${accountName}`);
        },
        getLocationSettings(accountName, ...expand) {
            return client.get(`locations/${accountName}/settings`, {
                params: {
                    expand: expand.join(',')
                }
            });
        },
        getCashRegisterSettings(accountName, cashRegisterID, ...expand) {
            return client.get(`locations/${accountName}/cashRegisters/${cashRegisterID}/settings`, {
                params: {
                    expand: expand.join(',')
                }
            });
        },
        deleteLocation(locationID) {
            return client.delete(`locations/${locationID}`);
        },
        saveSettings(accountName, settings) {
            return client.post(`locations/${accountName}/Settings`, settings);
        },
        getBrandSettings(accountName, ...expand) {
            return client.get(`locations/${accountName}/brandSettings`, {
                params: {
                    expand: expand.join(',')
                }
            });
        }
    };
}
