import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VueMask, { VueMaskDirective } from 'v-mask';
import { PiniaVuePlugin } from 'pinia';
import AsyncComputed from 'vue-async-computed';
import { Drag, Drop } from 'vue-drag-drop';
import { Vue2Storage } from 'vue2-storage';
import VueRouter from 'vue-router';
import VuetifyMask from 'vuetify-mask';
import cronVuetify from '@vue-js-cron/vuetify';
import JoditVue from 'jodit-vue';
import store, { pinia } from '@/store';
import qa from '@/directives/qa';
import positiveInts from '@/directives/positiveInts';
import sortableTable from '@/directives/sortableTable';
import visible from '@/g1/directives/visible';
import 'jodit/build/jodit.min.css';
import '@/styles/global.scss';
import '@/common/styles';
import '@mdi/font/css/materialdesignicons.min.css';
import router, { initRouter } from '@/router';
import App from '@/App.vue';
import printer from '@/plugins/printer';
import i18n from '@/plugins/localization';
import vuetify from '@/plugins/vuetify';
import FlagsPlugin, { flagsPluginKey } from '@/g1/plugins/flags';
import plugins from './plugins';
Vue.use(plugins);
Vue.use(AsyncComputed);
Vue.component('Drag', Drag);
Vue.component('Drop', Drop);
Vue.use(Vue2Storage, {
    prefix: 'g1_',
    driver: 'session'
});
Vue.use(printer);
Vue.use(VueTheMask);
Vue.use(VueMask);
Vue.use(VueRouter);
Vue.use(VuetifyMask);
Vue.use(cronVuetify);
Vue.use(FlagsPlugin);
Vue.use(JoditVue);
Vue.use(PiniaVuePlugin);
Vue.directive('positiveInts', positiveInts);
Vue.directive('sortableTable', sortableTable);
Vue.directive('visible', visible);
Vue.directive('qa', qa);
Vue.directive('mask', VueMaskDirective);
Vue.config.productionTip = false;
initRouter(pinia);
new Vue({
    vuetify,
    i18n,
    store,
    router,
    pinia,
    provide: {
        [flagsPluginKey]: Vue.prototype.$flags
    },
    render: h => h(App)
}).$mount('#app');
export const eventBus = new Vue();
