const directive = {
    bind(el, binding) {
        if (!binding.value) {
            el.style.visibility = 'hidden';
        }
    },
    update(el, binding) {
        el.style.visibility = binding.value ? 'visible' : 'hidden';
    }
};
export default directive;
