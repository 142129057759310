import { isNil } from 'lodash';
import { useI18n } from '../composables';
export function isNilOrWhitespace(value) {
    return typeof value === 'undefined'
        || value === null
        || value.replace(/\s/g, '').length < 1;
}
export function generateUniqueString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export function nullIfNilOrWhitespace(value) {
    return isNilOrWhitespace(value) ? null : value !== null && value !== void 0 ? value : null;
}
export function cleanWhitespace(value) {
    return value.trim().replaceAll(/\s+/g, ' ');
}
export function toPixels(value) {
    return typeof (value) === 'number' ? `${value.toString}px` : value;
}
export function removeHtmlTags(inputString) {
    if (inputString === null || inputString === undefined) {
        return inputString;
    }
    return inputString.replace(/<[^>]*>/g, '');
}
export function getInitials(fullName) {
    if (isNil(fullName)) {
        return '';
    }
    const regex = /[A-Za-z'-]+/g;
    const matches = fullName.match(regex);
    if (!matches) {
        return '';
    }
    let initials = '';
    for (const match of matches) {
        if (match.length > 0) {
            initials += match[0].toUpperCase();
        }
    }
    return initials;
}
export function getSpaced(...values) {
    const valid = values.filter(v => v !== undefined && v !== null);
    return valid.length === 0 ? null : values.join(' ');
}
export function getAvatarColor(initials, isDarkTheme) {
    if (isNilOrWhitespace(initials)) {
        return 'primary';
    }
    const colors = isDarkTheme
        ? ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green',
            'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey']
        : ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan',
            'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange'];
    const charCodeSum = initials.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
    const index = charCodeSum % colors.length;
    return colors[index];
}
export function formatDurationToHoursMinutes(duration) {
    const { tc } = useI18n();
    if (duration === null || duration === undefined) {
        return '';
    }
    const hours = Math.trunc(duration / 60);
    const minutes = duration % 60;
    if (hours > 0) {
        return `${hours} ${tc('common.duration.hours', hours)} ${minutes} ${tc('common.duration.minutes', minutes)}`;
    }
    return `${minutes} ${tc('common.duration.minutes', minutes)}`;
}
export function waitForElementToAppear(elementId, callback, maxAttempts = 10, interval = 1000) {
    let attempts = 0;
    function checkElement() {
        const element = document.getElementById(elementId);
        attempts++;
        if (element !== null) {
            callback();
        }
        else if (attempts < maxAttempts) {
            setTimeout(checkElement, interval);
        }
        else {
        }
    }
    checkElement();
}
export function arraysHaveSameValues(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const sortedArr1 = arr1.slice().sort((a, b) => a - b);
    const sortedArr2 = arr2.slice().sort((a, b) => a - b);
    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }
    return true;
}
export function delay(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}
