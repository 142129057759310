export var HttpStatusCode;
(function (HttpStatusCode) {
    HttpStatusCode[HttpStatusCode["Ok"] = 200] = "Ok";
    HttpStatusCode[HttpStatusCode["NoContent"] = 204] = "NoContent";
    HttpStatusCode[HttpStatusCode["BadRequest"] = 400] = "BadRequest";
    HttpStatusCode[HttpStatusCode["Unauthorized"] = 401] = "Unauthorized";
    HttpStatusCode[HttpStatusCode["Forbidden"] = 403] = "Forbidden";
    HttpStatusCode[HttpStatusCode["NotFound"] = 404] = "NotFound";
    HttpStatusCode[HttpStatusCode["InternalServerError"] = 500] = "InternalServerError";
    HttpStatusCode[HttpStatusCode["NotImplemented"] = 501] = "NotImplemented";
    HttpStatusCode[HttpStatusCode["ServiceUnavailable"] = 503] = "ServiceUnavailable";
})(HttpStatusCode || (HttpStatusCode = {}));
export class SearchRequest {
    constructor() {
        Object.defineProperty(this, "filter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sort", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pageSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pageIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
export function isOkDataResponse(response) {
    return response.status === HttpStatusCode.Ok && response.data !== undefined;
}
export function isOkPagedDataResponse(response) {
    return isOkDataResponse(response)
        && response.data !== null
        && typeof (response.data) === 'object'
        && 'data' in response.data && Array.isArray(response.data.data);
}
export function isValidationErrorResponse(response) {
    return response.status === HttpStatusCode.BadRequest
        && response.error !== undefined
        && response.error !== null
        && typeof (response.error) === 'object'
        && 'validationErrors' in response.error
        && response.error.validationErrors !== null
        && response.error.validationErrors !== undefined
        && typeof (response.error.validationErrors) === 'object';
}
export function hasErrorMessage(response) {
    return !response.successful
        && response.error !== undefined
        && response.error !== null
        && typeof response.error === 'object'
        && 'message' in response.error
        && typeof response.error.message === 'string';
}
