export var BillableItemType;
(function (BillableItemType) {
    BillableItemType["Service"] = "service";
    BillableItemType["ProductVariant"] = "productVariant";
    BillableItemType["GiftCertificate"] = "giftCertificate";
    BillableItemType["Package"] = "package";
    BillableItemType["ServiceAddOn"] = "serviceAddOn";
    BillableItemType["Series"] = "series";
    BillableItemType["MembershipPlan"] = "membershipPlan";
    BillableItemType["PackageAddOn"] = "packageAddOn";
    BillableItemType["ExternalOrderItem"] = "externalOrderItem";
    BillableItemType["CreditBooks"] = "creditBooks";
})(BillableItemType || (BillableItemType = {}));
export var TaxStatus;
(function (TaxStatus) {
    TaxStatus["Active"] = "active";
    TaxStatus["Inactive"] = "inactive";
})(TaxStatus || (TaxStatus = {}));
