export function createBrandService(client) {
    return {
        searchBrands(request) {
            return client.post('brands/search', request);
        },
        getBrand(brandID) {
            return client.get(`brands/${brandID}`);
        },
        getBrandByAccountName(accountName) {
            return client.get(`brands/${accountName}`);
        },
        getBrandLocations(accountName) {
            return client.get(`brands/${accountName}/locations`);
        },
        getBrandSettings(accountName, brandID, ...expand) {
            return client.get(`brands/${accountName}/${brandID}/settings`, {
                params: {
                    expand: expand.join(',')
                }
            });
        },
        getBrandLocationsWithLoyaltyProgram(accountName) {
            return client.get(`brands/${accountName}/locationsWithLoyaltyProgram`);
        },
        createBrand(request) {
            return client.post('brands', request);
        },
        updateBrand(brandID, request) {
            return client.post(`brands/${brandID}`, request);
        },
        deleteBrand(brandID) {
            return client.delete(`brands/${brandID}`);
        },
        getBrandGroups() {
            return client.get('brandGroups');
        },
        createBrandGroup(request) {
            return client.post('brandGroups', request);
        },
        getBrandDetail(brandID) {
            return client.get(`brands/${brandID}/Detail`);
        },
        getFeatures(brandId) {
            return client.get(`brands/${brandId}/features`);
        },
        setFeatures(brandId, features) {
            return client.put(`brands/${brandId}/features`, features);
        }
    };
}
