export var Permission;
(function (Permission) {
    Permission["GetNotification"] = "GetNotification";
    Permission["SearchNotification"] = "SearchNotification";
    Permission["UpdateNotification"] = "UpdateNotification";
    Permission["CreateNotification"] = "CreateNotification";
    Permission["DeleteNotification"] = "DeleteNotification";
    Permission["GetActiveNotification"] = "GetActiveNotification";
    Permission["DismissNotification"] = "DismissNotification";
    Permission["GetPartner"] = "GetPartner";
    Permission["GetCustomerMessage"] = "GetCustomerMessage";
    Permission["SearchCustomerMessage"] = "SearchCustomerMessage";
    Permission["UpdateCustomerMessage"] = "UpdateCustomerMessage";
    Permission["CreateCustomerMessage"] = "CreateCustomerMessage";
    Permission["GetLocationSettings"] = "GetLocationSettings";
    Permission["GetCashRegisterSettings"] = "GetCashRegisterSettings";
    Permission["GetBrandSettings"] = "GetBrandSettings";
    Permission["GetRoom"] = "GetRoom";
    Permission["GetRooms"] = "GetRooms";
    Permission["CreateRoom"] = "CreateRoom";
    Permission["DeleteRoom"] = "DeleteRoom";
    Permission["UpdateRoom"] = "UpdateRoom";
    Permission["GetSection"] = "GetSection";
    Permission["CreateSection"] = "CreateSection";
    Permission["UpdateSection"] = "UpdateSection";
    Permission["GetSections"] = "GetSections";
    Permission["DeleteSection"] = "DeleteSection";
    Permission["GetTables"] = "GetTables";
    Permission["GetTable"] = "GetTable";
    Permission["UpdateTable"] = "UpdateTable";
    Permission["CreateTable"] = "CreateTable";
    Permission["DeleteTable"] = "DeleteTable";
    Permission["AssignOrderToTable"] = "AssignOrderToTable";
    Permission["RemoveTablesFromOrder"] = "RemoveTablesFromOrder";
    Permission["FederatedCustomerSearch"] = "FederatedCustomerSearch";
    Permission["ManageCustomerDuplicates"] = "ManageCustomerDuplicates";
    Permission["GetCustomerMembershipBenefits"] = "GetCustomerMembershipBenefits";
    Permission["SearchGiftPrograms"] = "SearchGiftProgram";
    Permission["SearchGiftCertificates"] = "SearchGiftCertificate";
    Permission["SearchGiftCertificateTemplates"] = "SearchGiftCertificateTemplates";
    Permission["SearchTag"] = "SearchTag";
    Permission["CreateTag"] = "CreateTag";
    Permission["UpdateTag"] = "UpdateTag";
    Permission["DeleteTag"] = "DeleteTag";
    Permission["SearchTier"] = "SearchTier";
    Permission["SearchTax"] = "SearchTax";
    Permission["SearchPackage"] = "SearchPackage";
    Permission["GetPackageItem"] = "GetPackageItem";
    Permission["SearchService"] = "SearchService";
    Permission["SearchProductVariant"] = "SearchProductVariant";
    Permission["SearchUserLoginAudit"] = "SearchUserLoginAudit";
    Permission["FailureSummaryUserLoginAudit"] = "FailureSummaryLoginAudit";
    Permission["CreditCardTransactionLogSearch"] = "SearchCreditCardTransactionLog";
    Permission["GetRaincheck"] = "GetRaincheck";
    Permission["SearchRaincheck"] = "SearchRaincheck";
    Permission["CreateRaincheck"] = "CreateRaincheck";
    Permission["UpdateRaincheck"] = "UpdateRaincheck";
    Permission["CancelRaincheck"] = "CancelRaincheck";
    Permission["DeleteRaincheck"] = "DeleteRaincheck";
    Permission["GetUser"] = "GetUser";
    Permission["GetEmployee"] = "GetEmployee";
    Permission["SearchEmployee"] = "SearchEmployee";
    Permission["GetGroup"] = "GetPermissionGroup";
    Permission["GetLegacyAccess"] = "GetLegacyAccess";
    Permission["GetLegacyPermission"] = "GetLegacyPermission";
    Permission["SearchEventLog"] = "SearchEventLog";
    Permission["CloseOrderTransaction"] = "CloseOrderTransaction";
    Permission["RefundOrderTransaction"] = "RefundOrderTransaction";
    Permission["VoidOrderTransaction"] = "VoidOrderTransaction";
    Permission["VoidOrder"] = "VoidOrder";
    Permission["DeleteSavedOrder"] = "DeleteSavedOrder";
    Permission["GetTransactionAudit"] = "GetTransactionAudit";
    Permission["SearchTransactionAudit"] = "SearchTransactionAudit";
    Permission["UpdateTransactionAudit"] = "UpdateTransactionAudit";
    Permission["ResetTransactionAudit"] = "ResetTransactionAudit";
    Permission["RetryTransactionAudit"] = "RetryTransactionAudit";
    Permission["GetBackgroundTaskSettings"] = "GetBackgroundTaskSettings";
    Permission["SearchBackgroundTaskSettings"] = "SearchBackgroundTaskSettings";
    Permission["UpdateBackgroundTaskSettings"] = "UpdateBackgroundTaskSettings";
    Permission["DeleteBackgroundTaskSettings"] = "DeleteBackgroundTaskSettings";
    Permission["GetReprocessQueue"] = "GetReprocessQueue";
    Permission["SearchReprocessQueue"] = "SearchReprocessQueue";
    Permission["UpdateReprocessQueue"] = "UpdateReprocessQueue";
    Permission["SearchSpecial"] = "SearchSpecial";
    Permission["CreateOrder"] = "CreateOrder";
    Permission["RefundOrder"] = "RefundOrder";
    Permission["GetOrder"] = "GetOrder";
    Permission["AddItemToOrder"] = "AddItemToOrder";
    Permission["RemoveItemFromOrder"] = "RemoveItemFromOrder";
    Permission["UpdateItemQuantity"] = "UpdateItemQuantity";
    Permission["UpdateItemModifiers"] = "UpdateItemModifiers";
    Permission["UpdateItemAddOn"] = "UpdateItemAddOn";
    Permission["CombineOrder"] = "CombineOrder";
    Permission["UpdateItemNote"] = "UpdateItemNote";
    Permission["AddPaymentToOrder"] = "AddPaymentToOrder";
    Permission["RemovePaymentFromOrder"] = "RemovePaymentFromOrder";
    Permission["SetOrderCustomer"] = "SetOrderCustomer";
    Permission["CloseOrder"] = "CloseOrder";
    Permission["SplitOrder"] = "SplitOrder";
    Permission["ProcessOrder"] = "ProcessOrder";
    Permission["OverrideItemPrice"] = "OverrideItemPrice";
    Permission["RemovePriceOverride"] = "RemovePriceOverride";
    Permission["SaveOpenOrder"] = "SaveOpenOrder";
    Permission["SearchOrders"] = "SearchOrders";
    Permission["SetOrderReadyStatus"] = "SetOrderReadyStatus";
    Permission["GetLatestCloseOutPeriod"] = "GetLatestCloseOutPeriod";
    Permission["AddOrderNote"] = "AddOrderNote";
    Permission["UpdateOrderNote"] = "UpdateOrderNote";
    Permission["DeleteOrderNote"] = "DeleteOrderNote";
    Permission["UpdateOrderCashRegister"] = "UpdateOrderCashRegister";
    Permission["OverrideOrderTax"] = "OverrideOrderTax";
    Permission["OverrideAutoGratuity"] = "OverrideAutoGratuity";
    Permission["UpdateServer"] = "UpdateServer";
    Permission["CreateInvoice"] = "CreateInvoice";
    Permission["PurchaseInvoice"] = "PurchaseInvoice";
    Permission["UpdateNumberOfGuests"] = "UpdateNumberOfGuests";
    Permission["UpdateSentToKitchen"] = "UpdateSentToKitchen";
    Permission["SubstitutePackageItem"] = "SubstitutePackageItem";
    Permission["EditOrderItemGreenFee"] = "EditOrderItemGreenFee";
    Permission["SearchMenus"] = "SearchMenus";
    Permission["CreateMenu"] = "CreateMenu";
    Permission["UpdateMenu"] = "UpdateMenu";
    Permission["GetMenu"] = "GetMenu";
    Permission["DeleteMenu"] = "DeleteMenu";
    Permission["GetColorOptions"] = "GetColorOptions";
    Permission["SearchCatalogs"] = "SearchCatalogs";
    Permission["GetKds"] = "GetKds";
    Permission["AddKds"] = "AddKds";
    Permission["UpdateKds"] = "UpdateKds";
    Permission["DeleteKds"] = "DeleteKds";
    Permission["AddTicketItem"] = "AddTicketItem";
    Permission["GetTicketItem"] = "GetTicketItem";
    Permission["UpdateTicketItemStatus"] = "UpdateTicketItemStatus";
    Permission["UpdateTicketItemPriority"] = "UpdateTicketItemPriority";
    Permission["SearchMemberships"] = "SearchMemberships";
    Permission["DeleteMembership"] = "DeleteMembership";
    Permission["GetMembership"] = "GetMembership";
    Permission["CreateMembership"] = "CreateMembership";
    Permission["UpdateMembership"] = "UpdateMembership";
    Permission["GetMembershipSettings"] = "GetMembershipSettings";
    Permission["CreateMembershipSettings"] = "CreateMembershipSettings";
    Permission["UpdateMembershipSettings"] = "UpdateMembershipSettings";
    Permission["AccessMemberships"] = "AccessMemberships";
    Permission["AccessMembershipDetail"] = "AccessMembershipDetail";
    Permission["AccessMembershipSettings"] = "AccessMembershipSettings";
    Permission["AddLineitemTip"] = "AddLineitemTip";
    Permission["AddSignature"] = "AddSignature";
    Permission["ApplySpecialToOrder"] = "ApplySpecialToOrder";
    Permission["ApplySpecialToItem"] = "ApplySpecialToItem";
    Permission["RemoveSpecialFromItem"] = "RemoveSpecialFromItem";
    Permission["RemoveSpecialFromOrder"] = "RemoveSpecialFromOrder";
    Permission["ApplyRaincheckToOrder"] = "ApplyRaincheckToOrder";
    Permission["RemoveRaincheckFromItem"] = "RemoveRaincheckFromItem";
    Permission["IssueRainchecksForOrder"] = "IssueRainchecksForOrder";
    Permission["SendNotification"] = "SendNotification";
    Permission["SetNotificationStatus"] = "SetNotificationStatus";
    Permission["SearchProductBrand"] = "SearchProductBrand";
    Permission["DistributeCreditBooks"] = "DistributeCreditBooks";
    Permission["CreateCreditBooks"] = "CreateCreditBooks";
    Permission["UpdateCreditBooks"] = "UpdateCreditBooks";
    Permission["SearchCreditBooks"] = "SearchCreditBooks";
    Permission["GetCreditBooks"] = "GetCreditBooks";
    Permission["DeleteCreditBooks"] = "DeleteCreditBooks";
    Permission["ExpireCreditBooks"] = "ExpireCreditBooks";
    Permission["AccessCreditBooks"] = "AccessCreditBooks";
    Permission["CanGrantPermissions"] = "CanGrantPermissions";
    Permission["ManagerRefund"] = "ManagerRefund";
    Permission["CanViewPartnerApiCredentials"] = "CanViewPartnerApiCredentials";
    Permission["CanOverrideProductVariantMinimumSellPrice"] = "CanOverrideProductVariantMinimumSellPrice";
    Permission["BackdateOrders"] = "BackdateOrders";
    Permission["NoSaleOpenDrawer"] = "NoSaleOpenDrawer";
    Permission["AccessSavedOrders"] = "AccessSavedOrders";
    Permission["AccessSlotColoring"] = "AccessSlotColoring";
    Permission["SearchBatchItems"] = "SearchBatchItems";
    Permission["GetBatchItem"] = "GetBatchItem";
    Permission["DeleteBatchItem"] = "DeleteBatchItem";
    Permission["UpdateBatchItem"] = "UpdateBatchItem";
    Permission["RetryBatchItem"] = "RetryBatchItem";
    Permission["SearchBatchItemResults"] = "SearchBatchItemResults";
    Permission["CustomizeColors"] = "CustomizeColors";
    Permission["GetBillingTypes"] = "GetBillingTypes";
    Permission["CreateEditBillingTypes"] = "CreateEditBillingTypes";
    Permission["SearchSchedules"] = "SearchSchedules";
    Permission["CreateSchedule"] = "CreateSchedule";
    Permission["UpdateSchedule"] = "UpdateSchedule";
    Permission["DeleteSchedule"] = "DeleteSchedule";
    Permission["LocationGetAll"] = "SearchLocation";
    Permission["LocationGet"] = "GetLocationByAccount";
    Permission["LocationCreate"] = "LocationCreate";
    Permission["LocationUpdate"] = "LocationUpdate";
    Permission["LocationDelete"] = "DeleteLocation";
    Permission["LocationConfigure"] = "LocationConfigure";
    Permission["BrandGetAll"] = "SearchBrand";
    Permission["BrandCreate"] = "CreateBrand";
    Permission["BrandUpdate"] = "EditBrand";
    Permission["BrandDelete"] = "DeleteBrand";
    Permission["PartnerGetAll"] = "SearchPartner";
    Permission["PartnerCreate"] = "CreatePartner";
    Permission["PartnerUpdate"] = "UpdatePartner";
    Permission["PartnerDelete"] = "DeletePartner";
    Permission["GiftCertificateTemplateGetAll"] = "GiftCertificateTemplateGetAll";
    Permission["GiftCertificateTemplateManage"] = "GiftCertificateTemplateManage";
    Permission["ResourceLocalizationManage"] = "ResourceLocalizationManage";
    Permission["ReportGetAll"] = "ReportGetAll";
    Permission["ReportSchedule"] = "ReportSchedule";
    Permission["ReportManage"] = "ReportManage";
    Permission["GroupGet"] = "SearchPermissionGroups";
    Permission["GroupUpdate"] = "UpdatePermissionGroup";
    Permission["GroupCreate"] = "CreatePermissionGroup";
    Permission["GroupDelete"] = "DeletePermissionGroup";
    Permission["UserGetAll"] = "SearchUser";
    Permission["SecuritySettingsManage"] = "SecuritySettingsManage";
    Permission["LegacyAccessGet"] = "SearchLegacyAccess";
    Permission["LegacyAccessUpdate"] = "LegacyAccessUpdate";
    Permission["LegacyPermissionGet"] = "SearchLegacyPermissions";
    Permission["LegacyPermissionUpdate"] = "UpdateLegacyPermission";
    Permission["BrsDataCenterGet"] = "BrsDataCenterGet";
    Permission["UserAccessSetGet"] = "UserAccessSetGet";
    Permission["SmartPlayManage"] = "SmartPlayManage";
    Permission["ResponsiveTeeSheetAssignmentManage"] = "ResponsiveTeeSheetAssignmentManage";
    Permission["CustomerGet"] = "CustomerGet";
    Permission["CustomerCreate"] = "CustomerCreate";
    Permission["CustomerUpdate"] = "CustomerUpdate";
    Permission["CustomerDelete"] = "CustomerDelete";
    Permission["CustomerManage"] = "CustomerManage";
    Permission["CustomerFieldManage"] = "CustomerFieldManage";
    Permission["CustomerNoteGet"] = "CustomerNoteGet";
    Permission["CustomerNoteCreate"] = "CustomerNoteCreate";
    Permission["CustomerNoteUpdate"] = "CustomerNoteUpdate";
    Permission["CustomerNoteDelete"] = "CustomerNoteDelete";
    Permission["CustomerCreditCardCreate"] = "CustomerCreditCardCreate";
    Permission["CustomerCreditCardSetDefault"] = "CustomerCreditCardSetDefault";
    Permission["CustomerCreditCardDelete"] = "CustomerCreditCardDelete";
    Permission["CustomerPhotoCreate"] = "CustomerPhotoCreate";
    Permission["CustomerPhotoDelete"] = "CustomerPhotoDelete";
    Permission["CustomerDocumentGet"] = "CustomerDocumentGet";
    Permission["LocationEventGet"] = "LocationEventGet";
    Permission["SegmentationManage"] = "SegmentationManage";
    Permission["SeriesGetAll"] = "SeriesGetAll";
    Permission["SeriesManage"] = "SeriesManage";
    Permission["SpecialManage"] = "SpecialManage";
    Permission["GiftCertificateManage"] = "GiftCertificateManage";
    Permission["CashRegisterClose"] = "CashRegisterClose";
    Permission["CashRegisterManage"] = "CashRegisterManage";
    Permission["CashRegisterCloseOutUpdate"] = "CashRegisterCloseOutUpdate";
    Permission["CartAgreementGetAll"] = "CartAgreementGetAll";
    Permission["TeeSheetManage"] = "TeeSheetManage";
    Permission["TeeTimeGetAll"] = "TeeTimeGetAll";
    Permission["BrandLocationGetAll"] = "BrandLocationGetAll";
    Permission["BrandCustomerGetAll"] = "BrandCustomerGetAll";
    Permission["BrandConfigure"] = "BrandConfigure";
    Permission["EmailTemplateGet"] = "EmailTemplateGet";
    Permission["BrandReportGet"] = "BrandReportGet";
    Permission["BrandSettingsManage"] = "BrandSettingsManage";
    Permission["ProductUpload"] = "ProductUpload";
    Permission["ProductGet"] = "ProductGet";
    Permission["ProductCreate"] = "ProductCreate";
    Permission["ProductVariantUpload"] = "ProductVariantUpload";
    Permission["LegacyMenuManage"] = "LegacyMenuManage";
    Permission["VendorGet"] = "VendorGet";
    Permission["VendorGetAll"] = "VendorGetAll";
    Permission["EmployeeScheduleGet"] = "EmployeeScheduleGet";
    Permission["EmployeeScheduleUpdate"] = "EmployeeScheduleUpdate";
    Permission["CustomerEmailGet"] = "CustomerEmailGet";
    Permission["IntegratedMarketingManage"] = "IntegratedMarketingManage";
    Permission["InventoryOrderGet"] = "InventoryOrderGet";
    Permission["LocationReportGet"] = "LocationReportGet";
    Permission["GetRenderingExtensions"] = "GetRenderingExtensions";
    Permission["GetSubscriptionProperties"] = "GetSubscriptionProperties";
    Permission["GetSubscriptions"] = "GetSubscriptions";
    Permission["GetSchedules"] = "GetSchedules";
    Permission["DeleteSubscription"] = "DeleteSubscription";
    Permission["CreateSubscription"] = "CreateSubscription";
    Permission["LocationSettingsManage"] = "LocationSettingsManage";
    Permission["ServiceCategoryCreate"] = "ServiceCategoryCreate";
    Permission["ClassSettingsManage"] = "ClassSettingsManage";
    Permission["DepartmentTemplateManage"] = "DepartmentTemplateManage";
    Permission["ProductBrandGetAll"] = "ProductBrandGetAll";
    Permission["ParentFieldGetAll"] = "ParentFieldGetAll";
    Permission["CustomerTypeGetAll"] = "CustomerTypeGetAll";
    Permission["BusinessTypeGetAll"] = "BusinessTypeGetAll";
    Permission["BusinessConfigurationGetAll"] = "BusinessConfigurationGetAll";
    Permission["ScheduledTaskGet"] = "ScheduledTaskGet";
    Permission["GolfNowDataExtractGetAll"] = "GolfNowDataExtractGetAll";
    Permission["ManageSmartplaySettings"] = "ManageSmartplaySettings";
    Permission["AccountingReadOnly"] = "AccountingReadOnly";
    Permission["DeleteJournalEntries"] = "DeleteJournalEntries";
    Permission["TransferJournalEntries"] = "TransferJournalEntries";
    Permission["LoyaltyProgramGet"] = "LoyaltyProgramGet";
    Permission["LoyaltyProgramCreate"] = "LoyaltyProgramCreate";
    Permission["LoyaltyProgramUpdate"] = "LoyaltyProgramUpdate";
    Permission["LoyaltyProgramDelete"] = "LoyaltyProgramDelete";
    Permission["GetPartnerLocations"] = "GetPartnerLocations";
    Permission["GetPartnerBrands"] = "GetPartnerBrands";
    Permission["LookupOptionGetAll"] = "LookupOptionGetAll";
    Permission["ManageInventory"] = "ManageInventory";
    Permission["GetDevice"] = "GetDevice";
    Permission["CreateDevice"] = "CreateDevice";
    Permission["UpdateDevice"] = "UpdateDevice";
    Permission["DeleteDevice"] = "DeleteDevice";
    Permission["SearchDisplay"] = "DisplaySearch";
    Permission["UpdateDisplay"] = "DisplayUpdate";
    Permission["AchTransactionGetAll"] = "AchTransactionGetAll";
})(Permission || (Permission = {}));
