export function createTaxService(client) {
    return {
        searchTaxes(accountName, request) {
            return client.post(`${accountName}/taxes/search`, request);
        },
        createTaxes(accountName, request) {
            return client.post(`${accountName}/taxes`, request);
        },
        updateTax(accountName, request) {
            return client.put(`${accountName}/taxes`, request);
        },
        getTax(accountName, taxId) {
            return client.get(`${accountName}/taxes/${taxId}`);
        },
        deleteTax(accountName, taxId) {
            return client.delete(`${accountName}/taxes/${taxId}`);
        }
    };
}
