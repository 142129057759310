import { computed, getCurrentInstance, useAttrs } from 'vue';
export const defaults = {
    label: undefined,
    labelLocation: undefined,
    requiredLocation: undefined,
    icon: undefined,
    iconLocation: 'append-inner',
    errors: () => []
};
export function useField(props) {
    var _a;
    const attrs = useAttrs();
    const vue = (_a = getCurrentInstance()) === null || _a === void 0 ? void 0 : _a.proxy;
    if (vue === undefined) {
        throw new Error('useField must be used within a setup function');
    }
    return {
        vFieldAttrs: computed(() => (Object.assign(Object.assign({ outlined: true, dense: true, persistentPlaceholder: true, hideDetails: 'auto', menuProps: { 'offset-y': true }, clearable: props.clearable, disabled: props.disabled, error: props.errors.length > 0 ? 'error' : undefined, color: 'primary-text', loading: props.loading, autofocus: props.autoselect }, createIconAttrs(props)), attrs))),
        vFieldOn: Object.assign(Object.assign(Object.assign({}, createIconListeners(props, vue)), createAutoselectListener(props, vue)), vue.$listeners),
        fieldAttrs: computed(() => ({
            label: props.label,
            labelLocation: props.labelLocation,
            required: props.required,
            requiredLocation: props.requiredLocation,
            errors: props.errors
        }))
    };
}
function createAutoselectListener(props, vue) {
    if (!props.autoselect) {
        return {};
    }
    return {
        focus: (event) => {
            if (!(event.target instanceof HTMLInputElement)) {
                return;
            }
            event.target.select();
            vue.$emit('focus', event);
        }
    };
}
function createIconAttrs(props) {
    if (props.icon === undefined) {
        return {};
    }
    const icon = `$${props.icon}`;
    switch (props.iconLocation) {
        case 'append':
            return { appendOuterIcon: icon };
        case 'prepend':
            return { prependIcon: icon };
        case 'append-inner':
            return { appendIcon: icon };
        case 'prepend-inner':
            return { prependInnerIcon: icon };
        default:
            return {};
    }
}
function createIconListeners(props, vue) {
    if (vue.$listeners['click:icon'] === undefined) {
        return {};
    }
    switch (props.iconLocation) {
        case 'append':
            return { 'click:append-outer': (e) => vue.$emit('click:icon', e) };
        case 'prepend':
            return { 'click:prepend': (e) => vue.$emit('click:icon', e) };
        case 'append-inner':
            return { 'click:append': (e) => vue.$emit('click:icon', e) };
        case 'prepend-inner':
            return { 'click:prepend-inner': (e) => vue.$emit('click:icon', e) };
        default:
            return {};
    }
}
