import { __awaiter } from "tslib";
import { watchOnce } from '@vueuse/core';
import { initialize } from 'launchdarkly-js-client-sdk';
import Vue, { computed, readonly, ref } from 'vue';
import { cloneDeep } from 'lodash';
import { useAppConfig } from '../composables';
import { isNilOrWhitespace } from '../utils';
export const flagsPluginKey = Symbol('flagsPlugin');
const { version, launchDarklyClientId: clientId } = useAppConfig();
const appName = 'g1-web-app';
const appContext = {
    kind: 'app',
    name: appName,
    key: `g1-web-app-${version}`,
    version
};
const baseContext = {
    kind: 'multi',
    app: appContext
};
const offlinePlugin = {
    setContext() { return Promise.resolve(); },
    getFlag(_, defaultValue) { return readonly(ref(defaultValue)); },
    ready: readonly(ref(true))
};
const plugin = {
    install(vue) {
        if (isNilOrWhitespace(clientId)) {
            vue.prototype.$flags = offlinePlugin;
            return;
        }
        const flagContext = ref(null);
        const ldClient = initialize(clientId, baseContext, {
            application: {
                id: appName,
                version
            }
        });
        const ready = ref(false);
        ldClient.on('initialized', () => {
            ready.value = true;
        });
        const ldContext = computed(() => {
            if (flagContext.value === null) {
                return baseContext;
            }
            const context = cloneDeep(baseContext);
            if ('brand' in flagContext.value) {
                context.brand = {
                    kind: 'brand',
                    key: flagContext.value.brand.id.toString(),
                    name: flagContext.value.brand.name,
                    accountName: flagContext.value.brand.accountName
                };
                return context;
            }
            context.location = {
                kind: 'location',
                key: flagContext.value.location.id.toString(),
                accountName: flagContext.value.location.accountName,
                name: flagContext.value.location.name
            };
            if (flagContext.value.location.brand !== null) {
                context.brand = {
                    kind: 'brand',
                    key: flagContext.value.location.brand.id.toString(),
                    name: flagContext.value.location.brand.name,
                    accountName: flagContext.value.location.brand.accountName
                };
            }
            return context;
        });
        const flagsPlugin = {
            setContext(context) {
                return __awaiter(this, void 0, void 0, function* () {
                    flagContext.value = context;
                    if (!ready.value) {
                        return;
                    }
                    yield ldClient.identify(ldContext.value);
                });
            },
            getFlag(flagKey, defaultValue) {
                var _a;
                if (ldClient === null) {
                    throw new Error('LaunchDarkly client is not initialized');
                }
                const value = ready.value
                    ? (_a = ldClient.variation(flagKey, defaultValue)) !== null && _a !== void 0 ? _a : defaultValue
                    : defaultValue;
                const flag = ref(value);
                function setFlag(newFlagValue) {
                    flag.value = newFlagValue;
                }
                if (!ready.value) {
                    watchOnce(ready, () => {
                        var _a;
                        setFlag((_a = ldClient.variation(flagKey, defaultValue)) !== null && _a !== void 0 ? _a : defaultValue);
                    });
                }
                ldClient.on(`change:${flagKey}`, setFlag);
                return readonly(flag);
            },
            ready: readonly(ready)
        };
        Vue.prototype.$flags = flagsPlugin;
    }
};
export default plugin;
