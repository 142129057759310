import { __awaiter } from "tslib";
export function createMenuService(client) {
    return {
        searchMenus(accountName, request) {
            return client.post(`${accountName}/menus/search`, request);
        },
        getMenu(accountName, menuID) {
            return __awaiter(this, void 0, void 0, function* () {
                const colorOptionsResponse = yield this.getColorOptions(accountName);
                if (!colorOptionsResponse.successful) {
                    return colorOptionsResponse;
                }
                const menuResponse = yield client.get(`${accountName}/menus/${menuID}`);
                if (menuResponse.successful) {
                    normalizeMenu(menuResponse.data, colorOptionsResponse.data);
                }
                return menuResponse;
            });
        },
        createMenu(accountName, request) {
            return client.post(`${accountName}/menus`, request);
        },
        updateMenu(accountName, menuID, request) {
            return client.put(`${accountName}/menus/${menuID}`, request);
        },
        deleteMenu(accountName, menuID) {
            return client.delete(`${accountName}/menus/${menuID}`);
        },
        getColorOptions(accountName) {
            return client.get(`${accountName}/menus/colorOptions`);
        }
    };
}
function normalizeMenu(menu, colorOptions) {
    var _a, _b, _c, _d, _e;
    const defaultColor = (_b = (_a = colorOptions.find(o => o.secondaryColor === 'blue')) !== null && _a !== void 0 ? _a : colorOptions[0]) !== null && _b !== void 0 ? _b : { primaryColor: 'blue', secondaryColor: 'blue', id: 0 };
    if (menu.tabs.length === 0) {
        menu.tabs.push({
            id: null,
            name: 'Default',
            displayMetadata: {
                colIndex: menu.tabs.length,
                pages: 1,
                colorOptionID: defaultColor.id,
                colorOption: defaultColor
            },
            items: []
        });
    }
    for (const item of menu.items) {
        item.displayMetadata.colorOption = (_c = colorOptions.find(o => o.id === item.displayMetadata.colorOptionID)) !== null && _c !== void 0 ? _c : defaultColor;
        item.displayMetadata.colorOptionID = item.displayMetadata.colorOption.id;
    }
    for (const tab of menu.tabs) {
        tab.displayMetadata.colorOption = (_d = colorOptions.find(o => o.id === tab.displayMetadata.colorOptionID)) !== null && _d !== void 0 ? _d : defaultColor;
        tab.displayMetadata.colorOptionID = tab.displayMetadata.colorOption.id;
        for (const item of tab.items) {
            item.displayMetadata.colorOption = (_e = colorOptions.find(o => o.id === item.displayMetadata.colorOptionID)) !== null && _e !== void 0 ? _e : defaultColor;
            item.displayMetadata.colorOptionID = item.displayMetadata.colorOption.id;
        }
    }
}
