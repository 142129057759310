export function createRaincheckService(client) {
    return {
        getRaincheck(accountName, id) {
            return client.get(`${accountName}/raincheck/${id}`);
        },
        searchRainchecks(accountName, request) {
            return client.post(`${accountName}/raincheck/search`, request);
        },
        createRaincheck(accountName, request) {
            return client.post(`${accountName}/raincheck`, request);
        },
        updateRaincheck(accountName, id, request) {
            return client.put(`${accountName}/raincheck/${id}`, request);
        },
        cancelRaincheck(accountName, request) {
            return client.post(`${accountName}/raincheck/cancel`, request);
        }
    };
}
