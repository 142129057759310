export function createCustomerNoteService(client) {
    return {
        getCustomerNote(id) {
            return client.get(`customerNotes/${id}`);
        },
        createCustomerNote(request) {
            return client.post('customerNotes', request);
        },
        updateCustomerNote(id, customerID, request) {
            return client.put('customerNotes', Object.assign({ id, customerID }, request));
        },
        deleteCustomerNote(id) {
            return client.delete(`customerNotes/${id}`);
        }
    };
}
