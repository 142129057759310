import { PhoneFormat } from '@/types/locations';
import { isNilOrWhitespace } from './helpers';
export function formatPhone(phoneNumber, format = PhoneFormat.NANPA) {
    if (phoneNumber == null || isNilOrWhitespace(phoneNumber)) {
        return '';
    }
    switch (format) {
        case PhoneFormat.NANPA:
            phoneNumber = phoneNumber.replace(/\D/g, '');
            phoneNumber = phoneNumber.slice(-10);
            if (phoneNumber.length !== 10) {
                return phoneNumber;
            }
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        default:
            return phoneNumber;
    }
}
