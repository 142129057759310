const dateTimeFormats = {
    'en-US': {
        shortDate: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        longDate: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short'
        },
        longDateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        },
        longDateTimeS: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        },
        shortTime: {
            hour: 'numeric', minute: 'numeric', hour12: true
        },
        longTime: {
            hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
        },
        longUtcDateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
            timeZoneName: 'short'
        },
        shortUtcDateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour12: false,
            timeZone: 'UTC',
            timeZoneName: 'short'
        },
        shortMonthYear: {
            year: 'numeric', month: 'short'
        }
    },
    'en-GB': {
        shortDate: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        longDate: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short'
        },
        longDateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        },
        longDateTimeS: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        },
        shortTime: {
            hour: 'numeric', minute: 'numeric', hour12: true
        },
        longTime: {
            hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
        },
        longUtcDateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
            timeZoneName: 'short'
        },
        shortUtcDateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour12: false,
            timeZone: 'UTC',
            timeZoneName: 'short'
        },
        shortMonthYear: {
            year: 'numeric', month: 'short'
        }
    },
    'en-IE': {
        shortDate: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        longDate: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short'
        },
        longDateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        },
        longDateTimeS: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        },
        shortTime: {
            hour: 'numeric', minute: 'numeric', hour12: true
        },
        longTime: {
            hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
        },
        longUtcDateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
            timeZoneName: 'short'
        },
        shortUtcDateTime: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour12: false,
            timeZone: 'UTC',
            timeZoneName: 'short'
        },
        shortMonthYear: {
            year: 'numeric', month: 'short'
        }
    }
};
export default dateTimeFormats;
