export const numberFormats = {
    decimal: {
        style: 'decimal',
        minimumFractionDigits: 2
    }
};
export const currencyFormats = {
    CAD: {
        currencySymbol: 'CAN$'
    }
};
