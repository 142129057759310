export var CreditCardPaymentFlow;
(function (CreditCardPaymentFlow) {
    CreditCardPaymentFlow[CreditCardPaymentFlow["PreauthorizeOnAddPayment"] = 1] = "PreauthorizeOnAddPayment";
    CreditCardPaymentFlow[CreditCardPaymentFlow["ChargeOnAddPayment"] = 2] = "ChargeOnAddPayment";
})(CreditCardPaymentFlow || (CreditCardPaymentFlow = {}));
export var CustomPaymentMethodType;
(function (CustomPaymentMethodType) {
    CustomPaymentMethodType["CashEquivalent"] = "cashEquivalent";
    CustomPaymentMethodType["GiftCertificateProgram"] = "giftCertificateProgram";
    CustomPaymentMethodType["CashiersCheck"] = "cashiersCheck";
})(CustomPaymentMethodType || (CustomPaymentMethodType = {}));
export var OrderItemType;
(function (OrderItemType) {
    OrderItemType["Product"] = "product";
    OrderItemType["GiftCertificate"] = "giftCertificate";
    OrderItemType["ServiceAddOn"] = "serviceAddOn";
    OrderItemType["Series"] = "series";
    OrderItemType["EmployeeTip"] = "employeeTip";
    OrderItemType["CreditAccountCharge"] = "creditAccountCharge";
    OrderItemType["MembershipSignup"] = "membershipSignup";
    OrderItemType["MembershipFee"] = "membershipFee";
    OrderItemType["GiftCardRefill"] = "giftCardRefill";
    OrderItemType["Package"] = "package";
    OrderItemType["SpecialFreeItem"] = "specialFreeItem";
    OrderItemType["MembershipFreezeFee"] = "membershipFreezeFee";
    OrderItemType["GreenFee"] = "greenFee";
    OrderItemType["CreditAccountBalanceForward"] = "creditAccountBalanceForward";
    OrderItemType["ExternalAccountBalanceReplinish"] = "externalAccountBalanceReplinish";
    OrderItemType["CreditBooks"] = "creditBooks";
    OrderItemType["ExpirationCreditBooks"] = "expirationVirtual";
    OrderItemType["Service"] = "service";
})(OrderItemType || (OrderItemType = {}));
export var CreditCardType;
(function (CreditCardType) {
    CreditCardType[CreditCardType["ErrorParsingType"] = -1] = "ErrorParsingType";
    CreditCardType["AmericanExpress"] = "americanExpress";
    CreditCardType["Visa"] = "visa";
    CreditCardType["MasterCard"] = "masterCard";
    CreditCardType["Discover"] = "discover";
    CreditCardType["JCB"] = "jcb";
    CreditCardType["Debit"] = "debit";
    CreditCardType["DinersClub"] = "dinersClub";
    CreditCardType["Maestro"] = "maestro";
    CreditCardType["Solo"] = "solo";
    CreditCardType["Samsung"] = "samsung";
    CreditCardType["ShinHan"] = "shinHan";
    CreditCardType["KookMin"] = "kookMin";
    CreditCardType["Lotte"] = "lotte";
    CreditCardType["KEB"] = "KEB";
    CreditCardType["BC"] = "BC";
    CreditCardType["HyunDai"] = "hyunDai";
    CreditCardType["Naps"] = "naps";
    CreditCardType["ElectronVisa"] = "electronVisa";
    CreditCardType["DeltaVisa"] = "deltaVisa";
    CreditCardType["NH"] = "NH";
    CreditCardType["JEJU"] = "JEJU";
    CreditCardType["Bancomer"] = "bancomer";
    CreditCardType["Banamex"] = "banamex";
    CreditCardType["Santander"] = "santander";
    CreditCardType["CMRFalabella"] = "CMRFalabella";
    CreditCardType["Dankort"] = "dankort";
    CreditCardType["UnionPay"] = "unionPay";
})(CreditCardType || (CreditCardType = {}));
export var GiftCertificateExpirationUnitType;
(function (GiftCertificateExpirationUnitType) {
    GiftCertificateExpirationUnitType["Day"] = "day";
    GiftCertificateExpirationUnitType["Month"] = "month";
    GiftCertificateExpirationUnitType["Year"] = "year";
})(GiftCertificateExpirationUnitType || (GiftCertificateExpirationUnitType = {}));
export var PointOfSaleView;
(function (PointOfSaleView) {
    PointOfSaleView["TeeSheet"] = "teeSheet";
    PointOfSaleView["Catalog"] = "catalog";
})(PointOfSaleView || (PointOfSaleView = {}));
export var OrderReadyStatus;
(function (OrderReadyStatus) {
    OrderReadyStatus["InProgress"] = "inProgress";
    OrderReadyStatus["OrderReady"] = "orderReady";
})(OrderReadyStatus || (OrderReadyStatus = {}));
export var OrderNoteType;
(function (OrderNoteType) {
    OrderNoteType["General"] = "general";
    OrderNoteType["Kitchen"] = "kitchen";
})(OrderNoteType || (OrderNoteType = {}));
export var OrderSearchItemAdvancedFilter;
(function (OrderSearchItemAdvancedFilter) {
    OrderSearchItemAdvancedFilter["HasProducts"] = "hasProducts";
    OrderSearchItemAdvancedFilter["HasServices"] = "hasServices";
    OrderSearchItemAdvancedFilter["HasGiftCertificates"] = "hasGiftCertificates";
    OrderSearchItemAdvancedFilter["HasCancellationFee"] = "hasCancellationFee";
    OrderSearchItemAdvancedFilter["HasSpecialApplied"] = "hasSpecialApplied";
    OrderSearchItemAdvancedFilter["HasPackages"] = "hasPackages";
    OrderSearchItemAdvancedFilter["HasSeries"] = "hasSeries";
    OrderSearchItemAdvancedFilter["HasRefund"] = "hasRefund";
    OrderSearchItemAdvancedFilter["HasPointsAccrued"] = "hasPointsAccrued";
    OrderSearchItemAdvancedFilter["HasPointsUsed"] = "hasPointsUsed";
    OrderSearchItemAdvancedFilter["HasTip"] = "hasTip";
    OrderSearchItemAdvancedFilter["HasMembershipFee"] = "hasMembershipFee";
    OrderSearchItemAdvancedFilter["HasShipping"] = "hasShipping";
    OrderSearchItemAdvancedFilter["HasCreditBooks"] = "hasCreditBooks";
    OrderSearchItemAdvancedFilter["HasCreditBooksExpiration"] = "hasCreditBooksExpiration";
    OrderSearchItemAdvancedFilter["HasMembershipRenewal"] = "hasMembershipRenewal";
    OrderSearchItemAdvancedFilter["PaymentMethod"] = "paymentMethod";
    OrderSearchItemAdvancedFilter["FinalTotal"] = "finalTotal";
    OrderSearchItemAdvancedFilter["LastRefundDateRange"] = "lastRefundDateRange";
    OrderSearchItemAdvancedFilter["DateCreated"] = "dateCreated";
    OrderSearchItemAdvancedFilter["CreatedByEmployee"] = "createdByEmployee";
    OrderSearchItemAdvancedFilter["Source"] = "source";
    OrderSearchItemAdvancedFilter["CashRegister"] = "cashRegister";
    OrderSearchItemAdvancedFilter["CustomerCountry"] = "customerCountry";
    OrderSearchItemAdvancedFilter["CustomerDateCreatedRange"] = "customerDateCreatedRange";
    OrderSearchItemAdvancedFilter["CustomerMembership"] = "customerMembership";
    OrderSearchItemAdvancedFilter["CustomerState"] = "customerState";
    OrderSearchItemAdvancedFilter["StartEndDate"] = "startEndDate";
})(OrderSearchItemAdvancedFilter || (OrderSearchItemAdvancedFilter = {}));
export var OrderSearchItemInclude;
(function (OrderSearchItemInclude) {
    OrderSearchItemInclude["OrderItems"] = "orderItems";
    OrderSearchItemInclude["PaymentMethodNames"] = "paymentMethodNames";
    OrderSearchItemInclude["TableNames"] = "TableNames";
    OrderSearchItemInclude["CreatedBy"] = "createdBy";
    OrderSearchItemInclude["Customer"] = "customer";
    OrderSearchItemInclude["CustomerAddress"] = "customerAddress";
    OrderSearchItemInclude["CustomerEmailSettings"] = "customerEmailSettings";
    OrderSearchItemInclude["CashRegister"] = "cashRegister";
    OrderSearchItemInclude["CashRegisterCloseOutPeriod"] = "cashRegisterCloseOutPeriod";
    OrderSearchItemInclude["NotesCount"] = "notesCount";
    OrderSearchItemInclude["Payment"] = "payment";
    OrderSearchItemInclude["TotalProducts"] = "totalProducts";
    OrderSearchItemInclude["TotalServices"] = "totalServices";
    OrderSearchItemInclude["TotalGiftCertificates"] = "totalGiftCertificates";
    OrderSearchItemInclude["TotalCancellationFee"] = "totalCancellationFee";
    OrderSearchItemInclude["TotalMembershipFee"] = "totalMembershipFee";
    OrderSearchItemInclude["TotalDiscountSpecial"] = "totalDiscountSpecial";
    OrderSearchItemInclude["TotalPackages"] = "totalPackages";
    OrderSearchItemInclude["TotalSeries"] = "totalSeries";
    OrderSearchItemInclude["TotalTips"] = "totalTips";
    OrderSearchItemInclude["PaymentSpecial"] = "paymentSpecial";
    OrderSearchItemInclude["Change"] = "change";
    OrderSearchItemInclude["Location"] = "location";
    OrderSearchItemInclude["RefundState"] = "refundState";
    OrderSearchItemInclude["ReadyStatus"] = "readyStatus";
    OrderSearchItemInclude["HasRefund"] = "hasRefund";
})(OrderSearchItemInclude || (OrderSearchItemInclude = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["CreditCard"] = "creditCard";
    PaymentMethod["GiftCertificate"] = "giftCertificate";
    PaymentMethod["Check"] = "check";
    PaymentMethod["Cash"] = "cash";
    PaymentMethod["Series"] = "series";
    PaymentMethod["CreditAccount"] = "creditAccount";
    PaymentMethod["RewardPoints"] = "rewardPoints";
    PaymentMethod["MembershipBenefit"] = "membershipBenefit";
    PaymentMethod["BankTransfer"] = "bankTransfer";
    PaymentMethod["Raincheck"] = "raincheck";
    PaymentMethod["ExternalAccountBalance"] = "externalAccountBalance";
    PaymentMethod["ExternalGolfNow"] = "externalGolfNow";
    PaymentMethod["BrsPayment"] = "brsPayment";
    PaymentMethod["CustomerCreditBooks"] = "customerCreditBooks";
    PaymentMethod["MasterCreditBooks"] = "masterCreditBooks";
    PaymentMethod["Ach"] = "ach";
})(PaymentMethod || (PaymentMethod = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["Open"] = "open";
    OrderStatus["Closed"] = "closed";
    OrderStatus["Cancelled"] = "cancelled";
    OrderStatus["Shipped"] = "shipped";
    OrderStatus["PartiallyPaid"] = "partiallyPaid";
    OrderStatus["Voided"] = "voided";
    OrderStatus["Paid"] = "paid";
    OrderStatus["Abandoned"] = "abandoned";
})(OrderStatus || (OrderStatus = {}));
export var OrderSource;
(function (OrderSource) {
    OrderSource["Internal"] = "internal";
    OrderSource["Customer"] = "customer";
    OrderSource["API"] = "api";
    OrderSource["Desktop"] = "desktop";
    OrderSource["Tablet"] = "tablet";
    OrderSource["SmartPlay"] = "smartPlay";
    OrderSource["Teesheet"] = "teesheet";
    OrderSource["Prepaid"] = "golfNowPrepaid";
    OrderSource["GolfNowFeaturedProduct"] = "golfNowFeaturedProduct";
    OrderSource["GolfNow"] = "golfNow";
    OrderSource["ThirdParty"] = "thirdParty";
})(OrderSource || (OrderSource = {}));
export var OrderSearchItemAdvancedFilterFinalTotal;
(function (OrderSearchItemAdvancedFilterFinalTotal) {
    OrderSearchItemAdvancedFilterFinalTotal[OrderSearchItemAdvancedFilterFinalTotal["LessThan50"] = 0] = "LessThan50";
    OrderSearchItemAdvancedFilterFinalTotal[OrderSearchItemAdvancedFilterFinalTotal["From50To100"] = 1] = "From50To100";
    OrderSearchItemAdvancedFilterFinalTotal[OrderSearchItemAdvancedFilterFinalTotal["From100to200"] = 2] = "From100to200";
    OrderSearchItemAdvancedFilterFinalTotal[OrderSearchItemAdvancedFilterFinalTotal["MoreThan200"] = 3] = "MoreThan200";
})(OrderSearchItemAdvancedFilterFinalTotal || (OrderSearchItemAdvancedFilterFinalTotal = {}));
export var OrderSearchItemAdvancedFilterCustomerMembership;
(function (OrderSearchItemAdvancedFilterCustomerMembership) {
    OrderSearchItemAdvancedFilterCustomerMembership["AllMembers"] = "allMembers";
    OrderSearchItemAdvancedFilterCustomerMembership["AllNonMembers"] = "allNonMembers";
    OrderSearchItemAdvancedFilterCustomerMembership["FrozenMembers"] = "frozenMembers";
    OrderSearchItemAdvancedFilterCustomerMembership["SuspendedMembers"] = "suspendedMembers";
})(OrderSearchItemAdvancedFilterCustomerMembership || (OrderSearchItemAdvancedFilterCustomerMembership = {}));
export var OrderNotificationCategory;
(function (OrderNotificationCategory) {
    OrderNotificationCategory["General"] = "general";
    OrderNotificationCategory["TeesheetNotification"] = "teesheetNotification";
    OrderNotificationCategory["CreditAccountStatement"] = "creditAccountStatement";
    OrderNotificationCategory["MembershipBillingStatement"] = "membershipBillingStatement";
    OrderNotificationCategory["GiftCertificate"] = "giftCertificate";
    OrderNotificationCategory["SmartPlayOrderConfirmation"] = "smartPlayOrderConfirmation";
    OrderNotificationCategory["SmartPlayOutForDelivery"] = "smartPlayOutForDelivery";
    OrderNotificationCategory["OrderReceipt"] = "orderReceipt";
    OrderNotificationCategory["OnlineGiftCertificateReceipt"] = "onlineGiftCertificateReceipt";
    OrderNotificationCategory["OnlineGiftCertificateConfirmation"] = "onlineGiftCertificateConfirmation";
    OrderNotificationCategory["OnlineMembershipConfirmation"] = "onlineMembershipConfirmation";
    OrderNotificationCategory["OnlineMembershipReceipt"] = "onlineMembershipReceipt";
})(OrderNotificationCategory || (OrderNotificationCategory = {}));
export var OrderNotificationType;
(function (OrderNotificationType) {
    OrderNotificationType["Email"] = "email";
    OrderNotificationType["Sms"] = "sms";
})(OrderNotificationType || (OrderNotificationType = {}));
export var RefundState;
(function (RefundState) {
    RefundState["Full"] = "full";
    RefundState["Partial"] = "partial";
})(RefundState || (RefundState = {}));
export var FeatureType;
(function (FeatureType) {
    FeatureType["FeaturedProduct"] = "featuredProduct";
    FeatureType["SmartPlay"] = "smartPlay";
    FeatureType["Prepaid"] = "prepaid";
    FeatureType["Memberships"] = "memberships";
    FeatureType["GiftCertificates"] = "giftCertificates";
    FeatureType["CreditBookExpiration"] = "creditBookExpiration";
})(FeatureType || (FeatureType = {}));
export var MinimumSellPriceValidationBehavior;
(function (MinimumSellPriceValidationBehavior) {
    MinimumSellPriceValidationBehavior["DoNotAllow"] = "doNotAllow";
    MinimumSellPriceValidationBehavior["SetToBounds"] = "setToBounds";
    MinimumSellPriceValidationBehavior["Ignore"] = "ignore";
})(MinimumSellPriceValidationBehavior || (MinimumSellPriceValidationBehavior = {}));
export var CreditCardEntryMethod;
(function (CreditCardEntryMethod) {
    CreditCardEntryMethod["Manual"] = "manual";
    CreditCardEntryMethod["Swipe"] = "swipe";
    CreditCardEntryMethod["PinPad"] = "pinPad";
    CreditCardEntryMethod["CustomerProfile"] = "customerProfile";
})(CreditCardEntryMethod || (CreditCardEntryMethod = {}));
export var RefundPaymentMethodType;
(function (RefundPaymentMethodType) {
    RefundPaymentMethodType["None"] = "none";
    RefundPaymentMethodType["Cash"] = "cash";
    RefundPaymentMethodType["Check"] = "check";
    RefundPaymentMethodType["NewGiftCard"] = "newGiftCard";
    RefundPaymentMethodType["NewGiftCertificate"] = "newGiftCertificate";
    RefundPaymentMethodType["CreditAccount"] = "creditAccount";
    RefundPaymentMethodType["AccountBalance"] = "accountBalance";
    RefundPaymentMethodType["ExternalGolfNow"] = "externalGolfNow";
    RefundPaymentMethodType["RewardsPoints"] = "rewardsPoints";
    RefundPaymentMethodType["CreditCard"] = "creditCard";
    RefundPaymentMethodType["ExternalAccountBalance"] = "externalAccountBalance";
    RefundPaymentMethodType["ReverseAll"] = "reverseAll";
})(RefundPaymentMethodType || (RefundPaymentMethodType = {}));
export var RefundItemType;
(function (RefundItemType) {
    RefundItemType["OrderItem"] = "orderItem";
    RefundItemType["OrderTip"] = "orderTip";
    RefundItemType["IndividualTax"] = "individualTax";
    RefundItemType["AllTaxes"] = "allTaxes";
    RefundItemType["EntireOrder"] = "entireOrder";
    RefundItemType["Deposit"] = "deposit";
    RefundItemType["FlatAmount"] = "flatAmount";
    RefundItemType["ServiceCharge"] = "serviceCharge";
    RefundItemType["PaymentItem"] = "paymentItem";
    RefundItemType["OrderTipSplit"] = "orderTipSplit";
    RefundItemType["AutoGratuity"] = "autoGratuity";
    RefundItemType["IndividualSurcharge"] = "individualSurcharge";
    RefundItemType["WholeOrder"] = "wholeOrder";
})(RefundItemType || (RefundItemType = {}));
export var MembershipPaymentFrequency;
(function (MembershipPaymentFrequency) {
    MembershipPaymentFrequency["OneTime"] = "oneTime";
    MembershipPaymentFrequency["Monthly"] = "monthly";
    MembershipPaymentFrequency["Quarterly"] = "quarterly";
    MembershipPaymentFrequency["BiMonthly"] = "biMonthly";
    MembershipPaymentFrequency["BiWeekly"] = "biWeekly";
    MembershipPaymentFrequency["Weekly"] = "weekly";
    MembershipPaymentFrequency["Annually"] = "annually";
})(MembershipPaymentFrequency || (MembershipPaymentFrequency = {}));
export var MembershipPaymentExpiration;
(function (MembershipPaymentExpiration) {
    MembershipPaymentExpiration["Months"] = "months";
    MembershipPaymentExpiration["Weeks"] = "weeks";
})(MembershipPaymentExpiration || (MembershipPaymentExpiration = {}));
export var ModifierGroupType;
(function (ModifierGroupType) {
    ModifierGroupType["Forced"] = "forced";
    ModifierGroupType["Addon"] = "addon";
})(ModifierGroupType || (ModifierGroupType = {}));
export var CreditAccountType;
(function (CreditAccountType) {
    CreditAccountType["Customer"] = "customer";
    CreditAccountType["Corporate"] = "corporate";
    CreditAccountType["Membership"] = "membership";
})(CreditAccountType || (CreditAccountType = {}));
export var ExternalGolfNowPaymentType;
(function (ExternalGolfNowPaymentType) {
    ExternalGolfNowPaymentType["Unknown"] = "unknown";
    ExternalGolfNowPaymentType["CreditCard"] = "creditCard";
    ExternalGolfNowPaymentType["AccountBalance"] = "accountBalance";
})(ExternalGolfNowPaymentType || (ExternalGolfNowPaymentType = {}));
export var GiftCertificatePaymentType;
(function (GiftCertificatePaymentType) {
    GiftCertificatePaymentType["SpaFinderGiftCertificate"] = "spaFinderGiftCertificate";
    GiftCertificatePaymentType["MerchantGiftCertificate"] = "merchantGiftCertificate";
    GiftCertificatePaymentType["MerchantGiftCard"] = "merchantGiftCard";
    GiftCertificatePaymentType["SpaWishGiftCertificate"] = "spaWishGiftCertificate";
    GiftCertificatePaymentType["SpaBoomGiftCertificate"] = "spaBoomGiftCertificate";
    GiftCertificatePaymentType["Raincheck"] = "raincheck";
})(GiftCertificatePaymentType || (GiftCertificatePaymentType = {}));
export var SeriesExpirationType;
(function (SeriesExpirationType) {
    SeriesExpirationType["Never"] = "never";
    SeriesExpirationType["OnDate"] = "onDate";
    SeriesExpirationType["DaysFromPurchase"] = "daysFromPurchase";
    SeriesExpirationType["DaysFromFirstUse"] = "daysFromFirstUse";
})(SeriesExpirationType || (SeriesExpirationType = {}));
export var SignatureType;
(function (SignatureType) {
    SignatureType["Tablet"] = "tablet";
    SignatureType["Pinpad"] = "pinpad";
})(SignatureType || (SignatureType = {}));
export var PaymentTransactionType;
(function (PaymentTransactionType) {
    PaymentTransactionType["Charge"] = "charge";
    PaymentTransactionType["Refund"] = "refund";
    PaymentTransactionType["Preauthorize"] = "preauthorize";
    PaymentTransactionType["Capture"] = "capture";
    PaymentTransactionType["ReversePreauthorize"] = "reversePreauthorize";
    PaymentTransactionType["Void"] = "void";
})(PaymentTransactionType || (PaymentTransactionType = {}));
export var PaymentFrequencyType;
(function (PaymentFrequencyType) {
    PaymentFrequencyType["OneTime"] = "oneTime";
    PaymentFrequencyType["Monthly"] = "monthly";
    PaymentFrequencyType["Quarterly"] = "quarterly";
    PaymentFrequencyType["BiMonthly"] = "biMonthly";
    PaymentFrequencyType["BiWeekly"] = "biWeekly";
    PaymentFrequencyType["Weekly"] = "weekly";
    PaymentFrequencyType["Annually"] = "annually";
})(PaymentFrequencyType || (PaymentFrequencyType = {}));
export var PaymentExpirationType;
(function (PaymentExpirationType) {
    PaymentExpirationType["Months"] = "months";
    PaymentExpirationType["Weeks"] = "weeks";
})(PaymentExpirationType || (PaymentExpirationType = {}));
export var ThirdPartyCategoryType;
(function (ThirdPartyCategoryType) {
    ThirdPartyCategoryType[ThirdPartyCategoryType["Small"] = 1] = "Small";
    ThirdPartyCategoryType[ThirdPartyCategoryType["Medium"] = 2] = "Medium";
    ThirdPartyCategoryType[ThirdPartyCategoryType["Large"] = 3] = "Large";
    ThirdPartyCategoryType[ThirdPartyCategoryType["ExtraLarge"] = 4] = "ExtraLarge";
    ThirdPartyCategoryType[ThirdPartyCategoryType["Custom"] = 5] = "Custom";
})(ThirdPartyCategoryType || (ThirdPartyCategoryType = {}));
export var ThirdPartyVendorType;
(function (ThirdPartyVendorType) {
    ThirdPartyVendorType[ThirdPartyVendorType["ERange"] = 1] = "ERange";
    ThirdPartyVendorType[ThirdPartyVendorType["RangeServant"] = 2] = "RangeServant";
})(ThirdPartyVendorType || (ThirdPartyVendorType = {}));
export var ReceiptType;
(function (ReceiptType) {
    ReceiptType[ReceiptType["Invoice"] = 1] = "Invoice";
    ReceiptType[ReceiptType["Itemized"] = 2] = "Itemized";
    ReceiptType[ReceiptType["Gift"] = 3] = "Gift";
    ReceiptType[ReceiptType["All"] = 4] = "All";
    ReceiptType[ReceiptType["ExternalAccountBalance"] = 5] = "ExternalAccountBalance";
})(ReceiptType || (ReceiptType = {}));
export var CartAgreementOption;
(function (CartAgreementOption) {
    CartAgreementOption["None"] = "none";
    CartAgreementOption["PerOrder"] = "perOrder";
    CartAgreementOption["PerCart"] = "perCart";
})(CartAgreementOption || (CartAgreementOption = {}));
export var CashRegisterPayOptionType;
(function (CashRegisterPayOptionType) {
    CashRegisterPayOptionType["Employee"] = "employee";
    CashRegisterPayOptionType["Vendor"] = "vendor";
})(CashRegisterPayOptionType || (CashRegisterPayOptionType = {}));
export var CashRegisterPayoutOriginType;
(function (CashRegisterPayoutOriginType) {
    CashRegisterPayoutOriginType["OrderTip"] = "orderTip";
    CashRegisterPayoutOriginType["AutoGratuity"] = "autoGratuity";
    CashRegisterPayoutOriginType["Manual"] = "manual";
    CashRegisterPayoutOriginType["EmployeeTip"] = "employeeTip";
})(CashRegisterPayoutOriginType || (CashRegisterPayoutOriginType = {}));
export var AchTransactionType;
(function (AchTransactionType) {
    AchTransactionType["Debit"] = "debit";
    AchTransactionType["Credit"] = "credit";
})(AchTransactionType || (AchTransactionType = {}));
export var AchTransactionResponseType;
(function (AchTransactionResponseType) {
    AchTransactionResponseType["Approved"] = "approved";
    AchTransactionResponseType["Declined"] = "declined";
    AchTransactionResponseType["Error"] = "error";
    AchTransactionResponseType["Exception"] = "exception";
})(AchTransactionResponseType || (AchTransactionResponseType = {}));
export var TeeSheetNavigationTargetType;
(function (TeeSheetNavigationTargetType) {
    TeeSheetNavigationTargetType["Undefined"] = "undefined";
    TeeSheetNavigationTargetType["TeeSheet"] = "teeSheet";
    TeeSheetNavigationTargetType["StarterRanger"] = "starterRanger";
})(TeeSheetNavigationTargetType || (TeeSheetNavigationTargetType = {}));
