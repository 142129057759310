import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useBrowser } from '@/g1/composables';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const { browser } = useBrowser();
const firefoxCoefficient = 1.05;
export function normalizePt(value) {
    switch (browser) {
        case 'firefox':
            return value * firefoxCoefficient;
        default:
            return value;
    }
}
export function createEmptySection(height = 30) {
    return {
        text: '.',
        fontSize: normalizePt(4),
        marginTop: normalizePt(height)
    };
}
export function createNonPrintableSection(height = 100) {
    return {
        text: '\n',
        fontSize: normalizePt(4),
        marginTop: normalizePt(height)
    };
}
export function printReceipt(documentDefinitions) {
    print(Object.assign({ pageMargins: [normalizePt(15), normalizePt(38), normalizePt(15), 0], pageSize: {
            width: normalizePt(204),
            height: 'auto'
        }, pageOrientation: 'portrait' }, documentDefinitions));
}
export function printA4(documentDefinitions) {
    print(Object.assign({ pageSize: 'A4', pageOrientation: 'portrait' }, documentDefinitions));
}
export function print(documentDefinitions) {
    const pdf = pdfMake.createPdf(documentDefinitions);
    pdf.getBlob(printBlob);
}
function printBlob(blob) {
    const url = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    document.body.appendChild(iframe);
    iframe.onload = () => {
        var _a;
        (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.print();
        URL.revokeObjectURL(url);
    };
}
