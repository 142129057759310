import { redirectToLegacy } from '@/router/utils';
import { Flag, Permission } from '@/types';
const routes = [
    {
        name: 'system.locations',
        path: 'locations',
        component: () => import('@/pages/System/Locations/LocationsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationGet]
        }
    },
    {
        name: 'system.locations.features',
        path: 'locations/:id/features',
        component: () => import('@/pages/System/Locations/LocationFeaturesPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationUpdate],
            isAvailableWhenFlagIsEnabled: Flag.Configure.FeatureManagement
        }
    },
    {
        name: 'system.locations.create',
        path: 'locations/create',
        beforeEnter: redirectToLegacy('/Admin/Spas/EditSpa.aspx?Operation=Add'),
        meta: {
            hasEveryPermission: [Permission.LocationCreate]
        }
    },
    {
        name: 'system.locations.update',
        path: 'locations/:id/update',
        beforeEnter: redirectToLegacy(r => `/Admin/Spas/EditSpa.aspx?Operation=Update&SpaID=${r.params.id}`),
        meta: {
            hasEveryPermission: [Permission.LocationUpdate]
        }
    },
    {
        name: 'system.locations.configure',
        path: 'locations/:id/configure',
        beforeEnter: redirectToLegacy(r => `/Admin/Spas/ConfigureFeatureSettings.aspx?SpaID=${r.params.id}`),
        meta: {
            hasEveryPermission: [Permission.LocationConfigure]
        }
    },
    {
        name: 'system.locations.impersonate',
        path: 'locations/:id',
        beforeEnter: redirectToLegacy(r => `/Admin/Spas/Impersonate.aspx?SpaID=${r.params.id}`),
        meta: {
            hasEveryPermission: [Permission.LocationGetAll]
        }
    },
    {
        name: 'system.brands',
        path: 'brands',
        component: () => import('@/pages/System/Brands/BrandsPage.vue'),
        props: {
            isCreationMode: false
        },
        meta: {
            hasEveryPermission: [Permission.BrandGetAll]
        }
    },
    {
        name: 'system.brands.create',
        path: 'brands/create',
        component: () => import('@/pages/System/Brands/BrandCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.BrandCreate]
        }
    },
    {
        name: 'system.brands.update',
        path: 'brands/:id/update',
        component: () => import('@/pages/System/Brands/BrandCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationUpdate]
        }
    },
    {
        name: 'system.brands.features',
        path: 'brands/:id/features',
        component: () => import('@/pages/System/Brands/BrandFeaturesPage.vue'),
        meta: {
            hasEveryPermission: [Permission.BrandConfigure],
            isAvailableWhenFlagIsEnabled: Flag.Configure.FeatureManagement
        }
    },
    {
        name: 'system.brands.impersonate',
        path: 'brands/:id',
        beforeEnter: redirectToLegacy(r => `/Admin/Brands/Impersonate.aspx?BrandID=${r.params.id}`),
        meta: {
            hasEveryPermission: [Permission.BrandGetAll]
        }
    },
    {
        name: 'system.brands.configure',
        path: 'brands/:id/configure',
        beforeEnter: redirectToLegacy(r => `/Admin/Brands/ConfigureFeatureSettings.aspx?BrandID=${r.params.id}`),
        meta: {
            hasEveryPermission: [Permission.BrandConfigure]
        }
    },
    {
        name: 'system.partners',
        path: 'partners',
        component: () => import('@/pages/System/Partner/PartnersPage.vue'),
        meta: {
            hasEveryPermission: [Permission.PartnerGetAll]
        }
    },
    {
        name: 'system.partners.create',
        path: 'partners/create',
        component: () => import('@/pages/System/Partner/PartnerCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.PartnerCreate]
        }
    },
    {
        name: 'system.partners.update',
        path: 'partners/:id',
        component: () => import('@/pages/System/Partner/PartnerCreateUpdatePage.vue'),
        meta: {
            hasSomePermission: [Permission.PartnerUpdate, Permission.PartnerGetAll]
        }
    },
    {
        name: 'system.content',
        path: 'content',
        beforeEnter: redirectToLegacy('/Admin/Content/Default.aspx')
    },
    {
        name: 'system.content.giftCertificateTemplates',
        path: 'content/giftCertificateTemplates',
        beforeEnter: redirectToLegacy('/Admin/Content/GiftCertificateTemplate/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.GiftCertificateTemplateGetAll]
        }
    },
    {
        name: 'system.content.resourceLocalization',
        path: 'content/resourceLocalization',
        beforeEnter: redirectToLegacy('/Admin/Content/Localization/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.ResourceLocalizationManage]
        }
    },
    {
        name: 'system.reports.legacy',
        path: 'reports/legacy',
        beforeEnter: redirectToLegacy('/Admin/Reports/ListReports.aspx'),
        meta: {
            hasEveryPermission: [Permission.ReportGetAll],
            isAvailableWhenFlagIsDisabled: Flag.Rollout.VueReports
        }
    },
    {
        name: 'system.reports',
        path: 'reports',
        component: () => import('@/pages/Location/Reports/ReportsList/ReportsList.vue'),
        meta: {
            hasEveryPermission: [Permission.ReportGetAll],
            isAvailableWhenFlagIsEnabled: Flag.Rollout.VueReports
        }
    },
    {
        name: 'system.reports.schedule',
        path: 'reports/schedule',
        component: () => import('@/pages/Location/Reports/Schedule/ReportSchedule.vue'),
        meta: {
            hasEveryPermission: [
                Permission.DeleteSubscription,
                Permission.GetSchedules,
                Permission.GetSubscriptionProperties,
                Permission.GetSubscriptions
            ]
        }
    },
    {
        name: 'system.settings',
        path: 'settings'
    },
    {
        name: 'system.settings.serviceCategories',
        path: 'settings/serviceCategories',
        beforeEnter: redirectToLegacy('/Admin/Settings/Treatments/Categories.aspx'),
        meta: {
            hasEveryPermission: [Permission.ServiceCategoryCreate]
        }
    },
    {
        name: 'system.settings.defaultProductHierarchy',
        path: 'settings/defaultProductHierarchy',
        beforeEnter: redirectToLegacy('/Admin/Settings/DefaultProductHierarchy/DepartmentTemplate.aspx'),
        meta: {
            hasEveryPermission: [Permission.DepartmentTemplateManage]
        }
    },
    {
        name: 'system.settings.productBrands',
        path: 'settings/productBrands',
        beforeEnter: redirectToLegacy('/Admin/Settings/ProductBrands/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.ProductBrandGetAll]
        }
    },
    {
        name: 'system.settings.vendors',
        path: 'settings/vendors',
        beforeEnter: redirectToLegacy('/Admin/Settings/Vendors/SearchVendors.aspx'),
        meta: {
            hasEveryPermission: [Permission.VendorGetAll]
        }
    },
    {
        name: 'system.settings.reports',
        path: 'settings/reports',
        beforeEnter: redirectToLegacy('/Admin/Settings/Reports/ListReports.aspx'),
        meta: {
            hasEveryPermission: [Permission.ReportManage]
        }
    },
    {
        name: 'system.settings.parentFields',
        path: 'settings/parentFields',
        beforeEnter: redirectToLegacy('/Admin/Settings/ParentFields/Default.aspx?type=parent'),
        meta: {
            hasEveryPermission: [Permission.ParentFieldGetAll]
        }
    },
    {
        name: 'system.settings.childFields',
        path: 'settings/childFields',
        beforeEnter: redirectToLegacy('/Admin/Settings/ParentFields/Default.aspx?type=child'),
        meta: {
            hasEveryPermission: [Permission.ParentFieldGetAll]
        }
    },
    {
        name: 'system.settings.customerTypes',
        path: 'settings/customerTypes',
        beforeEnter: redirectToLegacy('/Admin/Settings/CustomerTypes/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.CustomerTypeGetAll]
        }
    },
    {
        name: 'system.settings.notifications',
        path: 'settings/notifications',
        component: () => import('@/pages/System/Notifications/NotificationsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchNotification]
        }
    },
    {
        name: 'system.settings.notifications.create',
        path: 'settings/notifications/create',
        component: () => import('@/pages/System/Notifications/NotificationUpdatePage.vue')
    },
    {
        name: 'system.settings.notifications.update',
        path: 'settings/notifications/:id',
        component: () => import('@/pages/System/Notifications/NotificationUpdatePage.vue')
    },
    {
        name: 'system.configuration',
        path: 'configuration'
    },
    {
        name: 'system.configuration.features',
        path: 'configuration/features'
    },
    {
        name: 'system.configuration.brsDataCenters',
        path: 'configuration/brsDataCenters',
        beforeEnter: redirectToLegacy('/Admin/Configuration/BrsDataCenters/BrsDataCenters.aspx'),
        meta: {
            hasEveryPermission: [Permission.BrsDataCenterGet]
        }
    },
    {
        name: 'system.configuration.userAccessSets',
        path: 'configuration/userAccessSets',
        beforeEnter: redirectToLegacy('/Admin/Configuration/AccessSets/SearchAccessSets.aspx'),
        meta: {
            hasEveryPermission: [Permission.UserAccessSetGet]
        }
    },
    {
        name: 'system.configuration.smartPlay',
        path: 'configuration/smartPlay',
        beforeEnter: redirectToLegacy('/Admin/Configuration/SmartPlay/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.SmartPlayManage]
        }
    },
    {
        name: 'system.configuration.responsiveTeeSheetAssignments',
        path: 'configuration/responsiveTeeSheetAssignments',
        beforeEnter: redirectToLegacy('/Admin/Configuration/ResponsiveTeeSheetAssignments/Default.aspx'),
        meta: {
            hasEveryPermission: [Permission.ResponsiveTeeSheetAssignmentManage]
        }
    },
    {
        name: 'system.businessTypes',
        path: 'businessTypes',
        beforeEnter: redirectToLegacy('/Admin/Settings/BusinessTypes/BusinessTypes.aspx'),
        meta: {
            hasEveryPermission: [Permission.BusinessTypeGetAll]
        }
    },
    {
        name: 'system.businessTypes.configurations',
        path: 'businessTypes/configurations',
        beforeEnter: redirectToLegacy('/Admin/Settings/BusinessConfigurations/BusinessConfigurations.aspx'),
        meta: {
            hasEveryPermission: [Permission.BusinessConfigurationGetAll]
        }
    },
    {
        name: 'system.maintenance',
        path: 'maintenance'
    },
    {
        name: 'system.maintenance.scheduledTasks',
        path: 'maintenance/scheduledTasks',
        beforeEnter: redirectToLegacy('/Admin/Maintenance/ScheduledTasks.aspx'),
        meta: {
            hasEveryPermission: [Permission.ScheduledTaskGet]
        }
    },
    {
        name: 'system.maintenance.asyncTasks',
        path: 'maintenance/asyncTasks',
        beforeEnter: redirectToLegacy('/Admin/Maintenance/AsyncTasks.aspx')
    },
    {
        name: 'system.maintenance.uploads',
        path: 'maintenance/uploads',
        beforeEnter: redirectToLegacy('/Admin/Maintenance/Uploads.aspx')
    },
    {
        name: 'system.maintenance.golfNowDataExtracts',
        path: 'maintenance/golfNowDataExtracts',
        beforeEnter: redirectToLegacy('/Admin/Maintenance/GolfNowDataExtracts.aspx'),
        meta: {
            hasEveryPermission: [Permission.GolfNowDataExtractGetAll]
        }
    },
    {
        name: 'system.maintenance.eventLogs',
        path: 'maintenance/eventLogs',
        component: () => import('@/pages/System/Maintenance/searchEventLogs.vue')
    },
    {
        name: 'system.maintenance.eventLog.view',
        path: 'maintenance/eventLog/:id',
        component: () => import('@/pages/System/Maintenance/editEventLogEntry.vue')
    },
    {
        name: 'system.maintenance.creditCardTransactions',
        path: 'maintenance/creditCardTransactions',
        component: () => import('@/pages/System/Maintenance/searchCreditCardTransactions.vue')
    },
    {
        name: 'system.maintenance.transactions',
        path: 'maintenance/transactions',
        component: () => import('@/pages/System/Maintenance/TransactionsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchTransactionAudit],
            legacyRoute: { name: 'system.maintenance.transactionsOld' }
        }
    },
    {
        name: 'system.maintenance.achTransactions',
        path: 'maintenance/achTransactions',
        component: () => import('@/pages/System/Maintenance/AchTransactions/AchTransactionsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.AchTransactionGetAll]
        }
    },
    {
        name: 'system.maintenance.transactionsOld',
        path: 'maintenance/transactionsOld',
        component: () => import('@/pages/System/Maintenance/Transactions/searchTransactionsAudit.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchTransactionAudit]
        }
    },
    {
        name: 'system.maintenance.transactions.viewTransactionsAudit',
        path: 'maintenance/transactions/:id',
        component: () => import('@/pages/System/Maintenance/Transactions/viewTransactionsAudit.vue'),
        meta: {
            hasEveryPermission: [Permission.GetTransactionAudit]
        }
    },
    {
        name: 'system.maintenance.reprocessQueue',
        path: 'maintenance/reprocessQueue',
        component: () => import('@/pages/System/Maintenance/ReprocessQueuePage.vue')
    },
    {
        name: 'system.maintenance.batches',
        path: 'maintenance/batches',
        component: () => import('@/pages/System/Maintenance/Batches/BatchItemsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchBatchItems]
        }
    },
    {
        name: 'system.maintenance.batches.detail',
        path: 'maintenance/batches/:id',
        component: () => import('@/pages/System/Maintenance/Batches/BatchItemDetailPage.vue'),
        meta: {
            hasEveryPermission: [Permission.GetBatchItem, Permission.UpdateBatchItem]
        }
    },
    {
        name: 'system.maintenance.backgroundTaskSettings',
        path: 'maintenance/backgroundTaskSettings',
        component: () => import('@/pages/System/BackgroundTasks/editBackgroundTaskSettings.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchBackgroundTaskSettings]
        }
    },
    {
        name: 'system.maintenance.backgroundTaskLogs',
        path: 'maintenance/backgroundTaskLogs',
        component: () => import('@/pages/System/BackgroundTasks/searchBackgroundTaskLog.vue'),
        meta: {
            hasEveryPermission: [Permission.GetTransactionAudit]
        }
    },
    {
        name: 'system.maintenance.bulkProcessScheduling',
        path: 'maintenance/bulkProcessScheduling',
        component: () => import('@/pages/System/BulkProcessScheduling/BulkProcessSchedulingPage.vue'),
        meta: {
            hasEveryPermission: [Permission.SearchSchedules]
        }
    },
    {
        name: 'system.maintenance.bulkProcessScheduling.create',
        path: 'maintenance/bulkProcessScheduling/create',
        component: () => import('@/pages/System/BulkProcessScheduling/EditSchedulePage.vue'),
        meta: {
            hasEveryPermission: [Permission.CreateSchedule]
        }
    },
    {
        name: 'system.maintenance.bulkProcessScheduling.update',
        path: 'maintenance/bulkProcessScheduling/:id',
        component: () => import('@/pages/System/BulkProcessScheduling/EditSchedulePage.vue'),
        meta: {
            hasEveryPermission: [Permission.UpdateSchedule]
        }
    },
    {
        name: 'system.maintenance.groupUpdateEvents',
        path: 'maintenance/groupUpdateHistory',
        component: () => import('@/pages/System/Maintenance/GroupUpdateEventsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.GroupGet]
        }
    },
    {
        name: 'system.security',
        path: 'security',
        beforeEnter: redirectToLegacy('/Admin/Security/Default.aspx')
    },
    {
        name: 'system.security.users',
        path: 'security/users',
        beforeEnter: redirectToLegacy('/Admin/Security/SearchUsers.aspx'),
        meta: {
            hasEveryPermission: [Permission.UserGetAll]
        }
    },
    {
        name: 'system.security.settings',
        path: 'security/settings',
        beforeEnter: redirectToLegacy('/Admin/Security/SecuritySettings.aspx'),
        meta: {
            hasEveryPermission: [Permission.SecuritySettingsManage]
        }
    },
    {
        name: 'system.security.groups',
        path: 'security/groups',
        component: () => import('@/pages/System/Security/GroupsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.GroupGet],
            keepAliveBetweenRoutes: ['system.security.groups.create', 'system.security.groups.update']
        }
    },
    {
        name: 'system.security.groups.create',
        path: 'security/groups/create',
        component: () => import('@/pages/System/Security/GroupCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.GroupCreate]
        }
    },
    {
        name: 'system.security.groups.update',
        path: 'security/groups/:id',
        component: () => import('@/pages/System/Security/GroupCreateUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.GroupUpdate]
        }
    },
    {
        name: 'system.security.legacy.access',
        path: 'security/legacy/access',
        component: () => import('@/pages/System/Security/LegacyAccessPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LegacyAccessGet]
        }
    },
    {
        name: 'system.security.legacy.access.update',
        path: 'security/legacy/access/:id',
        component: () => import('@/pages/System/Security/LegacyAccessUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.LegacyAccessUpdate]
        }
    },
    {
        name: 'system.security.legacy.permissions',
        path: 'security/legacy/permissions',
        component: () => import('@/pages/System/Security/LegacyPermissionsPage.vue'),
        meta: {
            hasEveryPermission: [Permission.LegacyPermissionGet]
        }
    },
    {
        name: 'system.security.legacy.permissions.update',
        path: 'security/legacy/permissions/:id',
        component: () => import('@/pages/System/Security/LegacyPermissionUpdatePage.vue'),
        meta: {
            hasEveryPermission: [Permission.LegacyPermissionUpdate]
        }
    },
    {
        name: 'system.quickbooks.settings.callback',
        path: 'quickbooks/settings/callback',
        component: () => import('@/pages/System/Quickbooks/settingsCallback.vue'),
        meta: {
            hasEveryPermission: [Permission.LocationSettingsManage]
        }
    }
];
export default routes;
