import * as locales from 'date-fns/locale';
export function createLocaleSettings(options) {
    return {
        locale: options.locale,
        firstDayOfWeek: getFirstDayOfWeek(options.locale),
        dateMask: options.locale === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
    };
}
function getFirstDayOfWeek(locale) {
    var _a, _b;
    const localeInfo = new Intl.Locale(locale);
    if ('weekInfo' in localeInfo) {
        const weekInfo = localeInfo.weekInfo;
        return weekInfo.firstDay === 7 ? 0 : weekInfo.firstDay;
    }
    locale = locale.replaceAll('-', '');
    if (!(locale in locales)) {
        locale = locale.slice(0, 2);
    }
    if (!(locale in locales)) {
        return 1;
    }
    const localeIndex = locale;
    return (_b = (_a = locales[localeIndex].options) === null || _a === void 0 ? void 0 : _a.weekStartsOn) !== null && _b !== void 0 ? _b : 1;
}
