export function createBillingTypeService(client) {
    return {
        deleteBillingType(accountName, id, soft = false) {
            return client.delete(`${accountName}/billingTypes/${id}`, {
                params: {
                    softDelete: soft
                }
            });
        },
        searchBillingTypes(accountName, request, expandOptions) {
            return client.post(`${accountName}/billingTypes/search`, request, {
                params: {
                    expandOptions: expandOptions === null || expandOptions === void 0 ? void 0 : expandOptions.join(',')
                }
            });
        },
        createBillingType(accountName, request) {
            return client.post(`${accountName}/billingTypes`, request);
        },
        updateBillingType(accountName, id, request) {
            return client.put(`${accountName}/billingTypes`, request);
        }
    };
}
