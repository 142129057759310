import { getAutoNumericPredefinedOptions } from '@/g1';
const autoNumericOptions = getAutoNumericPredefinedOptions();
const options = {
    'en-US': {
        currency: autoNumericOptions.dollar,
        currencyPos: autoNumericOptions.dollarPos,
        integer: autoNumericOptions.integer,
        integerPos: autoNumericOptions.integerPos,
        float: {
            decimalPlaces: 2
        },
        floatPos: {
            decimalPlaces: 2,
            minimumValue: '0'
        },
        id: Object.assign(Object.assign({}, autoNumericOptions.integerPos), { digitGroupSeparator: '' })
    },
    'en-GB': {
        currency: autoNumericOptions.British
    },
    'en-IE': {
        currency: autoNumericOptions.euro
    }
};
export default options;
