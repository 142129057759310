import { __awaiter } from "tslib";
import { PagedDataSetResponse } from '@/types/common';
import { FederatedSearchCustomer } from '@/types/domain';
export function createCustomerService(client) {
    const defaultExpand = [];
    return {
        federatedSearchCustomers(accountName, request, expand = defaultExpand) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/customer/federatedSearch`, request, {
                    params: {
                        expand: expand.join(',')
                    }
                });
                return new PagedDataSetResponse(FederatedSearchCustomer, response);
            });
        },
        searchCustomers(accountName, request) {
            return client.post(`${accountName}/customers/search`, request);
        },
        searchByIDs(accountName, request, expand = defaultExpand) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post(`${accountName}/customer/searchByIDs`, request, {
                    params: {
                        expand: expand.join(',')
                    }
                });
                return new PagedDataSetResponse(FederatedSearchCustomer, response);
            });
        },
        getCustomer(accountName, customerID, expand) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.get(`${accountName}/customers/${customerID}`, {
                    params: {
                        expand: expand.join(',')
                    }
                });
            });
        },
        getCustomerIDByCommonID(accountName, commonID) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.get(`${accountName}/customers/commonId/${commonID}`);
            });
        },
        getCustomerMembershipBenefits(accountName, customerID) {
            return client.get(`${accountName}/customers/${customerID}/customerMembershipBenefits`);
        },
        getCustomerSeries(accountName, customerID) {
            return client.get(`${accountName}/customers/${customerID}/customerSeries`);
        },
        createCustomer(accountName, request) {
            return client.post(`${accountName}/customers`, request);
        },
        updateCustomer(accountName, customerID, request) {
            return client.put(`${accountName}/customers/${customerID}`, request);
        },
        deleteCustomer(accountName, customerID) {
            return client.delete(`${accountName}/customers/${customerID}`);
        },
        addCustomerCreditCard(accountName, customerID, request) {
            return client.post(`${accountName}/customers/${customerID}/creditCards`, request);
        },
        removeCustomerCreditCard(accountName, customerID, customerCreditCardId) {
            return client.delete(`${accountName}/customers/${customerID}/creditCards/${customerCreditCardId}`);
        },
        setDefaultCreditCard(accountName, customerID, request) {
            return client.put(`${accountName}/customers/${customerID}/defaultCreditCard`, request);
        },
        uploadCustomerPhoto(accountName, customerID, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/customers/${customerID}/photo`, request);
            });
        },
        deleteCustomerPhoto(accountName, customerID) {
            return client.delete(`${accountName}/customers/${customerID}/photo`);
        },
        addCustomerTag(accountName, customerID, tagId) {
            return client.post(`${accountName}/customers/${customerID}/tag/${tagId}`);
        },
        removeCustomerTag(accountName, customerID, tagId) {
            return client.delete(`${accountName}/customers/${customerID}/tag/${tagId}`);
        },
        cancelCustomerMembership(accountName, customerMembershipId, request) {
            return client.post(`${accountName}/customers/memberships/${customerMembershipId}/cancel`, request);
        },
        getCustomerMessage(accountName, id) {
            return client.get(`${accountName}/customerMessage/${id}`);
        },
        searchCustomerMessages(accountName, request) {
            return client.post(`${accountName}/customerMessage/search`, request);
        },
        getCustomerDuplicateGroups(accountName, context, request) {
            const url = context === 'brand'
                ? `brand/${accountName}/customers/duplicates/groups`
                : `${accountName}/customers/duplicates/groups`;
            return client.post(url, request);
        },
        getCustomerDuplicateDetails(accountName, context, request) {
            const url = context === 'brand'
                ? `brand/${accountName}/customers/duplicates/details`
                : `${accountName}/customers/duplicates/details`;
            return client.post(url, request);
        },
        prepareCustomerDuplicatesMerge(accountName, context, request) {
            const url = context === 'brand'
                ? `brand/${accountName}/customers/duplicates/merge/prepare`
                : `${accountName}/customers/duplicates/merge/prepare`;
            return client.post(url, request);
        },
        commitCustomerDuplicatesMerge(accountName, context, request) {
            const url = context === 'brand'
                ? `brand/${accountName}/customers/duplicates/merge/commit`
                : `${accountName}/customers/duplicates/merge/commit`;
            return client.post(url, request);
        }
    };
}
