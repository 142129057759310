import { G1Btn, G1TextField, G1Textarea, G1Combobox, G1Autocomplete, G1InputContainer, G1ActionsMenu, G1NumericField, G1Select, G1CurrencyField, G1ValidationErrors, G1DatePickerField, G1TextOverflow, G1Avatar, G1Label, G1RouterView, G1DateTimePickerField, G1ExpandableRow } from '@/g1';
import AppPage from '@/components/app/AppPage.vue';
import AppDialog from '@/components/app/AppDialog.vue';
import G1Section from '@/g1/components/G1Section.vue';
import { GSelect, GButton, GDateField, GDatePicker, GTextField, GField, GIcon, GDateRangePicker, GDateRangeField, GErrorMessages, GAutocomplete, GCheckbox, GActionsButton, GTextarea, GCombobox } from '@/common';
const globalComponents = vue => {
    vue.component('G1ActionsMenu', G1ActionsMenu);
    vue.component('G1Autocomplete', G1Autocomplete);
    vue.component('G1Avatar', G1Avatar);
    vue.component('G1Btn', G1Btn);
    vue.component('G1Combobox', G1Combobox);
    vue.component('G1CurrencyField', G1CurrencyField);
    vue.component('G1DatePickerField', G1DatePickerField);
    vue.component('G1DateTimePickerField', G1DateTimePickerField);
    vue.component('G1ExpandableRow', G1ExpandableRow);
    vue.component('G1InputContainer', G1InputContainer);
    vue.component('G1Label', G1Label);
    vue.component('G1NumericField', G1NumericField);
    vue.component('G1RouterView', G1RouterView);
    vue.component('G1Section', G1Section);
    vue.component('G1Select', G1Select);
    vue.component('G1Textarea', G1Textarea);
    vue.component('G1TextField', G1TextField);
    vue.component('G1TextOverflow', G1TextOverflow);
    vue.component('G1ValidationErrors', G1ValidationErrors);
    vue.component('GIcon', GIcon);
    vue.component('GButton', GButton);
    vue.component('GField', GField);
    vue.component('GTextField', GTextField);
    vue.component('GTextarea', GTextarea);
    vue.component('GSelect', GSelect);
    vue.component('GAutocomplete', GAutocomplete);
    vue.component('GCombobox', GCombobox);
    vue.component('GDateField', GDateField);
    vue.component('GDateRangeField', GDateRangeField);
    vue.component('GDatePicker', GDatePicker);
    vue.component('GDateRangePicker', GDateRangePicker);
    vue.component('GErrorMessages', GErrorMessages);
    vue.component('GCheckbox', GCheckbox);
    vue.component('GActionsButton', GActionsButton);
    vue.component('AppPage', AppPage);
    vue.component('AppDialog', AppDialog);
};
export default globalComponents;
export { AppPage, AppDialog, G1Btn, G1TextField, G1Textarea, G1Combobox, G1TextOverflow, G1ActionsMenu, G1Label, G1RouterView, G1Autocomplete, G1InputContainer, G1NumericField, G1CurrencyField, G1Select, G1DatePickerField, G1ValidationErrors, G1Avatar, G1DateTimePickerField, GDateField, GDatePicker, G1ExpandableRow, G1Section, GButton, GTextField, GField, GIcon, GDateRangeField, GSelect, GDateRangePicker, GErrorMessages, GAutocomplete, GCheckbox, GActionsButton, GCombobox, GTextarea };
