export function createPackageService(client) {
    return {
        searchPackages(accountName, request) {
            return client.post(`${accountName}/packages/search`, request);
        },
        getPackageItem(accountName, packageItemID) {
            return client(`${accountName}/packages/items/${packageItemID}`);
        }
    };
}
