import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import { VTooltip, Resize } from 'vuetify/lib';
import { useVuetify } from '../composables';
export default defineComponent({
    name: 'G1TextOverflow',
    directives: {
        resize: Resize
    },
    props: {
        tooltipText: { type: String, default: undefined },
        contentClass: { type: [String, Array, Object], default: undefined },
        maxWidth: { type: String, default: '100%' },
        disabled: Boolean
    },
    setup(props) {
        const isTooltipDisabled = ref(true);
        const contentRef = ref(null);
        const { theme } = useVuetify();
        function checkTooltipVisibility() {
            if (props.disabled) {
                return;
            }
            setTimeout(() => {
                isTooltipDisabled.value = !hasOverflow();
            }, 400);
        }
        function hasOverflow() {
            return contentRef.value !== null && contentRef.value.offsetWidth < contentRef.value.scrollWidth;
        }
        onMounted(() => {
            checkTooltipVisibility();
        });
        onUpdated(() => {
            checkTooltipVisibility();
        });
        return {
            contentRef,
            isTooltipDisabled,
            theme,
            checkTooltipVisibility
        };
    },
    render(h) {
        var _a, _b;
        const text = (_b = (_a = this.$slots.default) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.text;
        const contentClass = ['g1-text-overflow__content', {
                'g1-text-overflow__content--disabled': 'disabled' in this.$props && this.$props.disabled
            }];
        if (Array.isArray(this.contentClass)) {
            contentClass.push(...this.contentClass);
        }
        else if (this.contentClass) {
            contentClass.push(this.contentClass);
        }
        return h(VTooltip, {
            props: {
                top: true,
                contentClass: 'g1-text-overflow__tooltip text--primary',
                color: this.theme.dark ? 'grey darken-3' : 'grey lighten-3',
                openDelay: 500,
                disabled: this.isTooltipDisabled
            },
            directives: [{
                    name: 'resize',
                    value: () => this.checkTooltipVisibility()
                }],
            scopedSlots: {
                default: () => { var _a; return ('tooltipText' in this.$props ? (_a = this.$props.tooltipText) !== null && _a !== void 0 ? _a : text : text); },
                activator: ({ on }) => h('div', {
                    ref: 'contentRef',
                    class: [contentClass],
                    style: { maxWidth: 'maxWidth' in this.$props ? this.$props.maxWidth : undefined },
                    on
                }, this.$slots.default)
            }
        }, []);
    }
});
