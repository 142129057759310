import { createNumberFormatService } from '../features';
const plugin = {
    install(vue, options) {
        if (options === undefined) {
            throw new Error('Provide number format global options');
        }
        vue.prototype.$numberFormat = createNumberFormatService(options);
        vue.prototype.$bindInput = vue.prototype.$numberFormat.bindInput;
        vue.prototype.$formatNumber = vue.prototype.$numberFormat.formatNumber;
        vue.prototype.$formatCurrency = vue.prototype.$numberFormat.formatCurrency;
    }
};
export default plugin;
