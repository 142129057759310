import { __awaiter } from "tslib";
import { computed, isRef, onMounted, ref, unref, watch } from 'vue';
import { watchDebounced } from '@vueuse/core';
import { isEqual } from 'lodash';
import { isOkPagedDataResponse } from '../types';
import { useI18n } from './useI18n';
export function useDataTable({ headers, filter, options, fetch }) {
    const { t } = useI18n();
    const dataTableHeaders = ref([]);
    watch(isRef(headers) ? headers : () => headers, () => {
        dataTableHeaders.value = convertToDataTableHeaders(unref(headers !== null && headers !== void 0 ? headers : []));
    }, { immediate: true });
    function convertToDataTableHeaders(tableHeaders) {
        function getHeaderText(h) {
            if (h.text !== undefined) {
                return h.text;
            }
            return h.localeKey === undefined
                ? ''
                : t(h.localeKey).toString();
        }
        return tableHeaders
            .filter(h => h.visible !== false)
            .map(h => (Object.assign(Object.assign({}, h), { text: getHeaderText(h) })));
    }
    const tableOptions = ref(Object.assign(Object.assign({}, getDefaultOptions()), unref(options)));
    watch(isRef(options) ? options : () => options, () => {
        tableOptions.value = Object.assign(Object.assign({}, getDefaultOptions()), unref(options));
    });
    const items = ref([]);
    const loading = ref(false);
    const serverItemsLength = ref(-1);
    const dataLoadDependency = ref();
    const searchRequest = computed(() => {
        var _a, _b;
        return ({
            pageIndex: ((_a = tableOptions.value.page) !== null && _a !== void 0 ? _a : 1) - 1,
            pageSize: tableOptions.value.itemsPerPage,
            sort: (_b = tableOptions.value.sortBy) === null || _b === void 0 ? void 0 : _b.map((sb, i) => {
                var _a;
                return ({
                    [sb]: ((_a = tableOptions.value.sortDesc) === null || _a === void 0 ? void 0 : _a[i]) ? 'desc' : 'asc'
                });
            }),
            filter: filter === null || filter === void 0 ? void 0 : filter.value
        });
    });
    const fetchQueue = [];
    function loadData() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (fetch === undefined) {
                return;
            }
            loading.value = true;
            try {
                const fetchPromise = fetch(searchRequest.value);
                fetchQueue.push(fetchPromise);
                const response = yield fetchPromise;
                if (fetchQueue.slice(-1)[0] !== fetchPromise) {
                    return;
                }
                if (!response.successful) {
                    items.value = [];
                    serverItemsLength.value = 0;
                    return;
                }
                if (isOkPagedDataResponse(response)) {
                    items.value = response.data.data;
                    serverItemsLength.value = (_a = response.data.paging.totalCount) !== null && _a !== void 0 ? _a : 0;
                }
                else if (Array.isArray(response.data)) {
                    items.value = response.data;
                    serverItemsLength.value = -1;
                }
            }
            finally {
                loading.value = false;
            }
        });
    }
    watch(filter !== null && filter !== void 0 ? filter : (() => {
    }), () => {
        tableOptions.value.page = 1;
    });
    onMounted(() => {
        watch(searchRequest, (newValue, oldValue) => {
            if (isEqual(newValue, oldValue)) {
                return;
            }
            loading.value = true;
        });
        watchDebounced([searchRequest, dataLoadDependency], (newValue, oldValue) => __awaiter(this, void 0, void 0, function* () {
            if (isEqual(newValue, oldValue)) {
                return;
            }
            yield loadData();
        }), { debounce: 100 });
    });
    return {
        headers: dataTableHeaders,
        options: tableOptions,
        loadData,
        items,
        loading,
        serverItemsLength,
        searchRequest,
        dataLoadDependency,
        tableProps: computed(() => ({
            footerProps: { itemsPerPageOptions: [10, 25, 100] }
        }))
    };
}
function getDefaultOptions() {
    return {
        page: 1
    };
}
