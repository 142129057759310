import Vue from 'vue';
export function getResponseErrorMessage(response) {
    if ('error' in response && response.error !== null) {
        if (typeof (response.error) === 'object' && 'message' in response.error && typeof (response.error.message) === 'string') {
            return response.error.message;
        }
        if (typeof (response.error) === 'string') {
            return response.error;
        }
    }
    return null;
}
function isFilterWithCondition(filter) {
    return '$or' in filter || '$and' in filter;
}
class SearchFilterBuilderImplementation {
    constructor() {
        Object.defineProperty(this, "currentFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
    }
    withFilter(filter) {
        if (!isFilterWithCondition(this.currentFilter)) {
            this.withCondition('$and');
        }
        if (!isFilterWithCondition(this.currentFilter)) {
            return this;
        }
        if (this.currentFilter.$or !== undefined) {
            this.currentFilter.$or.push(filter);
        }
        else if (this.currentFilter.$and !== undefined) {
            this.currentFilter.$and.push(filter);
        }
        return this;
    }
    withCondition(condition) {
        if (isFilterWithCondition(this.currentFilter) && condition in this.currentFilter) {
            return this;
        }
        this.currentFilter = {
            [condition]: [this.currentFilter]
        };
        return this;
    }
    withOperation(field, operation, value) {
        if (isFilterWithCondition(this.currentFilter)) {
            const newFilter = {
                [field]: {
                    [operation]: value
                }
            };
            if (this.currentFilter.$or !== undefined) {
                this.currentFilter.$or.push(newFilter);
            }
            else if (this.currentFilter.$and !== undefined) {
                this.currentFilter.$and.push(newFilter);
            }
            return this;
        }
        Vue.set(this.currentFilter, field, {
            [operation]: value
        });
        return this;
    }
    build() {
        return this.currentFilter;
    }
    or() {
        return this.withCondition('$or');
    }
    and() {
        return this.withCondition('$and');
    }
    filter(filter) {
        return this.withFilter(filter);
    }
    equals(field, value) {
        return this.withOperation(field, '$eq', value);
    }
    notEquals(field, value) {
        return this.withOperation(field, '$neq', value);
    }
    contains(field, value) {
        return this.withOperation(field, '$contains', value);
    }
    startsWith(field, value) {
        return this.withOperation(field, '$startsWith', value);
    }
    endsWith(field, value) {
        return this.withOperation(field, '$endsWith', value);
    }
    isNull(field) {
        return this.withOperation(field, '$isNull', true);
    }
    isNotNull(field) {
        return this.withOperation(field, '$isNull', false);
    }
    greaterThan(field, value) {
        return this.withOperation(field, '$gt', value);
    }
    greaterThanOrEqual(field, value) {
        return this.withOperation(field, '$gte', value);
    }
    lessThan(field, value) {
        return this.withOperation(field, '$lt', value);
    }
    lessThanOrEqual(field, value) {
        return this.withOperation(field, '$lte', value);
    }
    in(field, value) {
        return this.withOperation(field, '$in', value);
    }
    notIn(field, value) {
        return this.withOperation(field, '$notIn', value);
    }
}
export function createSearchFilter() {
    return new SearchFilterBuilderImplementation();
}
