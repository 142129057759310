export function createProductService(client) {
    return {
        searchProductVariants(accountName, request) {
            return client.post(`${accountName}/productVariants/search`, request);
        },
        getProductVariant(accountName, productVariantID) {
            return client.get(`${accountName}/productVariants/${productVariantID}`);
        },
        getProductVariantModifierGroups(accountName, productVariantID) {
            return client.get(`${accountName}/productVariants/${productVariantID}/modifierGroups`);
        },
        getProductLines(request) {
            return client.post('/smartPlay/productLines', request);
        },
        getProductLineItems(accountName, request) {
            return client.post(`${accountName}/smartPlay/productLineItems`, request);
        },
        saveProductLineItems(accountName, items) {
            return client.post(`${accountName}/smartPlay/productLineItemsSave`, items);
        }
    };
}
