export function createTierService(httpClient) {
    return {
        searchTiers(accountName, request, children = false, isBrandTiersRequestFromLocation = false) {
            const params = {};
            if (children) {
                params.expand = 'children';
            }
            const headers = {
                'allow-brand-tiers-searching-from-location': isBrandTiersRequestFromLocation
            };
            return httpClient.post(`${accountName}/tiers/search`, request, {
                params,
                headers
            });
        }
    };
}
