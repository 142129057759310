export function createPartnerService(client) {
    return {
        getPartner(partnerID) {
            return client.get(`partners/${partnerID}`);
        },
        searchPartners(request) {
            return client.post('partners/search', request);
        },
        createPartner(request) {
            return client.post('partners', request);
        },
        updatePartner(partnerID, request) {
            return client.put(`partners/${partnerID}`, request);
        },
        deletePartner(partnerID) {
            return client.delete(`partners/${partnerID}`);
        }
    };
}
