import { TransactionAuditStatus } from '@/types/transactionAudits';
import { Util } from '@/types/common';
export function createTransactionAuditService(client) {
    return {
        searchTransactionAudits(request) {
            return client.post('transactionAudit/search', request);
        },
        getTransactionAudit(id) {
            return client.get(`transactionAudit/${id}`);
        },
        updateTransactionAudit(id, request) {
            return client.put(`transactionAudit/${id}`, request);
        },
        resetTransactionAudits(ids) {
            return client.post('transactionAudit/resetTransactions', ids);
        },
        resetTransactionAudit(id) {
            return client.post(`transactionAudit/${id}/resetTransaction`);
        },
        retryTransactionAudits(ids) {
            return client.post('transactionAudit/retryTransactions', ids);
        },
        retryTransactionAudit(id) {
            return client.post(`transactionAudit/${id}/retryTransaction`);
        },
        getTransactionServiceNames() {
            return client.get('transactionAudit/serviceNames');
        },
        getTransactionServerNames() {
            return client.get('transactionAudit/serverNames');
        },
        getTransactionEventPublishers() {
            return client.get('transactionAudit/eventPublishers');
        },
        getTransactionStatuses() {
            return client.get('transactionAudit/statuses');
        },
        canRetry(transactionAudit) {
            var _a;
            if (transactionAudit == null) {
                return false;
            }
            const now = new Date();
            const offset = now.getTimezoneOffset() / 60;
            const modified = new Date(transactionAudit.dateLastModified);
            const diff = Math.abs(now.getTime() - modified.getTime()) / 3600000 - offset;
            const enumVal = Util.parseEnum(TransactionAuditStatus, (_a = transactionAudit.status) !== null && _a !== void 0 ? _a : '');
            switch (enumVal) {
                case TransactionAuditStatus.InProgress:
                    return diff > 1;
                case TransactionAuditStatus.Failed:
                    return true;
                case TransactionAuditStatus.Completed:
                    return false;
                default:
                    return false;
            }
        }
    };
}
