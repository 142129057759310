export function useBrowser() {
    let browser = null;
    const { userAgent } = navigator;
    if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
        browser = 'chrome';
    }
    else if (userAgent.includes('Firefox')) {
        browser = 'firefox';
    }
    else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
        browser = 'safari';
    }
    else if (userAgent.includes('Edge')) {
        browser = 'edge';
    }
    else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
        browser = 'ie';
    }
    else {
        browser = null;
    }
    return {
        browser
    };
}
