import { createDateMask } from '../features/dateMask';
const directive = {
    bind(el, binding) {
        const inputElement = findInputElement(el);
        if (inputElement === null) {
            return;
        }
        const mask = binding.value;
        if (mask === null || mask === undefined) {
            return;
        }
        inputElement._dateMask = createDateMask(inputElement, mask);
    },
    unbind(el) {
        const inputElement = findInputElement(el);
        if (inputElement === null) {
            return;
        }
        if (inputElement._dateMask === undefined) {
            return;
        }
        inputElement._dateMask.delete();
        delete inputElement._dateMask;
    }
};
function findInputElement(el) {
    if (el.tagName === 'INPUT') {
        return el;
    }
    return el.querySelector('input');
}
export default directive;
