import { upperFirst } from 'lodash';
export var NavigationMode;
(function (NavigationMode) {
    NavigationMode["Default"] = "default";
    NavigationMode["Collapsed"] = "collapsed";
})(NavigationMode || (NavigationMode = {}));
export class ValidationError {
    constructor() {
        Object.defineProperty(this, "message", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.message = data.message;
    }
}
export var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["Unknown"] = 0] = "Unknown";
    ErrorCode[ErrorCode["InternalServerError"] = 1] = "InternalServerError";
    ErrorCode[ErrorCode["BadRequest"] = 2] = "BadRequest";
    ErrorCode[ErrorCode["ValidationError"] = 3] = "ValidationError";
    ErrorCode[ErrorCode["NotFound"] = 4] = "NotFound";
    ErrorCode[ErrorCode["Unauthorized"] = 5] = "Unauthorized";
})(ErrorCode || (ErrorCode = {}));
export class ErrorInfo {
    constructor() {
        Object.defineProperty(this, "message", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "validationErrors", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    mapFrom(data) {
        this.message = data.message;
        this.code = data.code;
        const validationErrorCollection = data.validationErrors;
        if (validationErrorCollection != null) {
            if (validationErrorCollection instanceof Map) {
                this.validationErrors = validationErrorCollection;
            }
            else {
                this.validationErrors = new Map();
                Object.keys(validationErrorCollection).forEach(fieldName => {
                    const errorCollection = Util.mapArray(validationErrorCollection[fieldName].errors, ValidationError);
                    this.validationErrors.set(fieldName, errorCollection);
                });
            }
        }
    }
}
export class ApiResponse {
    constructor(initialData) {
        Object.defineProperty(this, "successful", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        if (initialData != null) {
            this.mapFrom(initialData);
        }
    }
    mapFrom(data) {
        this.successful = data.successful;
        this.error = Util.mapObject(data.error, ErrorInfo);
    }
}
export class DataResponse extends ApiResponse {
    constructor(type, initialData) {
        super();
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.type = type;
        if (initialData != null) {
            this.mapFrom(initialData);
        }
    }
    mapFrom(data) {
        super.mapFrom(data);
        const dataObj = data.data;
        if (dataObj != null) {
            this.data = new this.type();
            this.data.mapFrom(dataObj);
        }
    }
}
export class PagedDataSetResponse extends ApiResponse {
    constructor(type, initialData) {
        super();
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paging", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.type = type;
        if (initialData != null) {
            this.mapFrom(initialData);
        }
    }
    mapFrom(data) {
        super.mapFrom(data);
        const pagedDataSet = data.data;
        if (pagedDataSet != null) {
            const dataObjArray = pagedDataSet.data;
            if (dataObjArray != null) {
                this.data = new Array();
                dataObjArray.forEach(dataObj => {
                    const objInst = new this.type();
                    objInst.mapFrom(dataObj);
                    this.data.push(objInst);
                });
            }
            const pageInfo = pagedDataSet.paging;
            if (pageInfo != null) {
                this.paging = new PageInfo();
                this.paging.mapFrom(pageInfo);
            }
        }
    }
}
export class PagedDataRequest {
    constructor() {
        Object.defineProperty(this, "paging", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.paging = new PageInfo();
    }
}
export class PageInfo {
    constructor() {
        Object.defineProperty(this, "pageSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pageIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "totalCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.pageSize = 10;
        this.pageIndex = 1;
        this.totalCount = null;
    }
    mapFrom(data) {
        this.pageSize = data.pageSize;
        this.pageIndex = data.pageIndex;
        this.totalCount = data.totalCount;
    }
}
export class DataGridOptions {
    constructor() {
        Object.defineProperty(this, "page", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "itemsPerPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sortBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sortDesc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "groupBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "groupDesc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "multiSort", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mustSort", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
export var FilterOperation;
(function (FilterOperation) {
    FilterOperation["And"] = "$and";
    FilterOperation["Or"] = "$or";
    FilterOperation["Equal"] = "$eq";
    FilterOperation["NotEqual"] = "$neq";
    FilterOperation["GreaterThan"] = "$gt";
    FilterOperation["GreaterThanOrEqual"] = "$gte";
    FilterOperation["LessThan"] = "$lt";
    FilterOperation["LessThanOrEqual"] = "$lte";
    FilterOperation["In"] = "$in";
    FilterOperation["Between"] = "$between";
    FilterOperation["Contains"] = "$contains";
    FilterOperation["IsNull"] = "$isNull";
})(FilterOperation || (FilterOperation = {}));
export class Filter {
}
export class Util {
    static mapArray(source, TCreator) {
        if (source == null) {
            return null;
        }
        const mapped = new Array();
        if (source == null) {
            return mapped;
        }
        for (let i = 0; i < source.length; i++) {
            mapped.push(Util.mapObject(source[i], TCreator));
        }
        return mapped;
    }
    static mapObject(source, TCreator) {
        if (source == null) {
            return null;
        }
        const inst = new TCreator();
        inst.mapFrom(source);
        return inst;
    }
    static parseDate(dateString) {
        if ((dateString == null) || (dateString === '')) {
            return null;
        }
        return new Date(dateString);
    }
    static parseLocalDate(dateString) {
        if ((dateString == null) || (dateString === '')) {
            return null;
        }
        return new Date(dateString.slice(0, -1));
    }
    static parseEnum(type, enumValue) {
        const enumNumber = enumValue;
        if (Number.isNaN(Number(enumNumber))) {
            const enumName = upperFirst(enumValue.toString().trim());
            return type[enumName];
        }
        return enumNumber;
    }
    static parseFlagEnum(type, enumValue) {
        const enumNumber = enumValue;
        if (Number.isNaN(Number(enumNumber))) {
            const enumNames = enumValue.toString().split(',');
            return enumNames.map(x => Util.parseEnum(type, x));
        }
        return [enumNumber];
    }
    static flagEnumString(type, enumValues) {
        const flagString = enumValues.map(x => {
            const enumVal = Util.parseEnum(type, x);
            return type[enumVal];
        });
        return flagString.join(',');
    }
    static checkFlagEnumValue(type, enumValue, expected) {
        return enumValue == null ? false : enumValue.some(x => x === Util.parseEnum(type, expected));
    }
}
