import { getCurrentInstance, ref, watch } from 'vue';
export function defineModel(props, defaultValue) {
    var _a;
    const vue = (_a = getCurrentInstance()) === null || _a === void 0 ? void 0 : _a.proxy;
    if (vue === undefined) {
        throw new Error('defineModel must be called within the setup function');
    }
    const value = ref(defaultValue);
    watch(() => props.modelValue, v => {
        value.value = v !== null && v !== void 0 ? v : defaultValue;
    }, { immediate: true });
    watch(value, v => {
        vue.$emit('update:modelValue', v);
    });
    return value;
}
