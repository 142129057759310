import { __awaiter } from "tslib";
export function createDisplayService(client) {
    return {
        searchDisplays(accountName, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/displays/search`, request);
            });
        },
        updateDisplay(accountName, displayId, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.put(`${accountName}/displays/${displayId}`, request);
            });
        },
        claimDisplay(accountName, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/displays/claim`, request);
            });
        },
        reclaimDisplay(accountName, displayId, request) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/displays/${displayId}/claim`, request);
            });
        },
        disconnectDisplay(accountName, displayId) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/displays/${displayId}/disconnect`);
            });
        },
        getUserToken(accountName, displayId) {
            return __awaiter(this, void 0, void 0, function* () {
                return client.post(`${accountName}/displays/${displayId}/token/user`);
            });
        }
    };
}
