import { __awaiter } from "tslib";
import VueRouter from 'vue-router';
import { usePermissions, useDialogs } from '@/g1';
import { useAppStore, useAuthStore } from '@/store';
import routes from './routes';
const router = new VueRouter({
    routes,
    base: 'app/',
    mode: 'history'
});
export function initRouter(pinia) {
    router.beforeEach((to, from, next) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const authStore = useAuthStore();
        const appStore = useAppStore(pinia);
        const { hasAccessToRoute } = usePermissions();
        const { openPopup } = useDialogs();
        function tryLogin() {
            return __awaiter(this, void 0, void 0, function* () {
                if (authStore.isAuthenticated) {
                    return;
                }
                yield appStore.doWhileLoading(authStore.authenticate());
            });
        }
        function tryLoadPermissions(route) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                if (!authStore.isAuthorized) {
                    yield appStore.doWhileLoading(authStore.authorize());
                }
                else if (!((_a = route.meta) === null || _a === void 0 ? void 0 : _a.mustProvideCredentials) && ((_b = route.meta) === null || _b === void 0 ? void 0 : _b.canPermissionsBeGranted) && hasAccessToRoute(route)) {
                    yield authStore.authorize();
                }
            });
        }
        function checkAccessToRoute(route) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                if (((_a = route.meta) === null || _a === void 0 ? void 0 : _a.mustProvideCredentials) || !hasAccessToRoute(route)) {
                    if (((_b = route.meta) === null || _b === void 0 ? void 0 : _b.mustProvideCredentials) || ((_c = route.meta) === null || _c === void 0 ? void 0 : _c.canPermissionsBeGranted)) {
                        const isSuccessful = yield (openPopup({
                            component: () => import('@/pages/GrantPermissionsDialog.vue')
                        }, Object.assign(Object.assign({}, route.meta.grantPermissionsDialogAttrs), { accountName: route.params.account }))).getValue();
                        return isSuccessful === true;
                    }
                    return false;
                }
                return true;
            });
        }
        if ((_a = to.meta) === null || _a === void 0 ? void 0 : _a.anonymous) {
            next();
            return;
        }
        yield tryLogin();
        if (!authStore.isAuthenticated) {
            next({ name: 'login' });
            return;
        }
        yield tryLoadPermissions(to);
        if (yield checkAccessToRoute(to)) {
            next();
            return;
        }
        if (from.fullPath === '/') {
            next({ name: 'notFound' });
        }
    }));
}
export default router;
