import { format } from 'date-fns';
const delimiter = '/';
export function createDateMask(input, mask) {
    input.addEventListener('keydown', onKeyDown);
    input.addEventListener('paste', onPaste);
    input.addEventListener('dragover', onDragOver);
    input.addEventListener('drop', onDrop);
    const parts = getParts(mask);
    function onKeyDown(event) {
        var _a;
        const { key } = event;
        if (key !== backspaceKey && key !== deleteKey && isFunctionKey(event)) {
            return;
        }
        const cursorPosition = (_a = input.selectionStart) !== null && _a !== void 0 ? _a : 0;
        if (key === backspaceKey) {
            if (cursorPosition > 0 && input.value[cursorPosition - 1] === delimiter) {
                event.preventDefault();
                input.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
                return;
            }
        }
        if (key === deleteKey) {
            if (cursorPosition < input.value.length && input.value[cursorPosition] === delimiter) {
                event.preventDefault();
                input.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
                return;
            }
        }
        if (!isDigit(key) && key !== backspaceKey && key !== deleteKey) {
            event.preventDefault();
            return;
        }
        const value = getFutureValue(event);
        if (value.length > mask.length) {
            event.preventDefault();
            return;
        }
        const dateParts = parseDateParts(value, parts);
        if (!isValidDate(dateParts)) {
            event.preventDefault();
            return;
        }
        appendDelimiterIfNeeded(event, input, mask, value);
    }
    function onPaste(event) {
        var _a, _b, _c, _d;
        event.preventDefault();
        const pastedData = (_b = (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text')) !== null && _b !== void 0 ? _b : '';
        const newValue = input.value.slice(0, (_c = input.selectionStart) !== null && _c !== void 0 ? _c : 0)
            + pastedData
            + input.value.slice((_d = input.selectionEnd) !== null && _d !== void 0 ? _d : 0);
        const date = new Date(newValue);
        if (Number.isNaN(date.getTime())) {
            return;
        }
        input.value = format(date, mask);
        input.dispatchEvent(new Event('input'));
    }
    function onDragOver(event) {
        event.preventDefault();
    }
    function onDrop(event) {
        event.preventDefault();
    }
    return {
        delete() {
            input.removeEventListener('keydown', onKeyDown);
            input.removeEventListener('paste', onPaste);
            input.removeEventListener('dragover', onDragOver);
            input.removeEventListener('drop', onDrop);
        }
    };
}
function appendDelimiterIfNeeded(event, input, mask, value) {
    var _a;
    let shouldAppendDelimiter = false;
    const cursorPosition = (_a = input.selectionStart) !== null && _a !== void 0 ? _a : 0;
    if (cursorPosition === 1 || cursorPosition === 4) {
        if (value[cursorPosition] !== delimiter && value[cursorPosition + 1] !== delimiter && value[cursorPosition - 1] !== delimiter) {
            shouldAppendDelimiter = true;
        }
    }
    if (shouldAppendDelimiter) {
        input.value = value.slice(0, cursorPosition + 1) + delimiter + value.slice(cursorPosition + 1);
        input.dispatchEvent(new Event('input'));
        event.preventDefault();
    }
}
function parseDateParts(value, parts) {
    const dateParts = {
        month: '',
        day: '',
        year: ''
    };
    const valueParts = value.split(delimiter);
    for (let i = 0; i < valueParts.length; i++) {
        const valuePart = valueParts[i];
        const maskPart = parts[i];
        switch (maskPart) {
            case 'month':
                dateParts.month = valuePart;
                break;
            case 'day':
                dateParts.day = valuePart;
                break;
            case 'year':
                dateParts.year = valuePart;
                break;
            default:
                break;
        }
    }
    return dateParts;
}
function isValidDate(dateParts) {
    const { day, month, year } = dateParts;
    if (day.length > 0) {
        if (day.length > 2) {
            return false;
        }
        if (day.length === 1 && Number(day) > 3) {
            return false;
        }
        if (day.length === 2 && (Number(day) < 1 || Number(day) > 31)) {
            return false;
        }
    }
    if (month.length > 0) {
        if (month.length > 2) {
            return false;
        }
        if (month.length === 1 && Number(month) > 1) {
            return false;
        }
        if (month.length === 2 && (Number(month) < 1 || Number(month) > 12)) {
            return false;
        }
    }
    if (year.length > 0) {
        if (year.length > 4) {
            return false;
        }
        if (year.length === 1 && (Number(year) < 1 || Number(year) > 2)) {
            return false;
        }
        if (year.length === 2 && (Number(year) < 19 || Number(year) > 20)) {
            return false;
        }
        if (year.length === 3 && (Number(year) < 190 || Number(year) > 209)) {
            return false;
        }
        if (year.length === 4 && (Number(year) < 1900 || Number(year) > 2099)) {
            return false;
        }
    }
    return true;
}
function isDigit(char) {
    return /^\d$/.test(char);
}
function getFutureValue(event) {
    var _a, _b;
    const input = event.target;
    const cursorStart = (_a = input.selectionStart) !== null && _a !== void 0 ? _a : 0;
    const cursorEnd = (_b = input.selectionEnd) !== null && _b !== void 0 ? _b : cursorStart;
    const { value } = input;
    if (event.key === backspaceKey) {
        return value.slice(0, cursorStart - (cursorStart === cursorEnd ? 1 : 0)) + value.slice(cursorEnd);
    }
    if (event.key === deleteKey) {
        return value.slice(0, cursorStart) + value.slice(cursorEnd + (cursorStart === cursorEnd ? 1 : 0));
    }
    return value.slice(0, cursorStart) + event.key + value.slice(cursorEnd);
}
const backspaceKey = 'Backspace';
const deleteKey = 'Delete';
function isFunctionKey(event) {
    const functionKeys = [
        'Tab', 'Enter', 'Escape', backspaceKey, deleteKey,
        'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'
    ];
    return (functionKeys.includes(event.key)
        || event.key.startsWith('F')
        || event.metaKey
        || event.ctrlKey
        || event.altKey);
}
function getParts(mask) {
    switch (mask) {
        case 'MM/dd/yyyy':
            return [
                'month',
                'day',
                'year'
            ];
        case 'dd/MM/yyyy':
            return [
                'day',
                'month',
                'year'
            ];
        default:
            throw new Error('Invalid mask');
    }
}
