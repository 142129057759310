export function createTagService(client) {
    return {
        getTags(accountName, request, expand) {
            const urlParams = new URLSearchParams();
            if (Array.isArray(expand)) {
                urlParams.append('expand', expand.join(','));
            }
            return client.post(`${accountName}/tags/search?${urlParams}`, request);
        }
    };
}
