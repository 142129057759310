export function createBackgroundTaskLogService(client) {
    return {
        getBackgroundTaskLog(id) {
            return client.get(`backgroundTaskLog/${id}`);
        },
        searchBackgroundTaskLogs(request) {
            return client.post('backgroundTaskLog/search', request);
        }
    };
}
