import Vue from 'vue';
import Vuex from 'vuex';
import { getModule } from 'vuex-module-decorators';
import { createPinia } from 'pinia';
import DuplicateModule from './modules/duplicates';
import AuthStore from './modules/authStore';
export * from './modules/appStore';
export * from './modules/notificationStore';
export * from './modules/locationStore';
export * from './modules/transactionStatusStore';
export const pinia = createPinia();
Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        auth: AuthStore,
        duplicates: DuplicateModule
    }
});
export default store;
export function useAuthStore() {
    return getModule(AuthStore, store);
}
export function useDuplicatesStore() {
    return getModule(DuplicateModule, store);
}
