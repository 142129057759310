import { __awaiter } from "tslib";
import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { merge } from 'lodash';
import { g1NumberFormat } from '@/g1';
import messages from '@/locale/global/messages';
import commonMessages from '@/common/locale/common.json';
import { numberFormats, currencyFormats } from '@/locale/global/numberFormats';
import dateTimeFormats from '@/locale/global/dateTimeFormats';
import autoNumericOptions from '@/locale/global/autoNumeric';
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    messages: merge(commonMessages, messages),
    dateTimeFormats,
    silentFallbackWarn: true
});
const loadedLanguages = ['en-US'];
export function loadLocaleMessages(locale) {
    return __awaiter(this, void 0, void 0, function* () {
        if (i18n.locale === locale || loadedLanguages.includes(locale)) {
            setLocale(locale);
            return;
        }
        try {
            const loadedMessages = yield fetchLocaleMessages(locale);
            i18n.setLocaleMessage(locale, loadedMessages);
            loadedLanguages.push(locale);
            setLocale(locale);
        }
        catch (error) {
        }
        finally {
            loadedLanguages.push(locale);
            setLocale(locale);
        }
    });
}
function fetchLocaleMessages(locale) {
    return __awaiter(this, void 0, void 0, function* () {
        return (yield import(`@/locale/global/messages.${locale}.json`)).default;
    });
}
function setLocale(locale) {
    var _a;
    i18n.locale = locale;
    (_a = document.querySelector('html')) === null || _a === void 0 ? void 0 : _a.setAttribute('lang', locale);
}
Vue.use(g1NumberFormat, {
    numberFormats,
    fallbackLocale: 'en-US',
    autoNumericOptions,
    currencyFormats
});
export default i18n;
