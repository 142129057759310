export function createCreditBookService(client) {
    return {
        searchCreditBooks(accountName, request) {
            return client.post(`${accountName}/creditBooks/search`, request);
        },
        getCreditBook(accountName, id) {
            return client.get(`${accountName}/creditBooks/${id}`);
        },
        createCreditBook(accountName, request) {
            return client.post(`${accountName}/creditBooks`, request);
        },
        updateCreditBook(accountName, id, request) {
            return client.put(`${accountName}/creditBooks/${id}`, request);
        },
        removeCreditBook(accountName, id) {
            return client.delete(`${accountName}/creditBooks/${id}`);
        },
        distributeCreditBook(accountName, id, request) {
            return client.post(`${accountName}/creditBooks/${id}/distribute`, request);
        },
        expireCreditBook(accountName, id, request) {
            return client.post(`${accountName}/creditBooks/${id}/expire`, request);
        },
        searchCreditBookHistory(accountName, id, request) {
            return client.post(`${accountName}/creditBooks/${id}/history/search`, request);
        },
        getExpirationSettings(accountName) {
            return client.get(`${accountName}/creditBookExpirationSettings`);
        },
        createExpirationSettings(accountName, request) {
            return client.post(`${accountName}/creditBookExpirationSettings`, request);
        },
        updateExpirationSettings(accountName, request) {
            return client.put(`${accountName}/creditBookExpirationSettings`, request);
        }
    };
}
