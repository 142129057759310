import Sortable from "sortablejs";
const SortableTable = {
    bind(el, binding, vnode) {
        const options = {
            animation: 150,
            onUpdate: function (event) {
                vnode.child.$emit("sorted", event);
            }
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
    },
};
export default SortableTable;
