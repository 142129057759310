export function getTimeZoneOffsetFromLocal(date, timeZone) {
    date = new Date(date);
    date.setMilliseconds(0);
    const timeZoneTime = new Date(date.toLocaleString('en-US', { timeZone })).getTime();
    const localTime = date.getTime();
    return (localTime - timeZoneTime) / 1000 / 60;
}
export function convertToLocalTimeZone(date, timeZoneFrom) {
    date = new Date(date);
    date.setMinutes(date.getMinutes() + getTimeZoneOffsetFromLocal(date, timeZoneFrom));
    return date;
}
export function convertDateOnlyToISOString(dateOnly, timeZoneFrom) {
    const midnight = new Date(`${dateOnly.slice(0, 10)}T00:00:00`);
    return timeZoneFrom === undefined
        ? midnight.toISOString()
        : convertToLocalTimeZone(midnight, timeZoneFrom).toISOString();
}
export function extendToTheEndOfTheDay(dateOnly, timeZoneFrom) {
    const almostMidnight = new Date(`${dateOnly.slice(0, 10)}T23:59:59`);
    return timeZoneFrom === undefined
        ? almostMidnight.toISOString()
        : convertToLocalTimeZone(almostMidnight, timeZoneFrom).toISOString();
}
export function convertToDateOnlyString(date, timeZoneTo) {
    const targetDate = new Date(new Date(date).toLocaleString('en-US', { timeZone: timeZoneTo }));
    const year = targetDate.getFullYear();
    const month = (targetDate.getMonth() + 1).toString().padStart(2, '0');
    const dateDate = targetDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${dateDate}`;
}
export function convertToTimeZone(date, timeZoneTo, timeZoneFrom) {
    date = new Date(date);
    const offsetTo = getTimeZoneOffsetFromLocal(date, timeZoneTo);
    let offset;
    if (timeZoneFrom === undefined) {
        offset = -offsetTo;
    }
    else {
        const offsetFrom = getTimeZoneOffsetFromLocal(date, timeZoneFrom);
        offset = offsetFrom - offsetTo;
    }
    date.setMinutes(date.getMinutes() + offset);
    return date;
}
