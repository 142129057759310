export function createReportsListService(client) {
    return {
        searchReports(context, accountName, query) {
            if (context === 'location') {
                return client.post(`/report/${accountName}/search`, { type: 'spa', name: query });
            }
            if (context === 'brand') {
                return client.post(`/report/brand/${accountName}/search`, { type: 'brand', name: query });
            }
            return client.post('/report/search', { type: 'admin', name: query });
        }
    };
}
