export var TeeSheetAccountType;
(function (TeeSheetAccountType) {
    TeeSheetAccountType["Standard"] = "standard";
    TeeSheetAccountType["Responsive"] = "responsive";
})(TeeSheetAccountType || (TeeSheetAccountType = {}));
export var TeeSheetBookingStatus;
(function (TeeSheetBookingStatus) {
    TeeSheetBookingStatus["Active"] = "active";
    TeeSheetBookingStatus["Cancelled"] = "cancelled";
    TeeSheetBookingStatus["Paid"] = "paid";
    TeeSheetBookingStatus["Refunded"] = "refunded";
})(TeeSheetBookingStatus || (TeeSheetBookingStatus = {}));
