import { getCurrentInstance } from 'vue';
export function useI18n() {
    var _a;
    const app = (_a = getCurrentInstance()) === null || _a === void 0 ? void 0 : _a.proxy;
    if (app === undefined) {
        throw Error('useI18n can only be used in setup function');
    }
    return {
        t: app.$t.bind(app),
        d: app.$d.bind(app),
        n: app.$n.bind(app),
        tc: app.$tc.bind(app),
        i18n: app.$i18n
    };
}
