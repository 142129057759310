export function createQuickBooksSettingsService(client) {
    return {
        getQuickBooksSettings(accountName, settingsID) {
            return client.get(`${accountName}/quickbooks/settings/${settingsID}`);
        },
        createQuickBooksSettings(accountName, request) {
            return client.post(`${accountName}/quickbooks/settings`, request);
        },
        updateQuickBooksSettings(accountName, settingsID, request) {
            return client.put(`${accountName}/quickbooks/settings/${settingsID}`, request);
        },
        testQuickBooksSettings(accountName, settingsID) {
            return client.get(`${accountName}/quickbooks/settings/${settingsID}/test`);
        },
        getTransparentRedirectUrl(accountName, settingsID) {
            return client.get(`${accountName}/quickbooks/settings/${settingsID}/transparentRedirectUrl`);
        },
        disconnectFromQuickBooks(accountName, settingsID) {
            return client.post(`${accountName}/quickbooks/settings/${settingsID}/disconnect`);
        },
        searchQuickBooksCustomers(accountName, settingsID, request) {
            return client.post(`${accountName}/quickbooks/settings/${settingsID}/federatedSearchCustomers`, request);
        }
    };
}
