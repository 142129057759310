import { __awaiter } from "tslib";
import { HttpStatusCode } from '@/g1';
export function createReportScheduleService(client) {
    return {
        createSubscription(request) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.post('reports/subscriptions', request);
                if (response.status === HttpStatusCode.Ok) {
                    return true;
                }
                if (response.status === HttpStatusCode.BadRequest) {
                    return false;
                }
                return response.data.message;
            });
        },
        searchReportSchedule(entityOwnerID, searchRequest) {
            return __awaiter(this, void 0, void 0, function* () {
                const params = new URLSearchParams();
                if (searchRequest) {
                    params.append('searchRequest', searchRequest);
                }
                const response = yield client.get(`/reports/schedule/subscriptions/${entityOwnerID}?${params.toString()}`);
                if (response.status === HttpStatusCode.Ok) {
                    return Object.assign(Object.assign({}, response), { successful: true });
                }
                throw new Error('Failed to fetch report schedule');
            });
        },
        getSubscriptionProperties(subscriptionId) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.get(`reports/subscriptions/${subscriptionId}`);
                const { data } = response;
                return {
                    owner: data.owner,
                    description: data.description,
                    status: data.status,
                    eventType: data.eventType,
                    extensionSettings: data.extensionSettings,
                    parameterValues: data.parameterValues,
                    matchData: data.matchData
                };
            });
        },
        deleteSubscription(subscriptionId, userID) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield client.delete(`reports/subscriptions/${subscriptionId}?userID=${userID}`);
                if (response.status === HttpStatusCode.Ok) {
                    return Object.assign(Object.assign({}, response), { successful: true });
                }
                throw new Error('Failed to delete report schedule');
            });
        }
    };
}
