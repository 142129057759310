import { inject, provide, ref } from 'vue';
import { createLocaleSettings } from './localeSettings';
export const currentLocaleKey = Symbol('g-current-locale');
const currentLocale = ref(null);
export function useCurrentLocaleProvider() {
    if (inject(currentLocaleKey, null) === null) {
        provide(currentLocaleKey, currentLocale);
    }
    return {
        provideCurrentLocale(locale) {
            currentLocale.value = createLocaleSettings(locale);
        }
    };
}
