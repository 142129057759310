export function createSecurityService(client) {
    return {
        getGroup(id) {
            return client.get(`security/groups/${id}`);
        },
        searchGroups(request) {
            return client.post('security/groups/search', request);
        },
        createGroup(request) {
            return client.post('security/groups', request);
        },
        updateGroup(id, request) {
            return client.put(`security/groups/${id}`, request);
        },
        deleteGroup(id) {
            return client.delete(`security/groups/${id}`);
        },
        searchGroupUpdateEvents(request) {
            return client.post('security/groups/updateEvents/search', request);
        },
        getLegacyAccess(id) {
            return client.get(`security/legacyAccesses/${id}`);
        },
        searchLegacyAccess(request) {
            return client.post('security/legacyAccesses/search', request);
        },
        updateLegacyAccess(id, request) {
            return client.put(`security/legacyAccesses/${id}`, request);
        },
        getLegacyPermission(id) {
            return client.get(`security/legacyPermissions/${id}`);
        },
        searchLegacyPermissions(request) {
            return client.post('security/legacyPermissions/search', request);
        },
        updateLegacyPermission(id, request) {
            return client.put(`security/legacyPermissions/${id}`, request);
        },
        getUserAccessSets() {
            return client.get('security/userAccessSets');
        }
    };
}
