const routes = [
    {
        name: 'system.maintenance.eventLogs',
        path: 'maintenance/eventLogs',
        component: () => import('@/pages/System/Maintenance/searchEventLogs.vue')
    },
    {
        name: 'system.maintenance.eventLog.view',
        path: 'maintenance/eventLog/:id',
        component: () => import('@/pages/System/Maintenance/editEventLogEntry.vue')
    },
    {
        name: 'system.maintenance.spaEventLogs',
        path: 'maintenance/spaEventLogs',
        component: () => import('@/pages/System/Maintenance/searchSpaEditEventLogs.vue')
    },
    {
        name: 'system.teeSheet.securityGroupDialog',
        path: 'locationSettings/teeSheet',
        component: () => import('@/pages/Location/Settings/TeeSheet/SecurityGroupDialogPage.vue')
    }
];
export default routes;
