import { convertToDateOnlyString, convertToLocalTimeZone, convertToTimeZone, useCurrentDate, useI18n } from '@/g1';
import { useCurrentLocale } from '@/common';
export const todayAnchor = 'today';
export const yesterdayAnchor = 'yesterday';
export const last7DaysAnchor = 'last7Days';
export const thisWeekAnchor = 'thisWeek';
export const thisMonthAnchor = 'thisMonth';
export function useDateShortcuts() {
    const { t } = useI18n();
    const { timeZone } = useCurrentDate();
    const { firstDayOfWeek } = useCurrentLocale();
    function getLocationNow() {
        return convertToTimeZone(new Date(), timeZone);
    }
    function getTodayDateRange() {
        const date = new Date();
        return [convertToDateOnlyString(date, timeZone), convertToDateOnlyString(date, timeZone)];
    }
    function getYesterdayDateRange() {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return [convertToDateOnlyString(date, timeZone), convertToDateOnlyString(date, timeZone)];
    }
    function getLast7DaysDateRange() {
        const today = new Date();
        const startOfLast7Days = new Date(today);
        startOfLast7Days.setDate(startOfLast7Days.getDate() - 7);
        return [convertToDateOnlyString(startOfLast7Days, timeZone), convertToDateOnlyString(today, timeZone)];
    }
    function getThisWeekDateRange() {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const daysToSubtract = (dayOfWeek - firstDayOfWeek + 7) % 7;
        const startOfWeek = new Date(today);
        startOfWeek.setDate(startOfWeek.getDate() - daysToSubtract);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);
        return [convertToDateOnlyString(startOfWeek, timeZone), convertToDateOnlyString(endOfWeek, timeZone)];
    }
    function getThisMonthDateRange() {
        const today = getLocationNow();
        const startOfMonth = new Date(today);
        startOfMonth.setDate(1);
        const endOfMonth = new Date(startOfMonth);
        endOfMonth.setMonth(endOfMonth.getMonth() + 1);
        endOfMonth.setDate(0);
        return [
            convertToDateOnlyString(convertToLocalTimeZone(startOfMonth, timeZone), timeZone),
            convertToDateOnlyString(convertToLocalTimeZone(endOfMonth, timeZone), timeZone)
        ];
    }
    function getThisYearDateRange() {
        const today = getLocationNow();
        const startOfYear = new Date(today);
        startOfYear.setMonth(0, 1);
        const endOfLastYear = new Date(startOfYear);
        endOfLastYear.setFullYear(endOfLastYear.getFullYear() + 1);
        endOfLastYear.setDate(endOfLastYear.getDate() - 1);
        return [
            convertToDateOnlyString(convertToLocalTimeZone(startOfYear, timeZone), timeZone),
            convertToDateOnlyString(convertToLocalTimeZone(endOfLastYear, timeZone), timeZone)
        ];
    }
    function getLastWeekDateRange() {
        const todayLastWeek = new Date();
        todayLastWeek.setDate(todayLastWeek.getDate() - 7);
        const dayOfWeek = todayLastWeek.getDay();
        const daysToSubtract = (dayOfWeek - firstDayOfWeek + 7) % 7;
        const startOfLastWeek = new Date(todayLastWeek);
        startOfLastWeek.setDate(startOfLastWeek.getDate() - daysToSubtract);
        const endOfLastWeek = new Date(startOfLastWeek);
        endOfLastWeek.setDate(endOfLastWeek.getDate() + 6);
        return [convertToDateOnlyString(startOfLastWeek, timeZone), convertToDateOnlyString(endOfLastWeek, timeZone)];
    }
    function getLastMonthDateRange() {
        const today = getLocationNow();
        const startOfMonth = new Date(today);
        startOfMonth.setMonth(startOfMonth.getMonth() - 1);
        startOfMonth.setDate(1);
        const endOfMonth = new Date(startOfMonth);
        endOfMonth.setMonth(endOfMonth.getMonth() + 1);
        endOfMonth.setDate(0);
        return [
            convertToDateOnlyString(convertToLocalTimeZone(startOfMonth, timeZone), timeZone),
            convertToDateOnlyString(convertToLocalTimeZone(endOfMonth, timeZone), timeZone)
        ];
    }
    function getLastYearDateRange() {
        const today = getLocationNow();
        const startOfYear = new Date(today);
        startOfYear.setMonth(0, 1);
        startOfYear.setFullYear(startOfYear.getFullYear() - 1);
        const endOfLastYear = new Date(startOfYear);
        endOfLastYear.setFullYear(endOfLastYear.getFullYear() + 1);
        endOfLastYear.setDate(endOfLastYear.getDate() - 1);
        return [
            convertToDateOnlyString(convertToLocalTimeZone(startOfYear, timeZone), timeZone),
            convertToDateOnlyString(convertToLocalTimeZone(endOfLastYear, timeZone), timeZone)
        ];
    }
    return [
        {
            text: t('common.dateShortcuts.today').toString(),
            value: getTodayDateRange(),
            anchor: todayAnchor
        },
        {
            text: t('common.dateShortcuts.yesterday').toString(),
            value: getYesterdayDateRange(),
            anchor: yesterdayAnchor
        },
        {
            text: t('common.dateShortcuts.last7Days').toString(),
            value: getLast7DaysDateRange(),
            anchor: last7DaysAnchor
        },
        {
            text: t('common.dateShortcuts.thisWeek').toString(),
            value: getThisWeekDateRange(),
            anchor: thisWeekAnchor
        },
        {
            text: t('common.dateShortcuts.thisMonth').toString(),
            value: getThisMonthDateRange(),
            anchor: thisMonthAnchor
        },
        {
            text: t('common.dateShortcuts.thisYear').toString(),
            value: getThisYearDateRange()
        },
        {
            text: t('common.dateShortcuts.lastWeek').toString(),
            value: getLastWeekDateRange()
        },
        {
            text: t('common.dateShortcuts.lastMonth').toString(),
            value: getLastMonthDateRange()
        },
        {
            text: t('common.dateShortcuts.lastYear').toString(),
            value: getLastYearDateRange()
        }
    ];
}
