import { __awaiter } from "tslib";
import { createSearchFilter, useCurrentAccount } from '@/g1';
import { CartAgreementOption, GiftCertificateExpirationUnitType, TeeSheetNavigationTargetType } from '@/types/orders';
import { BillableItemType } from '@/types/taxes';
import { useLocationService, useTaxService } from '..';
import { LocationSettingsExpand, SeriesRefundType } from '../locations/getLocationSettings';
export function createPaymentsSettingsService() {
    return {
        getPaymentsSettings(accountName) {
            return __awaiter(this, void 0, void 0, function* () {
                const currentAccount = useCurrentAccount();
                const taxService = useTaxService();
                const searchRequest = {
                    pageSize: 5000,
                    pageIndex: 0,
                    filter: createSearchFilter()
                        .equals('status', 1)
                        .build(),
                    sort: [{ rate: 'asc' }],
                    appliesTo: [BillableItemType.ProductVariant, BillableItemType.Service]
                };
                const taxServiceResponse = yield taxService.searchTaxes(currentAccount.accountName, searchRequest);
                const locationService = useLocationService();
                const locationSettingsResponse = yield locationService.getLocationSettings(accountName, LocationSettingsExpand.PaymentSettingsOrder, LocationSettingsExpand.Mobile);
                if (!locationSettingsResponse.successful) {
                    throw new Error('Failed to load location settings');
                }
                const mappedTaxItems = taxServiceResponse.data.data.map((taxItem) => ({
                    taxItemID: taxItem.id,
                    name: taxItem.name,
                    rate: taxItem.rate
                }));
                mappedTaxItems.unshift({ taxItemID: 0, name: 'N/A', rate: 0 });
                return Promise.resolve(this.mapToPaymentSettings(locationSettingsResponse.data, mappedTaxItems));
            });
        },
        savePaymentsSettings(accountName, settings) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const locationService = useLocationService();
                    const locationSettingsResponse = yield locationService.getLocationSettings(accountName, LocationSettingsExpand.PaymentSettingsOrder, LocationSettingsExpand.Mobile);
                    if (!locationSettingsResponse.successful) {
                        throw new Error('Failed to load location settings');
                    }
                    const locationPaymentSettings = this.mapFromPaymentSettings(locationSettingsResponse.data, settings);
                    const locationRequest = yield locationService.saveSettings(accountName, locationPaymentSettings);
                    if (!locationRequest.successful) {
                        throw new Error('Failed to save location settings');
                    }
                    else {
                        yield this.getPaymentsSettings(accountName);
                    }
                }
                catch (e) {
                    throw new Error('Failed to save location settings');
                }
            });
        },
        mapToPaymentSettings(settings, taxItems) {
            const standardPayment = this.mapToStandardPayment(settings.paymentSettings);
            const specialtyPayments = this.mapToSpecialtyPayments(settings.paymentSettings);
            const tipsAndGratuity = this.mapToTipsAndGratuity(settings.paymentSettings, taxItems);
            const policies = this.mapToPolices(settings);
            const onlinePayments = this.mapToOnlinePayments(settings.mobile);
            return {
                standardPayment,
                specialtyPayments,
                tipsAndGratuity,
                policies,
                onlinePayments
            };
        },
        mapToStandardPayment(settings) {
            const { acceptChecks, acceptCash, enableCreditCardSurcharge: creditCardSurcharging, creditCardSurchargeRate } = settings;
            return {
                checks: { acceptChecks },
                cash: { acceptCash },
                surcharges: {
                    creditCardSurcharging,
                    creditCardSurchargeRate
                }
            };
        },
        mapToSpecialtyPayments(settings) {
            var _a, _b;
            const { acceptGiftCards, allowCashBackOnGCBalance, giftCertificateExpirationDateValueType, giftCertificateExpirationDateValue, applyGCTaxOnSale } = settings;
            return {
                giftCards: {
                    acceptGiftCards,
                    cashBack: !!allowCashBackOnGCBalance,
                    expiration: {
                        enabled: giftCertificateExpirationDateValue !== 0,
                        afterPurchasePeriod: giftCertificateExpirationDateValueType !== null && giftCertificateExpirationDateValueType !== void 0 ? giftCertificateExpirationDateValueType : GiftCertificateExpirationUnitType.Day,
                        afterPurchaseValue: giftCertificateExpirationDateValue !== null && giftCertificateExpirationDateValue !== void 0 ? giftCertificateExpirationDateValue : 0
                    },
                    applyTaxOn: applyGCTaxOnSale ? 'Sale' : 'Redemption'
                },
                prePaidSeries: {
                    allowRefunds: settings.seriesRefundType || SeriesRefundType.No
                },
                creditAccount: {
                    utilizeGlobalSettings: settings.enableGlobalCreditAccount || false,
                    creditCardRate: (_a = settings.defaultCreditLimit) !== null && _a !== void 0 ? _a : 1000,
                    defaultAutoBillingThreshold: (_b = settings.defaultCreditAccountThreshold) !== null && _b !== void 0 ? _b : 80,
                    activateAndApplyToExistingCustomers: false
                }
            };
        },
        mapToTipsAndGratuity(settings, paymentSettingsTaxItems) {
            return {
                autoTransferTipsToPayouts: settings.autoTransferTipsToPayouts,
                autoTransferGratuityToPayouts: settings.autoTransferGratuityToPayouts,
                payTipsWithPayroll: settings.payTipsWithPayroll,
                payGratuityWithPayroll: settings.payGratuityWithPayroll,
                tipOnServiceOnly: settings.tipOnServiceOnly,
                enableServiceCharge: settings.enableServiceCharge,
                serviceChargeRate: settings.serviceChargeRate,
                serviceChargeTaxID: settings.serviceChargeTaxID,
                enableServiceChargeOverride: settings.enableServiceChargeOverride,
                enableServiceChargeSplit: settings.enableServiceChargeSplit,
                serviceChargeSplitShare1: settings.serviceChargeSplitShare1,
                serviceChargeSplitShare2: settings.serviceChargeSplitShare2,
                serviceChargeSplitShare3: settings.serviceChargeSplitShare3,
                serviceChargeBasedOnOriginalPrice: settings.serviceChargeBasedOnOriginalPrice,
                serviceChargeSplitShare1TypeID: settings.serviceChargeSplitShare1TypeID,
                serviceChargeSplitShare2TypeID: settings.serviceChargeSplitShare2TypeID,
                serviceChargeSplitShare3TypeID: settings.serviceChargeSplitShare3TypeID,
                taxItems: paymentSettingsTaxItems
            };
        },
        mapToPolices(settings) {
            var _a, _b;
            return {
                overrideReasons: {
                    required: settings.paymentSettings.requireOverrideReason,
                    tags: settings.paymentSettings.priceOverrideReason.map(x => (Object.assign(Object.assign({}, x), { isDeleted: false })))
                },
                refundPolicy: {
                    refundPolicySummary: settings.paymentSettings.refundPolicySummary,
                    refundPolicy: settings.paymentSettings.refundPolicy,
                    allowManagerRefund: settings.paymentSettings.allowManagerRefund
                },
                cartAgreement: {
                    print: (_a = settings.cartAgreementOption) !== null && _a !== void 0 ? _a : CartAgreementOption.None,
                    agreement: (_b = settings.order.cartAgreementText) !== null && _b !== void 0 ? _b : ''
                }
            };
        },
        mapToOnlinePayments(settings) {
            if (settings === null) {
                return {
                    paymentsEnableForOnlinePurchase: {
                        giftCertificatesOrCards: false,
                        loyaltyPoints: false,
                        customerCreditBook: false,
                        customerCreditAccount: false
                    }
                };
            }
            return {
                paymentsEnableForOnlinePurchase: {
                    giftCertificatesOrCards: settings.enableGiftCardPayment,
                    loyaltyPoints: settings.enableLoyaltyPayment,
                    customerCreditBook: settings.enableCreditBookPayment,
                    customerCreditAccount: settings.enableCreditAccountPayment
                }
            };
        },
        mapFromPaymentSettings(origin, settings) {
            var _a;
            const standardPayment = this.mapFromStandardPayment(settings);
            const specialtyPayments = this.mapFromSpecialtyPayments(origin.paymentSettings, settings);
            const tipsAndGratuity = this.mapFromTipsAndGratuity(settings);
            const polices = this.mapFromPolicies(origin, settings);
            const onlinePayments = this.mapFromOnlinePayments(settings);
            return Object.assign(Object.assign({}, origin), { paymentSettings: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, origin.paymentSettings), polices.paymentSettings), standardPayment), specialtyPayments), tipsAndGratuity), order: Object.assign(Object.assign({}, origin.order), polices.order), cartAgreementOption: (_a = polices.cartAgreementOption) !== null && _a !== void 0 ? _a : CartAgreementOption.None, mobile: Object.assign(Object.assign({}, origin.mobile), onlinePayments) });
        },
        mapFromStandardPayment(settings) {
            const { standardPayment } = settings;
            const { creditCardSurcharging: enableCreditCardSurcharge, creditCardSurchargeRate } = standardPayment.surcharges;
            return { creditCardSurchargeRate, enableCreditCardSurcharge };
        },
        mapFromSpecialtyPayments(origin, settings) {
            const { acceptGiftCards, cashBack, applyTaxOn, expiration } = settings.specialtyPayments.giftCards;
            const { utilizeGlobalSettings, creditCardRate, defaultAutoBillingThreshold, activateAndApplyToExistingCustomers } = settings.specialtyPayments.creditAccount;
            const allowCashBackOnGCBalance = !cashBack && !origin.allowCashBackOnGCBalance ? origin.allowCashBackOnGCBalance : cashBack;
            const applyGCTaxOnSale = applyTaxOn === 'Sale';
            return {
                seriesRefundType: settings.specialtyPayments.prePaidSeries.allowRefunds,
                acceptGiftCards,
                allowCashBackOnGCBalance,
                applyGCTaxOnSale,
                giftCertificateExpirationDateValueType: expiration.afterPurchasePeriod,
                giftCertificateExpirationDateValue: expiration.enabled ? expiration.afterPurchaseValue : 0,
                enableGlobalCreditAccount: utilizeGlobalSettings !== null && utilizeGlobalSettings !== void 0 ? utilizeGlobalSettings : origin.enableGlobalCreditAccount,
                defaultCreditLimit: creditCardRate,
                defaultCreditAccountThreshold: defaultAutoBillingThreshold,
                activateAndApplyToExistingCustomers
            };
        },
        mapFromTipsAndGratuity(settings) {
            const { autoTransferGratuityToPayouts, autoTransferTipsToPayouts, payTipsWithPayroll, payGratuityWithPayroll, tipOnServiceOnly, enableServiceCharge, serviceChargeRate, enableServiceChargeOverride, enableServiceChargeSplit, serviceChargeSplitShare1, serviceChargeSplitShare2, serviceChargeSplitShare3, serviceChargeBasedOnOriginalPrice, serviceChargeSplitShare1TypeID, serviceChargeSplitShare2TypeID, serviceChargeSplitShare3TypeID, serviceChargeTaxID } = settings.tipsAndGratuity;
            return {
                autoTransferGratuityToPayouts,
                autoTransferTipsToPayouts,
                payTipsWithPayroll,
                payGratuityWithPayroll,
                tipOnServiceOnly,
                enableServiceCharge,
                serviceChargeRate,
                enableServiceChargeOverride,
                enableServiceChargeSplit,
                serviceChargeSplitShare1,
                serviceChargeSplitShare2,
                serviceChargeSplitShare3,
                serviceChargeBasedOnOriginalPrice,
                serviceChargeSplitShare1TypeID,
                serviceChargeSplitShare2TypeID,
                serviceChargeSplitShare3TypeID,
                serviceChargeTaxID
            };
        },
        mapFromPolicies(origin, settings) {
            const { policies } = settings;
            return {
                cartAgreementOption: policies.cartAgreement.print,
                order: {
                    cartAgreementText: policies.cartAgreement.agreement,
                    priceOverrideReason: [],
                    cartAgreementVersion: origin.order.cartAgreementVersion
                },
                paymentSettings: Object.assign(Object.assign({}, origin.paymentSettings), { refundPolicySummary: policies.refundPolicy.refundPolicySummary, refundPolicy: policies.refundPolicy.refundPolicy, allowManagerRefund: policies.refundPolicy.allowManagerRefund, requireOverrideReason: policies.overrideReasons.required, priceOverrideReason: policies.overrideReasons.tags })
            };
        },
        mapFromOnlinePayments(settings) {
            if (settings.onlinePayments === null) {
                return {
                    enableGiftCardPayment: false,
                    enableLoyaltyPayment: false,
                    enableCreditBookPayment: false,
                    enableCreditAccountPayment: false,
                    teeSheetNavigationTarget: TeeSheetNavigationTargetType.TeeSheet
                };
            }
            const { giftCertificatesOrCards, loyaltyPoints, customerCreditBook, customerCreditAccount } = settings.onlinePayments.paymentsEnableForOnlinePurchase;
            return {
                enableGiftCardPayment: giftCertificatesOrCards,
                enableLoyaltyPayment: loyaltyPoints,
                enableCreditBookPayment: customerCreditBook,
                enableCreditAccountPayment: customerCreditAccount,
                teeSheetNavigationTarget: TeeSheetNavigationTargetType.TeeSheet
            };
        }
    };
}
