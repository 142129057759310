import { g1PermissionsService } from '../features/permissions/g1PermissionsService';
const plugin = {
    install(vue, options) {
        vue.prototype.$permissions = g1PermissionsService;
        if (options === null || options === void 0 ? void 0 : options.permissionLookup) {
            vue.prototype.$permission = options === null || options === void 0 ? void 0 : options.permissionLookup;
        }
    }
};
export default plugin;
